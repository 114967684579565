import React from 'react'
import Select from 'react-select'
interface busFilterProps {
  responseData: { text: string; value: string }[]
  className?: string
  formData: any
  setFormData: React.Dispatch<React.SetStateAction<any>>
}
export const BusFilter: React.FC<busFilterProps> = ({
  className,
  responseData,
  formData,
  setFormData
}) => {
  return (
    <div className='flex flex-col px-2 py-2'>
      <Select
        options={responseData}
        placeholder='Select Operator...'
        isSearchable={true}
        className='text-sm tracking-widest'
        getOptionLabel={option => (option.text ? option.text : '')}
        getOptionValue={option => (option.value ? option.value : '')}
        onChange={option => {
          setFormData({ ...formData, selectedBus: option?.value })
        }}
      />
    </div>
  )
}
