import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../../components/common/SelectTable";

import { b2bConfig } from "../../../../config";
import { Instance } from "../../../../services";
import { RootState } from "../../../../store";
import "./index.css";

interface mobileTransactionPageProps {
  className?: string;
}

interface recordProps {
  ID: number;
  billerCode: string;
  eBARefNo: string;
  mobileNo: string;
  orderNo: string;
  rechargeAmount: number;
  rechargeStatus: string;
  rechargeTime: string;
  remarks: string;
}

export const MobileTransactionsPage: React.FC<mobileTransactionPageProps> = ({
  className
}) => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const [records, setRecords] = useState<recordProps[]>([]);
  const getRecords = useCallback(
    () => {
      if (isAuth) {
        Instance({
          url: `${b2bConfig.apiPath.dgServices}/mobile/geteloadrecord`,
          method: "GET"
        })
          .then(res => {
            if (
              res.data &&
              res.data.Data &&
              res.data.Status &&
              res.data.Status.Code === "01"
            ) {
              const response: recordProps[] = res.data.Data;
              setRecords(response);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    [isAuth]
  );

  useEffect(
    () => {
      getRecords();
    },
    [getRecords]
  );

  return (
    <div className={`${className}`}>
      <SelectTable className="text-gray-800">
        <thead className="text-xs font-normal uppercase border-t border-b text-gray-500 bg-gray-50 border-gray-200">
          <TableRow>
            <TableCell isHeader={true}>
              <span>No</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Biller Code</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Mobile Number</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Order No</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Amount</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Status</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Remark</span>
            </TableCell>
          </TableRow>
        </thead>
        <tbody className="text-xs font-normal divide-y divide-gray-400 bg-white">
          {records.map((r, i) =>
            <TableRow key={i}>
              <TableCell>
                <span>
                  {i + 1}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {r.billerCode}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {r.mobileNo}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {r.orderNo}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {r.rechargeAmount}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {r.rechargeStatus}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {r.remarks}
                </span>
              </TableCell>
            </TableRow>
          )}
        </tbody>
      </SelectTable>
    </div>
  );
};
