import { each } from "lodash";
import React, { Fragment } from "react";
import { b2bConfig } from "../../../config";
import { airlinePorps } from "../../../interfaces";
import AsyncSelect from "react-select/async";
import { fsearchformDataProps } from "../../../pages/dashbaord/FlightServices/FlightSearch";
import { Instance } from "../../../services";
import { useToasts } from "react-toast-notifications";
import "./index.css";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDoubleDownIcon, UserGroupIcon } from "@heroicons/react/solid";

import ReactDatePicker from "react-datepicker";
import ReactSelect from "react-select";


interface fSearchFormProps {
  className?: string;
  formData: fsearchformDataProps;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}
interface aSearchOptions {
  label?: string;
  value?: any;
}
export const FSearchForm: React.FC<fSearchFormProps> = ({
  className,
  formData,
  setFormData,
  children
}) => {
  const classOptions = [
    { label: "Economy", value: "Y" },
    { label: "Business", value: "C" },
    { label: "First", value: "F" },
    { label: "Premium Economy", value: "S" }
  ];
  
  const currencyOptions = [
    { label: "MMK", value: "MMK" },
    { label: "USD", value: "USD" }
  ];

  const FetchData = (inputValue: string, callback: CallableFunction) => {
    Instance({
      url: `${b2bConfig.apiPath.filghtServices}/airportsearch?q=${inputValue}`,
      method: "GET"
    })
      .then((res) => {
        let tempData: aSearchOptions[] = [];
        if (res.data && res.data.Data) {
          const responseData: airlinePorps[] = res.data.Data;
          each(responseData, (e) => {
            const parms: aSearchOptions = {
              label: e.name + "("+ e.iata_code+")",
              value: e.iata_code
            };
            tempData.push(parms);
          });
        }
        callback(tempData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { addToast } = useToasts();

  return (
    <div className="flex flex-col relative mx-auto space-y-6 max-w-screen-md rounded-md  px-4 py-4 bg-white">
      <div className="relative flex"></div>
      <div className="flex flex-row relative text-sm text-gray-600 tracking-widest justify-center items-center content-center w-full">
        <button
          type="button"
          onClick={() => {
            setFormData({ ...formData, tripType: "oneway" });
          }}
          className={`w-full border  border-yellow-400 px-2 py-2 rounded-l-md ${
            formData.tripType === "oneway" && "bg-yellow-400 text-white"
          }`}>
          <span className="uppercase">OneWay</span>
        </button>
        <button
          type="button"
          onClick={() => {
            setFormData({ ...formData, tripType: "roundtrip" });
          }}
          className={`w-full border    border-yellow-400 px-2 py-2 rounded-r-md ${
            formData.tripType === "roundtrip" && "bg-yellow-400 text-white"
          }`}>
          <span className="uppercase">RoundTrip</span>
        </button>
      </div>

      <div className="flex justify-between  items-center content-center px-2 py-0 border-gray-200 rounded-md border">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-geo-alt-fill text-gray-400"
          viewBox="0 0 16 16">
          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
        </svg>
        <AsyncSelect
          id="origin"
          className="w-full outline-none focus:outline-none tracking-widest"
          name="origin"
          placeholder="Orgin .."
          onChange={(e) => setFormData({ ...formData, origin: e })}
          loadOptions={(inputValue: string, callback: CallableFunction) => {
            FetchData(inputValue, callback);
          }}
        />
      </div>
      <div className="flex justify-between items-center content-center px-2 py-0 border-gray-200 rounded-md border">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-geo-alt-fill text-gray-400"
          viewBox="0 0 16 16">
          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
        </svg>
        <AsyncSelect
          id="destination"
          className="w-full outline-none focus:outline-none tracking-widest"
          name="destination"
          placeholder="Destination.."
          onChange={(e) => setFormData({ ...formData, destination: e })}
          loadOptions={(inputValue: string, callback: CallableFunction) => {
            FetchData(inputValue, callback);
          }}
        />
      </div>

      {formData.tripType === "oneway" ? (
        <div className="flex justify-between  items-center content-center px-2 border-gray-200 rounded-md border">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-calendar-event text-gray-400 w-4 h-4 ml-2 mr-3 text-violet-200 hover:text-violet-100"
            viewBox="0 0 16 16">
            <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
          </svg>
          <ReactDatePicker
            dateFormat="yyyy-MM-dd"
            className="form-input border-0 focus:outline-none appearance-none w-full rounded-md tracking-widest text-secondary text-sm md:text-sm px-2"
            placeholderText="Departure Date..."
            name="singleDate"
            id="singleDate"
            minDate={new Date()}
            title="Departure Date"
            onChange={(date) => {
              setFormData({ ...formData, singleDate: date });
            }}
            selected={formData.singleDate}
          />
        </div>
      ) : (
        <div className="flex justify-between  items-center content-center px-2  border-gray-200 rounded-md border">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-calendar-event text-gray-400 w-4 h-4 ml-2 mr-3 text-violet-200 hover:text-violet-100"
            viewBox="0 0 16 16">
            <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
          </svg>
          <ReactDatePicker
            dateFormat="yyyy-MM-dd"
            className="form-input border-0 focus:outline-none appearance-none w-full rounded-md tracking-widest text-secondary text-sm md:text-sm px-2"
            placeholderText="Departure Date..."
            name="rangeDate"
            id="rangeDate"
            selectsRange={true}
            minDate={new Date()}
            title="From~To"
            startDate={formData.rangeDate[0]}
            endDate={formData.rangeDate[1]}
            onChange={(update) => {
              setFormData({ ...formData, rangeDate: update });
            }}
          />
        </div>
      )}


        <div className="flex justify-between  items-center content-center px-2 py-2 rounded-md ">
                  
                  <div className="w-full flex-row text-gray-400 justify-center ml-4 items-center content-center space-x-3">
                    <label>
                      <input
                        className="form-radio"
                        checked={formData.nonStop === "true"}
                        type="radio"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value
                          });
                        }}
                        name="nonStop"
                        id="nonStop"
                        value="Direct"
                      />
                      <span className="  mx-2">Direct</span>
                    </label>
                    <label>
                      <input
                        className="form-radio"
                        checked={formData.nonStop === "false"}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value
                          });
                        }}
                        type="radio"
                        name="nonStop"
                        id="nonStop"
                        value="OneStop"
                      />
                      <span className=" mx-2">OneStop</span>
                    </label>

                    <label>
                      <input
                        className="form-radio"
                        checked={formData.nonStop === "false"}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value
                          });
                        }}
                        type="radio"
                        name="nonStop"
                        id="nonStop"
                        value="All"
                      />
                      <span className=" mx-2"> All </span>
                    </label>
                    
                  </div>
                </div>




      <div className=" w-full max-w-screen-md bg-white ">
        <Popover as="div" className="relative inline-block text-left w-full">
          <Popover.Button className="inline-flex  justify-center w-full px-4 py-2 text-sm md:text-sm font-normal text-gray-400  bg-white border border-gray-200 rounded-md bg-opacity-80 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <UserGroupIcon
              className="w-4 h-4  mr-3 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
            <div className="mr-2 tracking-widest">
              Adults: <span className="text-red-600" >{formData.adults} </span>|
            </div>
            <div className="mr-2 tracking-widest">
              Children: <span className="text-red-600" >{formData.children} </span> |
            </div>
            <div className="mr-2 tracking-widest">
              Infants: <span className="text-red-600" >{formData.infants} </span> |
            </div>
            <div className="mr-2 tracking-widest">
              Class: <span className="text-red-600" >{formData.travelClass} </span>
            </div>
            <ChevronDoubleDownIcon
              className="w-4 h-4 ml-auto text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="absolute z-10 px-2 py-2 text-gray-400 bg-white w-full rounded-b-md max-w-screen-md shadow-md">
              <div className="flex px-4 py-4 flex-col space-y-3">
                <div className="flex flex-row space-x-2 justify-center items-center content-center">
                  <span className="w-full"> Adults </span>
                  <div className="flex flex-row space-x-2 w-full text-center">
                    <button
                      onClick={() => {

                        if(formData.adults <= 8){
                          setFormData(
                            {
                            ...formData,
                            adults: formData.adults + 1
                          });
                        }else{
                          addToast(`9 Adult is Max`, {
                            appearance: "error",
                            autoDismiss: true
                          });
                        }
                      }}
                      type="button"
                      className="text-center text-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-plus-square"
                        viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </button>
                    <span className="text-red-600 px-5" >{formData.adults} </span>
                    <button
                      onClick={() => {

                        if(formData.adults == 1){
                          addToast(`1 Adult is required`, {
                            appearance: "error",
                            autoDismiss: true
                          });
                        }else{
                          formData.adults > 0 &&
                          setFormData({
                            ...formData,
                            adults: formData.adults - 1
                          });
                        }

                        
                      }}
                      type="button"
                      className="text-center text-danger">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-dash-square"
                        viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex flex-row space-x-2 justify-center items-center content-center">
                  <span className="w-full"> Children </span>
                  <div className="flex flex-row space-x-2 w-full text-center">
                    <button
                      onClick={() => {

                        if(formData.children <= 4){
                          setFormData({
                            ...formData,
                            children: formData.children + 1
                          });
                        }else{
                          addToast(`5 Child is Max.`, {
                            appearance: "error",
                            autoDismiss: true
                          });
                        }
                        
                      }}
                      type="button"
                      className="text-center text-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-plus-square"
                        viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </button>
                    <span className="text-red-600 px-5" >{formData.children} </span>
                    <button
                      onClick={() => {
                        formData.children > 0 &&
                          setFormData({
                            ...formData,
                            children: formData.children - 1
                          });
                      }}
                      type="button"
                      className="text-center text-danger">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-dash-square"
                        viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex flex-row space-x-2 justify-center items-center content-center">
                  <span className="w-full"> Infants </span>
                  <div className="flex flex-row space-x-2 w-full text-center">
                    <button
                      onClick={() => {


                        if(formData.infants <= 4){
                          setFormData({
                            ...formData,
                            infants: formData.infants + 1
                          });
                        }else{
                          addToast(`5 Infant is Max.`, {
                            appearance: "error",
                            autoDismiss: true
                          });
                        }
                        
                      }}
                      type="button"
                      className="text-center text-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-plus-square"
                        viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </button>
                    <span className="text-red-600 px-5" >{formData.infants} </span>
                    <button
                      onClick={() => {
                        formData.infants > 0 &&
                          setFormData({
                            ...formData,
                            infants: formData.infants - 1
                          });
                      }}
                      type="button"
                      className="w-full text-center text-danger">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-dash-square"
                        viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex justify-between  items-center content-center px-2 py-0 border-gray-200 rounded-md border">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-clipboard text-gray-400 mr-3"
                    viewBox="0 0 16 16">
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                  </svg>
                  <ReactSelect
                    name="travelClass"
                    id="travelClass"
                    placeholder="TravelClass"
                    className="w-full outline-none focus:outline-none tracking-widest"
                    options={classOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    onChange={(option) => {
                      setFormData({ ...formData, travelClass: option?.value });
                    }}
                  />
                </div>

                <div className="justify-between  items-center content-center py-0 ">

                  <label className="w-full">
                    Prefer Airlines                             
                  </label>
                  <span className="text-xs w-full text-secondary">
                      {"  "} (eg. 8M,SQ,UB,MH)
                  </span>

             
                  <input type="text" name="includedAirlineCodes" className="form-input border-gray-200 border-1 focus:outline-none appearance-none w-full rounded-md tracking-widest text-secondary text-sm md:text-sm px-2" 
                  
                  value= { (formData.includedAirlineCodes=="_") ? "":formData.includedAirlineCodes} 

                      onChange={(e) => {
                        setFormData({ ...formData, includedAirlineCodes: e.target.value });
                      }
                    }
                      
                      //onChange={(e) => setFormData({ ...formData, includedAirlineCodes: e?.value })}
                      
                      />
                </div>

                {/*}
                <div className="flex justify-between  items-center content-center px-2 py-0 border-gray-200 rounded-md border">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-currency-exchange text-gray-400 mr-3"
                    viewBox="0 0 16 16">
                    <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z" />
                  </svg>
                  <ReactSelect
                    name="currencyCode"
                    id="currencyCode"
                    placeholder="Currency"
                    className="w-full outline-none focus:outline-none tracking-widest"
                    options={currencyOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    onChange={(option) => {
                      setFormData({ ...formData, currencyCode: option?.value });
                    }}
                  />
                </div>
                {*/}

                <div className="flex  justify-start ">
                  <button className="border border-yellow-400 flex justify-center items-center content-center space-x-2 bg-white rounded-md px-3 py-2 hover:bg-yellow-400 text-yellow-400 hover:text-yellow-50 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                    <span className="uppercase text-sm md:text-sm">Search</span>
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
      <div className="flex  justify-start ">
        <button className="border border-yellow-400 flex justify-center items-center content-center space-x-2 bg-white rounded-md px-3 py-2 hover:bg-yellow-400 text-yellow-400 hover:text-yellow-50 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
          <span className="uppercase text-sm md:text-sm">Search</span>
        </button>
      </div>

      {children}
    </div>
  );
};
