import { UsersIcon } from '@heroicons/react/solid'
import React from 'react'
import { useHistory } from 'react-router-dom'
import format from 'date-fns/format'
import {
  busSearchFormProps,
  otherBusResponseProps
} from '../../../pages/dashbaord/BusService/BusSearch'

import './index.css'
interface busCardProps {
  className?: string
  formData: busSearchFormProps
  busData?: otherBusResponseProps
}


export const BusCard: React.FC<busCardProps> = ({
  className,
  busData,
  formData
}) => {
  const { push } = useHistory()

  let dm:number = Number(busData?.durationInMinutes);
  let durationHours = dm/60
  //let durationMinute = dm%60

  return (
    <div className='w-full tracking-widest mb-4 '>
      {busData && (
        
        <div className='grid grid-cols-1 md:grid-cols-3 gap-3 bg-gray-100 px-2 py-2 rounded-md'>
          
          <div className='w-full flex justify-start items-center content-center flex-col'>
            <div className='flex text-gray-500 w-full px-2 py-2 space-x-2 justify-start space-x-2 items-center content-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-clock-history'
                viewBox='0 0 16 16'
              >
                <path d='M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z' />
                <path d='M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z' />
                <path d='M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z' />
              </svg>
              <span className='font-medium text-warning'>
                {busData.departureDateTime}
              </span>
            </div>
            <div className='w-full flex justify-start items-center content-center tracking-widest ml-4 text-gray-400'>
              <span className='text-xs font-normal'>
                {busData.operator.name.en} &nbsp;
                {busData.busType.en}
              </span>
            </div>
            <div className='flex text-xs ml-3 py-4 justify-start items-center content-center w-full space-x-3'>
              <span className='font-normal text-primary tracking-widest'>
                {busData.fullRouteName.en}
              </span>
            </div>
            <div className='flex text-gray-500 w-full px-2 py-2 space-x-2 justify-start space-x-2 items-center content-center'>
             
              <span className='font-normal text-secondary text-xs'>
                Departure : {busData.departureDateTime} <br></br>
                Arrival :  {busData.estArrivalDateTime} <br></br>
               
                Duration : {durationHours} Hours 
              </span>
            </div>
          </div>
          <div className='flex w-full  flex-col space-y-2 justify-start items-center content-center'>
            <img
              src={busData?.operator.logoUrl}
              alt=''
              className='w-auto rounded-md h-12'
            />
            <p className=' flex  font-medium text-gray-400 tracking-widest text-xs'>
              {busData.operator.name.en} 
            </p>
            <p className='font-normal flex px-2 py-2  space-x-2 justify-center content-center items-center text-gray-400 tracking-widest text-xs'>
              <span className='font-normal tracking-widest text-yellow-400 text-xs'>
                {busData.avlseats}
              </span>
              <UsersIcon className='w-4 h-4' />
            </p>
          </div>
          <div className='flex  flex-col space-y-2 justify-start items-center content-center'>
            <h6 className='text-success font-medium text-sm'>
            {busData.totalTicketPrice?.currency}
            {busData.totalTicketPrice?.amount} (Total)
            </h6>
            <h6 className='text-warning font-normal text-xs'>
            
            {busData?.unitTicketPrice?.currency}
              {busData?.unitTicketPrice?.amount} per Pax
              
            </h6>

            <button
              onClick={() => {
                push(
                  `/dashboard/bus/booking/${formData.depfrom}/${
                    formData.arrvto
                  }/${format(formData.depdate, 'yyyy-MM-dd')}/${formData.pax}/${
                    formData.nationality
                  }/${busData?.operator.code}/${busData.correlationIntegrity}`
                );
                sessionStorage.setItem('busData',JSON.stringify(busData))
              }}
              type='button'
              className='flex justify-center text-gray-400 hover:text-gray-800 border border-gray-300 hover:border-gray-800 px-2 py-2 text-xs items-center content-center space-x-2'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='14'
                height='14'
                fill='currentColor'
                className='bi bi-columns-gap'
                viewBox='0 0 16 16'
              >
                <path d='M6 1v3H1V1h5zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm14 12v3h-5v-3h5zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5zM6 8v7H1V8h5zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H1zm14-6v7h-5V1h5zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-5z' />
              </svg>
              <span> Select Seats</span>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
