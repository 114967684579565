import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../../components/common/SelectTable";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { b2bConfig } from "../../../../config";
import { Instance } from "../../../../services";

import "./index.css";

interface InsurancePageProps {
  className?: string;
  type: any;
  rate: any;
  startDate: Date | null;
  endDate: Date | any;
  route: any;
  travelerNo: any;
  grandTotal: any;
  showTable: boolean;
  nextStep: boolean;
  formSuccess: boolean;
  travelerList: any[];
  premiumOptions: any[];
}

interface optionProp {
  text: string;
  value: any;
}

const tripOptions: optionProp[] = [
  { value: 1, text: "Local" },
  { value: 2, text: "International" }
];

const travelerOptions: optionProp[] = [
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
  { value: 6, text: "6" },
  { value: 7, text: "7" },
  { value: 8, text: "8" },
  { value: 9, text: "9" },
  { value: 10, text: "10" }
];

const defaultValue = {
  type: "",
  rate: "",
  startDate: null,
  endDate: null,
  route: "",
  travelerNo: "",
  travelerList: [],
  premiumOptions: [],
  grandTotal: 0,
  showTable: false,
  nextStep: false,
  formSuccess: false
};

export const Insurance: React.FC<InsurancePageProps> = () => {
  const [formData, setFormData] = useState<InsurancePageProps>(defaultValue);
  const { addToast } = useToasts();

  const getPremiumRate = (e: any) => {
    const value = e.target.value;

    if (value) {
      Instance({
        url: `${b2bConfig.apiPath.insuranceServices}/premiumrate?tripType=${value}`
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data) {
              const response = res.data.data;
              setFormData({
                ...formData,
                [e.target.name]: value,
                premiumOptions: response,
                startDate: null,
                endDate: null,
                travelerNo: "",
                route: "",
                showTable: false
              });
            }
          }
        })
        .catch((err) => {
          console.log(err, ["transaction_fetch_error"]);
        });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: value,
        premiumOptions: [],
        startDate: null,
        endDate: null
      });
    }
  };

  const addDays = (date: any) => {
    const d = formData.premiumOptions.filter(
      (f) => f.slug === formData.rate
    )[0];
    const endDate = moment(date)
      .add(d.duration, d.durationUnit.charAt(0).toLowerCase())
      .format("yyyy-MM-DD");
    return new Date(endDate);
  };

  const addRow = (e: any) => {
    const list = [];
    const value = e.target.value;
    const price = formData.premiumOptions.filter(
      (f) => f.slug === formData.rate && f
    )[0].ratePerUnit;

    if (value) {
      for (let i = 0; i < parseInt(value); i++) {
        list.push({
          insuredName: "",
          insuredID: "",
          beneficiaryName: "",
          beneficiaryID: "",
          contactNo: "",
          unitNo: 1,
          total: parseInt(price)
        });
      }
    }

    setFormData({
      ...formData,
      [e.target.name]: value,
      showTable: true,
      travelerList: list,
      grandTotal: list.reduce((sum, i) => {
        return sum + i.unitNo * i.total;
      }, 0)
    });
  };

  const inputChange = (e: any, i: any) => {
    const newData = [...formData.travelerList];
    const value = e.target.value;

    newData[i][e.target.name] = value;
    setTimeout(() => {
      setFormData({
        ...formData,
        travelerList: newData
      });
    }, 0);

    if (e.target.name === "unitNo") {
      const price = formData.premiumOptions.filter(
        (f) => f.slug === formData.rate && f
      )[0].ratePerUnit;
      const total = parseInt(value, 10) * parseInt(price, 10);
      newData[i]["total"] = total;

      setTimeout(() => {
        setFormData({
          ...formData,
          travelerList: newData,
          grandTotal: newData.reduce((sum, i) => {
            return sum + i.total;
          }, 0)
        });
      }, 0);
    }
  };

  const onSubmit = () => {
    setFormData({
      ...formData,
      nextStep: true
    });
  };

  const reset = () => {
    setFormData(defaultValue);
  };

  const back = () => {
    setTimeout(() => {
      setFormData({
        ...formData,
        nextStep: false
      });
    }, 0);
  };

  const formSubmit = () => {
    const data = {
      premiumRate: formData.rate,
      paxNo: formData.travelerNo,
      unitPerPax: 1,
      totalAmount: parseFloat(formData.grandTotal).toFixed(2),
      currency: "MMK",
      route: formData.route,
      travelStartDate: moment(formData.startDate).format("YYYY-MM-DD"),
      travelEndDate: moment(formData.endDate).format("YYYY-MM-DD"),
      bookingDetail: formData.travelerList
    };

    Instance({
      url: `${b2bConfig.apiPath.insuranceServices}/book_insurance`,
      method: "POST",
      data: data
    })
      .then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            addToast(`Insurance is successfully booking.`, {
              appearance: "success",
              autoDismiss: true
            });
            reset();
          }, 300);
        }
      })
      .catch((err) => {
        console.log(err, ["transaction_fetch_error"]);
      });
  };

  const premiumRate = () => {
    const t = formData.premiumOptions.filter(
      (f) => f.slug === formData.rate && f
    )[0];
    return `${t.duration} ${t.durationUnit.toLowerCase()} ${parseInt(
      t.ratePerUnit
    )}ks per unit`;
  };

  return (
    <React.Fragment>
      {!formData.nextStep ? (
        <div>
          <h4 className="mb-3">Buy Insurance</h4>
          <div className={`bg-white rounded-md shadow-md`}>
            <div className="p-6 space-y-10 w-full">
              <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-4">
                <div className="">
                  <label className="pb-2 required">Trip Type</label>
                  <div className="border border-gray-300 rounded-md px-0.5 mt-2">
                    <select
                      name="type"
                      id="type"
                      required
                      value={formData.type}
                      onChange={(e) => getPremiumRate(e)}
                      className="border-0 p-1 w-full bg-white outline-none text-gray-500">
                      <option value="">Select</option>
                      {tripOptions.map((a, i) => (
                        <option key={i} value={a.value}>
                          {a.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="md:col-span-2 sm:col-span-1">
                  <label className="pb-2 required">Premium Rate</label>
                  <div className="border border-gray-300 rounded-md px-0.5 mt-2">
                    <select
                      name="rate"
                      id="rate"
                      value={formData.rate}
                      disabled={!formData.type}
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                          startDate: null,
                          endDate: null,
                          travelerNo: "",
                          route: "",
                          showTable: false
                        });
                      }}
                      className="border-0 p-1 w-full bg-white outline-none text-gray-500">
                      <option value="">Select</option>
                      {formData.premiumOptions &&
                        formData.premiumOptions.map((v, i) => (
                          <option key={i} value={v.slug}>
                            {`${
                              v.duration
                            } ${v.durationUnit.toLowerCase()} ${parseInt(
                              v.ratePerUnit
                            )}ks per unit`}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="">
                  <label className="pb-2 required">Start Date</label>
                  <div className="border border-gray-300 rounded-md mt-2">
                    <ReactDatePicker
                      dateFormat="yyyy-MM-dd"
                      className="form-input border-0 appearance-none w-full rounded-md tracking-widest text-secondary text-sm md:text-sm px-1 py-1.5"
                      name="startDate"
                      id="startDate"
                      minDate={new Date()}
                      title="Travel Start Date"
                      disabled={!formData.rate}
                      onChange={(date) => {
                        setFormData({
                          ...formData,
                          startDate: date,
                          endDate: addDays(date)
                        });
                      }}
                      selected={formData.startDate}
                    />
                  </div>
                </div>

                <div className="">
                  <label className="pb-2 required">End Date</label>
                  <div className="border border-gray-300 bg-gray-100 rounded-md mt-2">
                    <ReactDatePicker
                      dateFormat="yyyy-MM-dd"
                      className="form-input bg-gray-100 border-0 focus:outline-none appearance-none w-full rounded-md tracking-widest text-secondary text-sm md:text-sm px-2 py-1.5"
                      name="endDate"
                      id="endDate"
                      title="Travel End Date"
                      onChange={() => {}}
                      disabled
                      selected={formData.endDate}
                    />
                  </div>
                </div>

                <div className="md:col-span-2 sm:col-span-1">
                  <label className="pb-2 required">Route</label>
                  <div className="border border-gray-300 rounded-md px-0.5 mt-2">
                    <input
                      className="border-0 p-1 w-full bg-white outline-none text-gray-500"
                      id="route"
                      name="route"
                      required
                      value={formData.route}
                      disabled={!formData.startDate}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="">
                  <label className="pb-2 required">Traveler No.</label>
                  <div className="border border-gray-300 rounded-md px-0.5 mt-2">
                    <select
                      name="travelerNo"
                      id="travelerNo"
                      value={formData.travelerNo}
                      required
                      disabled={!formData.route}
                      onChange={(e) => addRow(e)}
                      className="border-0 p-1 w-full bg-white outline-none text-gray-500">
                      <option value="">Select</option>
                      {travelerOptions.map((a, i) => (
                        <option key={i} value={a.value}>
                          {a.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h4 className="mb-3">Buy Insurance</h4>
          <div className={`bg-white rounded-md shadow-md mb-4`}>
            <div className="p-6 space-y-3 w-full text-sm">
              <div className="flex flex-row">
                <div className="w-40">Trip Type</div>
                <div>
                  <span className="px-4">-</span>
                  {formData.type === "1" ? "Local" : "International"}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-40">Premium Rate</div>
                <div>
                  <span className="px-4">-</span>
                  {premiumRate()}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-40">Start Date</div>
                <div>
                  <span className="px-4">-</span>
                  {moment(formData.startDate).format("YYYY-MM-DD")}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-40">End Date</div>
                <div>
                  <span className="px-4">-</span>
                  {moment(formData.endDate).format("YYYY-MM-DD")}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-40">Route</div>
                <div>
                  <span className="px-4">-</span>
                  {formData.route}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-40">Traveler No.</div>
                <div>
                  <span className="px-4">-</span>
                  {formData.travelerNo}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {formData.showTable && formData.travelerList.length > 0 && (
        <div className={formData.nextStep ? "" : "mt-6 my-4"}>
          <h4 className="mb-2">Traveler Information</h4>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}>
            <SelectTable className="bg-white rounded-md shadow-md">
              <thead className="md:table-fixed text-xs m-4 font-normal border-t border-b text-gray-500 bg-gray-200 border-gray-200">
                <TableRow>
                  <TableCell isHeader={true}>
                    <span className="pl-1">No</span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span className="required">
                      Insured
                      <br /> Name
                    </span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span className="required">
                      Insured
                      <br /> NRIC/Passport
                    </span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span className="required">
                      Beneficiary
                      <br /> Name
                    </span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span className="required">
                      Beneficiary
                      <br /> NRIC/Passport
                    </span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span className="required">Contact No</span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span className="required">Unit</span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span className="">Total (MMK)</span>
                  </TableCell>
                </TableRow>
              </thead>

              <tbody className="text-xs font-normal divide-y divide-gray-100">
                {formData.travelerList.map((t, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <span className="pl-2">{i + 1}</span>
                    </TableCell>
                    <TableCell>
                      {formData.nextStep ? (
                        <span>{t.insuredName}</span>
                      ) : (
                        <input
                          required
                          className="border p-1 rounded w-full"
                          name="insuredName"
                          value={t.insuredName}
                          onChange={(e) => inputChange(e, i)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {formData.nextStep ? (
                        <span>{t.insuredID}</span>
                      ) : (
                        <input
                          required
                          className="border p-1 rounded w-full"
                          name="insuredID"
                          value={t.insuredID}
                          onChange={(e) => inputChange(e, i)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {formData.nextStep ? (
                        <span>{t.beneficiaryName}</span>
                      ) : (
                        <input
                          required
                          className="border p-1 rounded w-full"
                          name="beneficiaryName"
                          value={t.beneficiaryName}
                          onChange={(e) => inputChange(e, i)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {formData.nextStep ? (
                        <span>{t.beneficiaryID}</span>
                      ) : (
                        <input
                          required
                          className="border p-1 rounded w-full"
                          name="beneficiaryID"
                          value={t.beneficiaryID}
                          onChange={(e) => inputChange(e, i)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {formData.nextStep ? (
                        <span>{t.contactNo}</span>
                      ) : (
                        <input
                          required
                          className="border p-1 rounded w-full"
                          name="contactNo"
                          pattern="[0-9]+"
                          title="Please provide number only"
                          value={t.contactNo}
                          onChange={(e) => inputChange(e, i)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {formData.nextStep ? (
                        <span>{t.unitNo}</span>
                      ) : (
                        <input
                          required
                          type="number"
                          className="border p-1 rounded w-full text-center"
                          name="unitNo"
                          min={1}
                          max={10}
                          value={t.unitNo !== "" ? t.unitNo : 1}
                          onChange={(e) => inputChange(e, i)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {formData.nextStep ? (
                        <span>{t.total > 0 ? t.total : ""}</span>
                      ) : (
                        <input
                          name="total"
                          value={t.total > 0 ? t.total : ""}
                          disabled
                          className="border p-1 rounded w-full bg-gray-100 text-right pr-2"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </SelectTable>

            <div className="flex flex-row justify-between items-center my-5">
              {formData.nextStep ? (
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <button
                      className="border px-4 py-2 flex w-full rounded-md justify-between items-center content-center border-gray-400 bg-gray-400 hover:bg-gray-600 text-white"
                      onClick={back}>
                      <span className="w-full text-center">Back</span>
                    </button>
                  </div>

                  <div className="ml-2">
                    <button
                      className="border px-4 py-2 flex w-full rounded-md justify-between items-center content-center border-yellow-400 bg-yellow-400 hover:bg-yellow-600 text-white"
                      onClick={formSubmit}>
                      <span className="w-full text-center">Book Now</span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <button
                      type="submit"
                      className="border px-4 py-2 flex w-full rounded-md justify-between items-center content-center border-yellow-400 bg-yellow-400 hover:bg-yellow-600 text-white">
                      <span className="w-full text-center">Next</span>
                    </button>
                  </div>

                  <div className="ml-2">
                    <button
                      type="button"
                      onClick={reset}
                      className="border px-4 py-2 flex w-full rounded-md justify-between items-center content-center border-red-400 bg-red-400 hover:bg-red-600 text-white">
                      <span className="w-full text-center">Reset</span>
                    </button>
                  </div>
                </div>
              )}

              <div className="flex flex-col">
                <div>
                  <span>Grand Total</span>
                </div>
                <div>
                  <span className="border px-4 py-2 rounded w-48 block bg-gray-300 text-right">
                    {formData.grandTotal}
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </React.Fragment>
  );
};
