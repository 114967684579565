import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import { b2bConfig } from "../../config";
import { userDataProps } from "../../interfaces";
import { Instance } from "../../services";
import { RootState } from "../../store";
import { Layout } from "../../templates/Layout";
import { BusinessEntityPage } from "./BusinessEntity";
import { BusServicePage } from "./BusService";
import { FlightServicePage } from "./FlightServices";
import { DashboardHome } from "./Home";
import { MobileServicePage } from "./MobileSerives";
import { InsurancePage } from "./Insurance";
import { ChangePassword } from "./User";

export const DashbaordPage: React.FC = () => {
  const { path } = useRouteMatch();
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const [usrData, setusrData] = useState<userDataProps>();
  window.document.title = " TRAVEL.COM.MM";
  const history = useHistory();

  const authFn = useCallback(
    () => {
      if (!isAuth && sessionStorage.getItem("accessToken") === null) {
        history.push("/");
      }
    },
    [history, isAuth]
  );

  useEffect(
    () => {
      if (sessionStorage.getItem("accessToken")) {
        Instance({
          url: `${b2bConfig.apiPath.bzEntry}/user/profile/`,
          method: "GET"
        })
          .then(res => {
            if (res.status === 200) {
              if (
                res.data &&
                res.data.Data &&
                res.data.Status &&
                res.data.Status.Code === "01"
              ) {
                const resp: userDataProps = res.data.Data;
                setusrData(resp);
              }
            }
          })
          .catch(err => {
            console.log(err, ["userFetch error"]);
          });
      } else {
        authFn();
      }
    },
    [isAuth, authFn]
  );
  return (
    <Layout userData={usrData}>
      <Switch>
        <Route path={path} exact component={DashboardHome} />
        <Route path={`${path}/bz_entity`} component={BusinessEntityPage} />
        <Route path={`${path}/bus`} component={BusServicePage} />
        <Route path={`${path}/mobile`} component={MobileServicePage} />
        <Route path={`${path}/flight`} component={FlightServicePage} />
        <Route path={`${path}/insurance`} component={InsurancePage} />
        <Route path={`${path}/user/change-password`} component={ChangePassword} />
        <Redirect to="/401" />
      </Switch>
    </Layout>
  );
};
