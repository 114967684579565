import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import authReducer from './Reducers/auth'
import commonReducer from './Reducers/common'
import flightReducer from './Reducers/fligth'
export const store = configureStore({
  reducer: {
    auth: authReducer,
    common:commonReducer,
    flight:flightReducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
