import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";


import { WelcomeBanner } from "../../../components/common/welcomeBanner";
import { DashbaordAnnouncements } from "../../../components/dashboard/Home/Announcements";
import { DashbaordBookingCard } from "../../../components/dashboard/Home/Bookings/BookingCard";
import { DashbaordSearchCard } from "../../../components/dashboard/Home/Search";
import { DashbaordUsersCard } from "../../../components/dashboard/Home/Users";
import { b2bConfig } from "../../../config";
import { Instance } from "../../../services";
import { RootState } from "../../../store";
import "./index.css";
interface dashboardHomeProps {
  className?: string;
}
export interface responseProps {
  announcements?: anouncementProps[];
  bookings?: bookingProps;
  businessInfo: businessInfoProps;
  search: serarchProps;
  users: userPorps[];
}

export interface businessInfoProps {
  businessType: string;
  businessID: string;
  businessName: string;
  businessLogo: string;
}
export interface anouncementProps {
  id: number;
  title: string;
  message: string;
  attached: string;
  postdate: string;
}

export interface userPorps {
  userid: number;
  username: string;
  title: string;
  firstname: string;
  lastname: string;
  email: string;
  userrole: string;
  mobileno: string;
  photourl: string;
}

export interface bookingProps {
  onlineFlight: number;
  offlineFlight: number;
  onlineHotel: number;
  onlineBus: number;
}
export interface serarchProps {
  onlineFlight: string;
  onlineHotel: string;
  onlineBus: string;
}
export const DashboardHome: React.FC<dashboardHomeProps> = ({ className }) => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const [responseData, setResponseData] = useState<responseProps>()
  const getData = useCallback(
    () => {
      if (isAuth) {
        Instance({
          baseURL: `${b2bConfig.apiPath.bzEntry}/user/dashboard/`,
          method: "GET"
        })
          .then(res => {
            if(res.data && res.data.Data && res.data.Status && res.data.Status.Code==='01'){
              const response = res.data.Data;
              setResponseData(response)
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    [isAuth]
  );

  useEffect(
    () => {
      getData();
    },
    [getData]
  );

  return (
    <div className={`${className} block`}>
      <div className="block w-full max-w-screen-xl mx-auto">
        <div className="grid grid-flow-row grid-cols-12 gap-4 my-6">
          <div className="col-span-12">
            <WelcomeBanner businessInfo={responseData?.businessInfo} />
          </div>
          <div className="col-span-12 md:col-span-12">
           <DashbaordUsersCard users={responseData?.users} />
          </div>
          <div className="col-span-12 md:col-span-6">
           <DashbaordBookingCard bookings={responseData?.bookings} />
          </div>
          <div className="col-span-12 md:col-span-6">
           <DashbaordSearchCard search={responseData?.search} />
          </div>
         
          <div className="col-span-12 md:col-span-12">
            <DashbaordAnnouncements annoucements={responseData?.announcements} />
          </div>
        </div>
      </div>
    </div>
  );
};
