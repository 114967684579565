import React from "react";
import "./index.css";
import MessageIcon from "../../../assets/alert.png";
interface bMessageProps {
  message: string;
  title?: string;
  type?: "success" | "warning" | "danger";
  className?: string;
}

export const BMessage: React.FC<bMessageProps> = ({
  className,
  title,
  type = "success",
  message
}) => {
  return (
    <div
      className={`${className} rounded-md my-4 w-full flex flex-col md:flex-row px-2 py-1  bg-opacity-10 ${type ===
      "success"
        ? "bg-success"
        : type === "warning" ? "bg-warning" : "bg-danger"}`}
    >
      <div className="flex justify-center items-center content-center px-2 py-2 border border-white rounded-md ">
        <img src={MessageIcon} alt="" className="w-auto h-5 rounded-md " />
      </div>
      <div className="flex justify-center items-center content-center px-2 py-2 ">
        <p className="text-xs text-secondary font-normal">
          {message}
        </p>
      </div>
    </div>
  );
};
