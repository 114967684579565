import React from "react";
import { MainRouter } from "./routes";
import { store } from "./store";
import { Provider as StoreProvider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import { setupInterceptor } from "./services";
export const App: React.FC = () => {
  return (
    <div className="font-primary m-0 p-0">
      <StoreProvider store={store}>
        <ToastProvider placement="top-center">
          <MainRouter />
        </ToastProvider>
      </StoreProvider>
    </div>
  );
};

setupInterceptor(store);
