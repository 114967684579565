import { AgGridColumnProps } from "ag-grid-react";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { BDataGrid } from "../../common/BDataGrid";
import ExchangeImg from "../../../assets/exchange.png";
import "./index.css";
interface exchangeCompProps {
  className?: string;
}

const colDef: AgGridColumnProps[] = [
  {
    headerName: "From",
    field: "currfrom",
    width: 75,
    cellClass: "text-xs text-secondary font-normal",
    headerClass: "text-xs text-secondary uppercase font-normal"
  },
  {
    headerName: "To",
    field: "currto",
    width: 75,
    cellClass: "text-xs text-secondary font-normal",
    headerClass: "text-xs text-secondary uppercase font-normal"
  },
  {
    headerName: "Buy",
    field: "buy",
    width: 100,
    cellClass: "text-xs text-success font-normal",
    headerClass: "text-xs text-success uppercase font-normal"
  },
  {
    headerName: "Sell",
    field: "sell",
    width: 100,
    cellClass: "text-xs text-danger font-normal",
    headerClass: "text-xs text-danger uppercase font-normal"
  }
];
export const ExchangeRate: React.FC<exchangeCompProps> = ({ className }) => {
  const { exRates } = useSelector((state: RootState) => state.common);
  return (
    <div
      className={`${className} fixed z-50 top-36 right-0 flex bg-white shadow-md px-2 py-2 b2b_exchange rounded-l-md`}
    >
      <img src={ExchangeImg} alt="" className="w-auto h-8 rounded-md ex_img" />
      <div className="b2b_ex_container w-full">
        <BDataGrid
          columnDefs={colDef}
          rowData={exRates}
          className="w-full max-w-sm h-28"
        />
      </div>
    </div>
  );
};
