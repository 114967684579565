import { Popover, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { userPorps } from '../../../../pages/dashbaord/Home'
interface dashbarodCard1Props {
  className?: string
  users?: userPorps[]
}
export const DashbaordUsersCard: React.FC<dashbarodCard1Props> = ({
  users
}) => {
  return (
    <div>
      <ul className='flex flex-wrap justify-center sm:justify-start mb-8 sm:mb-0 -space-x-3 -ml-px'>
        {users?.map((u, i) => (
          <li key={i}>
            <div className='block'>
              <Popover className='relative'>
                {({ open }) => (
                  <>
                    <Popover.Button>
                      <img
                        className='w-12 h-12 bg-white rounded-full border-white border'
                        src={u.photourl}
                        width='36'
                        height='36'
                        alt='User 01'
                      />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-200'
                      enterFrom='opacity-0 translate-y-1'
                      enterTo='opacity-100 translate-y-0'
                      leave='transition ease-in duration-150'
                      leaveFrom='opacity-100 translate-y-0'
                      leaveTo='opacity-0 translate-y-1'
                    >
                      <Popover.Panel className='absolute z-10 w-96 flex max-w-sm flex-col mt-3 t'>
                        <div className='overflow-hidden w-full space-y-3 px-2 py-2 flex-col flex rounded-md shadow-md ring-1 bg-white ring-black ring-opacity-5'>
                          <p className='text-gray-600 text-xs font-normal'> {u.title} {u.firstname} {u.lastname} </p>
                          <p className='text-gray-600 text-xs font-normal'> {u.email} </p>
                          <p className='text-gray-600 text-xs font-normal'> {u.mobileno} </p>
                          <p className='text-gray-600 text-xs font-normal'>{u.username} </p>
                          <p className='text-gray-600 text-xs font-normal'> {u.userrole} </p>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
