import React, { Fragment } from 'react'
import './index.css'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
interface b2bPopupProps {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  className?: string
  size?: 'small' | 'medium' | 'large'
  title?: string
}

export const BPopup: React.FC<b2bPopupProps> = ({
  className,
  show,
  setShow,
  children,
  size,
  title
}) => {
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as='div'
          className='absolute inset-0 z-40 flex w-full max-w-screen-md mx-auto font-primary h-64 justify-center items-center content-center px-2 py-2 overflow-y-scroll my-auto'
          open={show}
          onClose={() => {
            setShow(!show)
          }}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-slate-900 opacity-30' />
          </Transition.Child>
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-700'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div
              className={`${className ||
                ''} inline-block relative w-full max-w-5xl p-6 my-8 overflow-hidden text-left h-full align-middle transition-all transform bg-white shadow-xl rounded-2xl`}
            >
              <Dialog.Title
                as='h3'
                className={`${className ||
                  ''}  flex items-center px-5 py-4 border-b border-slate-100 font-semibold text-slate-800`}
              >
                {title}
              </Dialog.Title>
              <Dialog.Description
                as='div'
                className={`${className || ''} px-5 py-4`}
              >
                {children}
              </Dialog.Description>

              <div className='absolute top-5 right-5'>
                <button className='' onClick={() => setShow(!show)}>
                  <XIcon className='w-5 h-5' />
                </button>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  )
}
