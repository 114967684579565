const dev = process.env.NODE_ENV === 'development';

export const b2bConfig = {
  apiPath: {
    bzEntry:
      dev
        ? 'https://b2bapi.gemtechmyanmar.com/dev/api/v1'
        : 'https://b2bapi.gemtechmyanmar.com/prod/api/v1',
    bzEntry2:
      dev
        ? 'https://4xj2wy1zr0.execute-api.ap-southeast-1.amazonaws.com/dev/api/v1'
        : 'https://4xj2wy1zr0.execute-api.ap-southeast-1.amazonaws.com/prod/api/v1',
    dgServices:
      dev
        ? 'https://ds.api.gemtechmyanmar.com/dev/api/v1'
        : 'https://ds.api.gemtechmyanmar.com/prod/api/v1',
    busServices:
      dev
        ? 'https://busapi.gemtechmyanmar.com/dev/api/v1'
        : 'https://busapi.gemtechmyanmar.com/prod/api/v1',
    filghtServices:
      dev
        ? 'https://flightapi.gemtechmyanmar.com/dev/api/v1'
        : 'https://flightapi.gemtechmyanmar.com/prod/api/v1',
    insuranceServices:
      dev
        ? 'https://insuranceapi.gemtechmyanmar.com/dev/api/v1'
        : 'https://insuranceapi.gemtechmyanmar.com/prod/api/v1',
  },
  encrypt_key: 'bqw868/SK5b0hbzd6TdWcxST9AV44eNwnAAcVTCB0P8='
}
interface routeLstPorps {
  text: string
  src: string
}

export const routeList: routeLstPorps[] = [
  // business_admin
  {
    text: '',
    src: ''
  }
]

export const FlightData = {
  UniqueID: 'eJzTd9cPizI2d3IFAAsxAkc%3D',
  qOffice: 'NCE4D31SB',
  source: 'GDS',
  pnr: 'VZ37BE',
  createdate: '2022-02-06T04:36:00',
  lastTicketingDate: '2022-02-22',
  pricing: {
    currency: 'USD',
    total: '245.20',
    base: '185.00',
    grandtotal: '245.20',
    fareType: ['PUBLISHED']
  },
  xxxx: 'xxxx',
  itineraries: [
    {
      segments: [
        {
          departureAirportCode: 'SIN',
          departureDateTime: '2022-02-22T14:05:00',
          departureTerminal: '1',
          arrivalAirportCode: 'BKK',
          arrivalDateTime: '2022-02-22T15:40:00',
          journeyDuration: null,
          numberOfStops: 0,
          carrierCode: 'CX',
          flightNo: '630',
          operating_carrierCode: null,
          cabin: 'ECONOMY',
          class: 'V'
        }
      ]
    }
  ],
  travelers: [
    {
      id: '1',
      gender: 'MALE',
      name: {
        firstName: 'JOHN',
        lastName: 'SNOW'
      },
      documents: [
        {
          number: 'MA9388332',
          expiryDate: '2023-11-11',
          issuanceCountry: 'MY',
          nationality: 'MY',
          documentType: 'PASSPORT',
          holder: true
        }
      ],
      contact: {
        purpose: 'STANDARD',
        phones: [
          {
            deviceType: 'MOBILE',
            countryCallingCode: '95',
            number: '9450126497'
          }
        ],
        emailAddress: 'SIHEINSTER@GMAIL.COM'
      }
    }
  ],
  remarks: {
    general: [
      {
        subType: 'GENERAL_MISCELLANEOUS',
        text: 'ONLINE BOOKING FROM TRAVELS.COM.MM',
        flightOfferIds: ['1']
      },
      {
        subType: 'GENERAL_MISCELLANEOUS',
        text: 'PRICING ENTRY FXP/SBF/FF-ECONLIGHT/R,P,VC-CX,FC-USD/P1',
        flightOfferIds: ['1']
      }
    ],
    airline: [
      {
        subType: 'ADVANCED_TICKET_TIME_LIMIT',
        airlineCode: 'CX',
        text: 'ADV TKT NBR TO CX BY 07FEB 0435 GMT OR SUBJECT TO CANCEL',
        flightOfferIds: ['1']
      }
    ]
  },
  contacts: [
    {
      addresseeName: {
        firstName: 'YI YI KHIN'
      },
      address: {
        lines: ['1434 MOEMAKHA STREET YANGON'],
        postalCode: '11101',
        countryCode: 'MM',
        cityName: 'YANGON'
      },
      purpose: 'STANDARD',
      phones: [
        {
          deviceType: 'MOBILE',
          countryCallingCode: '95',
          number: '9454800571'
        }
      ],
      companyName: 'GEM TRAVEL',
      emailAddress: 'CRM@TRAVELS.COM.MM'
    }
  ]
}
