import React from "react";
import "./index.css";
import MptImg from "../../../assets/mpt.png";
import TelenorImg from "../../../assets/telenor.png";
import OoredooImg from "../../../assets/ooredoo.png";
import MyTelImg from "../../../assets/mytel.png";
import { resultProps } from "../../../helper/CheckMobile";
interface operatorProps {
  operator: resultProps;
  className?: string;
}

interface OpImgProps {
  operatorNm: "mpt" | "telenor" | "mytel" | "ooredoo" | "unknown";
  src: string;
}

export const OpImgLst: OpImgProps[] = [
  {
    operatorNm: "mpt",
    src: MptImg
  },
  {
    operatorNm: "telenor",
    src: TelenorImg
  },
  {
    operatorNm: "ooredoo",
    src: OoredooImg
  },
  {
    operatorNm: "mytel",
    src: MyTelImg
  }
];

export const Operator: React.FC<operatorProps> = ({ className, operator }) => {
  return (
    <div
      className={`${className} w-full flex justify-start space-y-2 md:space-y-0 md:space-x-2 flex-col md:flex-row`}
    >
      {OpImgLst.map((op: OpImgProps, i: number) =>
        <button
          type="button"
          key={i}
          className={`px-1 py-1 rounded-md border-2 ${operator.operatorNm ===
          op.operatorNm
            ? "border-success"
            : "border-secondary dg_img_inactive"}`}
        >
          <img src={op.src} alt="" className={`w-auto h-8 `} />
        </button>
      )}
    </div>
  );
};
