import { ChartOptions } from 'chart.js'

import React from 'react'
import { bookingProps } from '../../../../pages/dashbaord/Home'
import { dataBarProps, HorizontalBarChat } from '../../../common/Chart'

interface dashbarodCard1Props {
  className?: string
  bookings?: bookingProps
}
export const DashbaordBookingCard: React.FC<dashbarodCard1Props> = ({
  bookings
}) => {
  const bookingData: dataBarProps = {
    labels: ['BOOKINGS'],
    datasets: [
      {
        label: 'ONLINE FLIGHT',
        data: [bookings?.onlineFlight ? bookings.onlineFlight : 0],
        backgroundColor: '#61c528',
        
      },
      {
        label: 'OFFLINE FLIGHT',
        data: [bookings?.offlineFlight ? bookings.offlineFlight : 0],
        backgroundColor: '#2196f3'
      },
      {
        label: 'ONLINE BUS',
        data: [bookings?.onlineBus ? bookings.onlineBus : 0],
        backgroundColor: '#9c27b0'
      },
      {
        label: 'ONLINE HOTEL',
        data: [bookings?.onlineHotel ? bookings.onlineHotel : 0],
        backgroundColor: '#ffeb3b'
      }
    ]
  }

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: false,
        text: 'BOOKINGS'
      }
    },
    
  }
  return (
    <div className='px-2 py-2 shadow-md bg-white'>
      <HorizontalBarChat data={bookingData} options={options} />
    </div>
  )
}
