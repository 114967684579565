import React from "react";

import "./index.css";

export interface tabMenuProps {
  name: string;
  path: string;
}

interface dashboardInnerWrapperProps {
  className?: string;
  tabMenus?: tabMenuProps[];
  title?: string;
}

export const DashbaordInnerWrapper: React.FC<dashboardInnerWrapperProps> = ({
  tabMenus,
  children,
  title
}) => {
  return (
    <>
    
      <div className="h-full mb-8 bg-white rounded-md shadow-md">
      <div className="flex flex-col">
        <div className="p-6 space-y-10  w-full">
          {children}
        </div>
      </div>
    </div>
    </>
  );
};
