import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  FlightSearchResult,
  resultDataProps
} from "../../../../components/flight/Result";
import { FSearchForm } from "../../../../components/flight/SearchForm";
import { RootState } from "../../../../store";
import format from "date-fns/format";
import { fsearchformDataProps } from "../FlightSearch";
import "./index.css";
import "./spite.css";
import { b2bConfig } from "../../../../config";
import { Instance } from "../../../../services";
import { setFSearchFormData } from "../../../../store/Reducers/fligth";
import { useToasts } from "react-toast-notifications";

interface flightResultPageProps {
  className?: string;
}

export const FlightResultPage: React.FC<flightResultPageProps> = ({
  className
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { searchForm } = useSelector((state: RootState) => state.flight);
  const { params } = useRouteMatch();
  const queryString: any = params;
  const { addToast } = useToasts();

  const [formData, setformData] = useState<fsearchformDataProps>({
    tripType: searchForm.tripType,
    origin: searchForm.origin,
    destination: searchForm.destination,
    singleDate: new Date(searchForm.singleDate),
    rangeDate: [
      new Date(searchForm.rangeDate[0]),
      new Date(searchForm.rangeDate[1])
    ],
    execludedAirlines: searchForm.execludedAirlines,
    includedAirlines: searchForm.includedAirlines,
    nonStop: searchForm.nonStop,
    adults: searchForm.adults,
    children: searchForm.children,
    infants: searchForm.infants,
    travelClass: searchForm.travelClass,
    currencyCode: searchForm.currencyCode,
    includedAirlineCodes: searchForm.includedAirlines
  });
  const [data, setData] = useState<resultDataProps[]>([]);
  const searchFlight = useCallback(() => {
    const tripType = queryString.tripType;
    const origin = queryString.origin;
    const destination = queryString.destination;
    const depatureDate = queryString.departureDate;
    const returnDate = queryString.returnDate;
    const execludedAirlines =
      queryString.excludedAirlines === "_" ? "" : queryString.excludedAirlines;
    const includedAirlines =
      queryString.includeAirlines === "_" ? "" : queryString.includeAirlines;
    const nonStop = queryString.nonStop;
    const adults = queryString.adults;
    const children = queryString.children;
    const infants = queryString.infants;
    const travelClass = queryString.travelClass;
    const currencyCode = queryString.currencyCode;

    if (origin && destination) {
      if (tripType === "oneway") {
        const apiPath = `${b2bConfig.apiPath.filghtServices}/intflightsearch?tripType=${tripType}&origin=${origin}&destination=${destination}&departureDate=${depatureDate}&excludedAirlines=${execludedAirlines}&includedAirlineCodes=${includedAirlines}&nonStop=${nonStop}&adults=${adults}&children=${children}&infants=${infants}&travelClass=${travelClass}&currencyCode=${currencyCode}`;
        Instance({
          url: apiPath,
          method: "GET"
        })
          .then((res) => {
            if (
              res.data.success &&
              // res.data.Data &&
              res.data 
              //&& data.length > 0
              // res.data.Status.Code === "01"
            ) {
              const response: resultDataProps[] = res.data.data;
              setData(response);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const apiPath = `${b2bConfig.apiPath.filghtServices}/intflightsearch?tripType=${tripType}&origin=${origin}&destination=${destination}&departureDate=${depatureDate}&returnDate=${returnDate}&excludedAirlines=${execludedAirlines}&includedAirlineCodes=${includedAirlines}&nonStop=${nonStop}&adults=${adults}&children=${children}&infants=${infants}&travelClass=${travelClass}&currencyCode=${currencyCode}`;
        Instance({
          url: apiPath,
          method: "GET"
        })
          .then((res) => {
            if (
              res.data.success &&
              // res.data.Data &&
              res.data
              // res.data.Status.Code === "01"
            ) {
              const response: resultDataProps[] = res.data.data;
              setData(response);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [queryString]);

  useEffect(() => {
    searchFlight();
    localStorage.removeItem("flight-info");
  }, [searchFlight]);

  const SubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.preventDefault();

    const tripType = formData.tripType;
    const origin =
      formData.origin && formData.origin.value ? formData.origin.value : "";
    const destination =
      formData.origin && formData.destination.value
        ? formData.destination.value
        : "";
    const depatureDate =
      (formData.tripType === "oneway" && formData.singleDate) ||
      (formData.tripType === "oneway" && formData.rangeDate)
        ? format(formData.singleDate, "yyyy-MM-dd")
        : format(formData.rangeDate[0], "yyyy-MM-dd");
    const returnDate =
      formData.tripType === "roundtrip" && formData.rangeDate
        ? format(formData.rangeDate[1], "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd");
    const execludedAirlines =
      formData.execludedAirlines && formData.execludedAirlines !== undefined
        ? formData.execludedAirlines
        : "_";
    const includedAirlines = formData.includedAirlines;
    const nonStop = formData.nonStop;
    const adults = formData.adults;
    const children = formData.children;
    const infants = formData.infants;
    const travelClass = formData.travelClass;
    const currencyCode = formData.currencyCode;

    dispatch(
      setFSearchFormData({
        tripType: formData.tripType,
        origin: formData.origin,
        destination: formData.destination,
        singleDate: format(formData.singleDate, "yyyy-MM-dd"),
        rangeDate: [
          format(formData.rangeDate[0], "yyyy-MM-dd"),
          format(formData.rangeDate[1], "yyyy-MM-dd")
        ],
        execludedAirlines: formData.execludedAirlines
          ? formData.execludedAirlines
          : "_",
        includedAirlines: formData.includedAirlines
          ? formData.includedAirlines
          : "_",
        nonStop: formData.nonStop,
        adults: formData.adults,
        children: formData.children,
        infants: formData.infants,
        travelClass: formData.travelClass,
        currencyCode: formData.currencyCode
      })
    );
    if ((origin && destination) || origin === destination) {
      if (tripType === "oneway") {
        const params = `/dashboard/flight/onewaySearch/${tripType}/${origin}/${destination}/${depatureDate}/${execludedAirlines}/${includedAirlines}/${nonStop}/${adults}/${children}/${infants}/${travelClass}/${currencyCode}`;
        history.push(params);
      } else {
        const params = `/dashboard/flight/roundTripSearch/${tripType}/${origin}/${destination}/${depatureDate}/${returnDate}/${execludedAirlines}/${includedAirlines}/${nonStop}/${adults}/${children}/${infants}/${travelClass}/${currencyCode}`;
        history.push(params);
      }
    } else {
      addToast(`Please fill input completely`, {
        appearance: "warning",
        autoDismiss: true
      });
    }
  };
  return (
    <div className={`${className} grid grid-flow-row grid-cols-12 gap-3`}>

        

      {/*}
      
      <div className="col-span-12 lg:col-span-4 my-10 rounded-md bg-primary bg-opacity-5">
        <form onSubmit={(e) => SubmitForm(e)}>
          <FSearchForm formData={formData} setFormData={setformData} />
        </form>
      </div>
      {*/}

      <div className="col-span-12 lg:col-span-12 rounded-md">
        <div className="flex flex-row justify-start items-center content-center px-4 py-8  border-b rounded-b-md  border-gray-200">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-funnel text-primary font-bold"
            viewBox="0 0 16 16">
            <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
          </svg>
          <span className="tracking-widest text-secondary text-sm ml-2 flex flex-row space-x-2 justify-start items-center content-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-lightning-charge"
              viewBox="0 0 16 16">
              <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z" />
            </svg>
            {queryString.tripType}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-geo-alt pr-0.5"
              viewBox="0 0 16 16">
              <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
            {queryString.origin}~{queryString.destination}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-calendar-event mr-2"
              viewBox="0 0 16 16">
              <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
            </svg>
            {` `}
            <span className="pl-0.15">
              {queryString.departureDate}
              {queryString.returnDate ? `~${queryString.returnDate}` : ""}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-people-fill"
              viewBox="0 0 16 16">
              <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              <path
                fillRule="evenodd"
                d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
              />
              <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
            </svg>
            <span className="pl-0.5">
              Adult: {queryString.adults}, Child: {queryString.children},
              Infant: {queryString.infants}
            </span>
          </span>
          <span>
            <button
              onClick={() => history.goBack()}
              className="px-2 py-1 hover:bg-gray-300 rounded-md text-warning text-xs">
              Modify Search
            </button>
          </span>
        </div>
        {data.length > 0 ? (
          <FlightSearchResult data={data} />
        ) : (
          <div className="flex justify-center w-full h-96 content-center items-center flex-col">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              fill="currentColor"
              className="bi bi-folder-fill text-primary"
              viewBox="0 0 16 16">
              <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z" />
            </svg>
            <span className="text-lg tracking-widest text-secondary">
              No matches found!
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
