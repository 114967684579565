import React, { useEffect, useState } from "react";
import './index.css'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useSelector } from "react-redux";
import { RootState } from "./../../../store";
import { destinationsProps } from '../../../pages/dashbaord/BusService/BusSearch'

interface busSearchFormProps {
  destinations: any[]
  formData: any
  className?: string
  setFormData: React.Dispatch<React.SetStateAction<any>>
  onSubmit: () => void
}

export const BusSearchForm: React.FC<busSearchFormProps> = ({
  className,
  destinations,
  formData,
  setFormData,
  onSubmit
}) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const { busSearchParms } = useSelector((state: RootState) => state.common);

  useEffect(() => {
    if(busSearchParms.depfrom && busSearchParms.arrvto && firstLoad){
      setFirstLoad(!firstLoad);
      //onSubmit();
    }    
  }, [busSearchParms,firstLoad, onSubmit])
  
  return (
    <div
      className={`${className} w-full flex flex-col space-y-2 my-4 px-2 py-2`}
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}
        className=''
      >
        <div className='flex flex-col mx-auto  space-y-6 max-w-screen-md rounded-md shadow-md px-4 py-4 bg-white'>
          <div className='flex justify-between items-center content-center px-2 py-1 border-yellow-200 rounded-md border'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              fill='currentColor'
              className='bi bi-geo-alt-fill text-gray-400'
              viewBox='0 0 16 16'
            >
              <path d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z' />
            </svg>
            <div className='w-full'>
              <Select
                isClearable={true}
                name='depfrom'
                value={destinations.filter(d=>d.pk===formData.depfrom)}
                id='depfrom'
                isSearchable={true}
                placeholder='Your location...'
                className='text-sm tracking-widest text-center'
                onChange={option =>
                  setFormData({ ...formData, depfrom: option?.pk })
                }
                options={destinations}
                getOptionValue={(option: destinationsProps) =>
                  option.pk.toString()
                }
                getOptionLabel={(option: destinationsProps) =>
                  `${option.destinationNameEN}(${option.destinationNameMM})`
                }
              />
            </div>

            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              fill='currentColor'
              className='bi bi-geo-alt-fill text-gray-400'
              viewBox='0 0 16 16'
            >
              <path d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z' />
            </svg>
            <div className='w-full'>
              <Select
                isClearable={true}
                name='arrvto'
                value={destinations.filter(d=>d.pk===formData.arrvto)}
                options={destinations}
                id='arrvto'
                onChange={option =>
                  setFormData({ ...formData, arrvto: option?.pk })
                }
                getOptionValue={(option: destinationsProps) =>
                  option.pk.toString()
                }
                getOptionLabel={(option: destinationsProps) =>
                  `${option.destinationNameEN}(${option.destinationNameMM})`
                }
                isSearchable={true}
                placeholder='Destination...'
                className='text-sm tracking-widest text-center'
              />
            </div>
          </div>
          <div className='flex justify-between items-center content-center px-2 py-1 border-yellow-200 rounded-md border'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-calendar-event text-gray-400 '
              viewBox='0 0 16 16'
            >
              <path d='M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z' />
              <path d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z' />
            </svg>
            <div className='w-full'>
              <DatePicker
                id='depdate'
                dateFormat='yyyy-MM-dd'
                className=' w-full focus:outline-none text-center text-secondary text-sm px-3 py-2 rounded-md'
                placeholderText='Select Date'
                onChange={date => {
                  setFormData({ ...formData, depdate: date })
                }}
                minDate={new Date()}
                maxDate={
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 6,
                    new Date().getDate()
                  )
                }
                selected={formData.depdate}
                name='depdate'
              />
            </div>
          </div>
          <div className='flex justify-between items-center content-center px-2 py-2 border-yellow-200 rounded-md border'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-people text-gray-400'
              viewBox='0 0 16 16'
            >
              <path d='M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z' />
            </svg>
            <div className='w-full'>
              <div className='w-full flex justify-center items-center content-center space-x-4'>
                <button
                  type='button'
                  onClick={() => {
                    setFormData({
                      ...formData,
                      pax: formData.pax > 1 ? formData.pax - 1 : formData.pax
                    })
                  }}
                  className='w-full flex justify-center items-center content-center px-2 py-1'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    fill='currentColor'
                    className='bi bi-dash-circle text-danger'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
                    <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z' />
                  </svg>
                </button>
                <span className='text-sm font-bold text-gray-400'>
                  {formData.pax}
                </span>
                <button
                  type='button'
                  onClick={() => {
                    setFormData({
                      ...formData,
                      pax: formData.pax < 100 ? formData.pax + 1 : formData.pax
                    })
                  }}
                  className='w-full flex justify-center items-center content-center px-2'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    fill='currentColor'
                    className='bi bi-plus-circle text-success'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className='flex justify-between items-center content-center px-2 py-2'>
            <div className='w-full'>
              <div className='flex w-full justify-start items-center content-center space-x-4'>
                <label className='flex justify-start items-center content-center space-x-3'>
                  <input
                    checked={formData.nationality === 'MM'}
                    type='radio'
                    name='nationality'
                    id='nationality'
                    value='MM'
                    onChange={e => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value
                      })
                    }}
                  />
                  <span className='text-xs text-gray-400 tracking-widest'>
                    LOCAL
                  </span>
                </label>
                <label className='flex justify-start items-center content-center space-x-3'>
                  <input
                    checked={formData.nationality === 'FOREIGN'}
                    type='radio'
                    name='nationality'
                    id='nationality'
                    value='FOREIGN'
                    onChange={e => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value
                      })
                    }}
                  />
                  <span className='text-xs text-gray-400 tracking-widest'>
                    FOREIGN
                  </span>
                </label>
              </div>
            </div>
          </div>

          <button
            type='submit'
            className='border px-2 py-2 flex w-full rounded-md justify-between items-center content-center border-yellow-400 bg-yellow-400 hover:bg-yellow-600 text-white'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-search'
              viewBox='0 0 16 16'
            >
              <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
            </svg>
            <span className='w-full text-center'>Search</span>
          </button>
        </div>

        {/* <div className='grid grid-cols-1 md:grid-cols-6 gap-3'>
          
          <div className=''>
            <label htmlFor='' className='text-sm text-gray-500 tracking-widest'>
              Nationality
            </label>{' '}
            <Select
              className='text-sm tracking-widest rounded-md'
              name='nationality'
              id='nationality'
              options={[
                {
                  value: 'MM',
                  text: 'LOCAL'
                },
                {
                  value: 'FOREIGN',
                  text: 'FOREIGN'
                }
              ]}
              isClearable={true}
              getOptionLabel={option => option.text}
              getOptionValue={option => option.value}
              onChange={(e: any) => {
                setFormData({ ...formData, nationality: e.value })
              }}
            />
          </div>
          <div className=''>
            <label className='text-sm text-gray-500 tracking-widest' htmlFor=''>
              No. of Pressengers
            </label>
            <div
              style={{ padding: 2 }}
              className='grid grid-cols-3 rounded-md  border border-gray-300'
            >
              <button
                type='button'
                onClick={() => {
                  setFormData({
                    ...formData,
                    pax: formData.pax > 1 ? formData.pax - 1 : formData.pax
                  })
                }}
                className='w-full flex justify-center items-center content-center px-2 py-1'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  fill='currentColor'
                  className='bi bi-dash-square text-warning'
                  viewBox='0 0 16 16'
                >
                  <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                  <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z' />
                </svg>
              </button>
              <button
                type='button'
                className='w-full flex text-gray-400 justify-center items-center content-center px-2 py-1'
              >
                {formData.pax}
              </button>
              <button
                type='button'
                onClick={() => {
                  setFormData({
                    ...formData,
                    pax: formData.pax < 100 ? formData.pax + 1 : formData.pax
                  })
                }}
                className='w-full flex justify-center items-center content-center px-2'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  fill='currentColor'
                  className='bi bi-plus-square text-success'
                  viewBox='0 0 16 16'
                >
                  <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                  <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                </svg>
              </button>
            </div>
          </div>
          <div className=''>
            <label htmlFor='' className='text-sm text-gray-500 tracking-widest'>
              Date
            </label>{' '}
            <DatePicker
              id='depdate'
              dateFormat='yyyy-MM-dd'
              className='form-input border text-center border-gray-300 w-full text-secondary text-sm px-3 py-2 rounded-md'
              placeholderText='Select Date'
              onChange={date => {
                setFormData({ ...formData, depdate: date })
              }}
              minDate={new Date()}
              maxDate={
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() + 6,
                  new Date().getDate()
                )
              }
              selected={formData.depdate}
              name='depdate'
            />
          </div>
          <div className='flex px-2 py-2 justify-center items-center content-center pt-3'>
            <Bbutton type='submit' title='Search' />
          </div>
        </div> */}
      </form>
    </div>
  )
}
