import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { airlinePorps } from "../../interfaces";
import { each } from 'lodash'
import { autoCompleOptions } from "../../components/common/BAutocomplete";
import { format } from "date-fns";


export interface flightStateProps{
    airlines?: autoCompleOptions[]
    searchForm:fsearchformStoreDataProps
}

export interface fsearchformStoreDataProps {
    tripType: "oneway" | "roundtrip";
    origin: any;
    destination: any;
    singleDate: string;
    rangeDate: string[];
    execludedAirlines?: any;
    includedAirlines?: any;
    includedAirlineCodes?: any;
    nonStop: "true" | "false";
    adults: number;
    children: number;
    infants: number;
    travelClass: "Y" | "C" | "F" | "S";
    currencyCode: "USD" | "MMK";
  }

const initialState:flightStateProps = {
    airlines:[],
    searchForm:{
        tripType: "oneway",
    origin: "",
    destination: "",
    singleDate:format(new Date(),'yyyy-MM-dd'),
    rangeDate: [
        format(new Date(),'yyyy-MM-dd'),
        format(new Date( new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 2),'yyyy-MM-dd')
    ],
    execludedAirlines: "_",
    includedAirlines: "_",
    includedAirlineCodes:"_",
    nonStop: "true",
    adults: 1,
    children: 0,
    infants: 0,
    travelClass: "Y",
    currencyCode: "USD"
    }
}

export const flightSlice = createSlice({
    name:'flight',
    initialState,
    reducers:{
        setAirlines:(state,action:PayloadAction<airlinePorps[]>)=>{
            let options:autoCompleOptions[] = [];
            each(action.payload,e=>{
                const params:autoCompleOptions = {
                    label:e.name,
                    value:e
                }
                options.push(params)
            })
            state.airlines=options;
        },
        setFSearchFormData:(state,action:PayloadAction<fsearchformStoreDataProps>)=>{
            state.searchForm = action.payload
        }
    }
})


export const {setAirlines,setFSearchFormData} = flightSlice.actions

export default flightSlice.reducer;
