import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { Instance } from "../../../../services";
import { b2bConfig } from "../../../../config";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../../components/common/SelectTable";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { BookingInfoPDF } from "./pdf";

type BookingIDParams = {
  id: string;
};

export interface BookingDetailPageProps {
  className?: string;
  result: object;
  premiumRate: any;
  route: any;
  travelStartDate: Date;
  travelEndDate: Date;
  paxNo: string;
  status: string;
  insuredInfo: any;
  totalAmount: string;
  loading: boolean;
  bookingDetail: [];
}

export const BookingDetail: React.FC<BookingDetailPageProps> = () => {
  const [result, setResult] = useState<BookingDetailPageProps>();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { id } = useParams<BookingIDParams>();

  const getDetail = useCallback(() => {
    document.body.classList.add("loading-indicator");
    const accessToken = sessionStorage.getItem("accessToken");

    if (id) {
      Instance({
        url: `${b2bConfig.apiPath.insuranceServices}/insurancebooking/${id}`,
        headers: {
          Authorization: `JWT ${accessToken}`
        }
      })
        .then((res) => {
          if (res.status === 200) {
            const response = res.data;
            setResult(response);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err, ["transaction_fetch_error"]);
        });
    } else {
      history.goBack();
    }
  }, [id, history]);

  useEffect(() => {
    getDetail();
  }, [getDetail]);

  return (
    <React.Fragment>
      <div className="flex mb-3">
        <div className="">
          <button
            onClick={() => history.goBack()}
            className="px-2 py-1 hover:bg-gray-300 rounded-md">
            <ChevronLeftIcon className="w-4 h-4 mt-1" />
          </button>
        </div>
        <div className="ml-1 mt-0.5">
          <span className="text-md">Insurance Booking Detail</span>
        </div>
      </div>

      {!loading && result && (
        <>
          <div className={`bg-white rounded-md shadow-md mb-4`}>
            <div className="p-6 space-y-3 w-full text-sm">
              <div className="flex flex-row">
                <div className="w-40">Trip Type</div>
                <div className="capitalize">
                  <span className="px-4">-</span>
                  {result.premiumRate.tripType.toLowerCase()}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-40">Premium Rate</div>
                <div>
                  <span className="px-4">-</span>
                  {`${
                    result.premiumRate.duration
                  } ${result.premiumRate.durationUnit.toLowerCase()} ${parseInt(
                    result.premiumRate.ratePerUnit
                  )}ks per unit`}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-40">Start Date</div>
                <div>
                  <span className="px-4">-</span>
                  {moment(result.travelStartDate).format("YYYY-MM-DD")}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-40">End Date</div>
                <div>
                  <span className="px-4">-</span>
                  {moment(result.travelEndDate).format("YYYY-MM-DD")}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-40">Route</div>
                <div>
                  <span className="px-4">-</span>
                  {result.route}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-40">Traveler No.</div>
                <div>
                  <span className="px-4">-</span>
                  {result.paxNo}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-40">Stauts</div>
                <div className=" capitalize">
                  <span className="px-4">-</span>
                  {result.status.toLowerCase()}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 my-4">
            <h4 className="mb-2">Traveler Information</h4>

            <SelectTable className="bg-white rounded-md shadow-md">
              <thead className="md:table-fixed text-xs m-4 font-normal border-t border-b text-gray-500 bg-gray-200 border-gray-200">
                <TableRow>
                  <TableCell isHeader={true}>
                    <span className="pl-1">No</span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span>
                      Insured
                      <br /> Name
                    </span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span>
                      Insured
                      <br /> NRIC/Passport
                    </span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span>
                      Beneficiary
                      <br /> Name
                    </span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span>
                      Beneficiary
                      <br /> NRIC/Passport
                    </span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span>Contact No</span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span>Unit</span>
                  </TableCell>
                  <TableCell isHeader={true}>
                    <span className="">Total (MMK)</span>
                  </TableCell>
                </TableRow>
              </thead>

              <tbody className="text-xs font-normal divide-y divide-gray-100">
                {Object.values(result.bookingDetail)
                  .map((m) => m)
                  .map((a: any, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <span className="pl-2">{i + 1}</span>
                      </TableCell>
                      <TableCell>
                        <span>{a.insuredInfo.insuredName}</span>
                      </TableCell>
                      <TableCell>
                        <span>{a.insuredInfo.insuredID}</span>
                      </TableCell>
                      <TableCell>
                        <span>{a.insuredInfo.beneficiaryName}</span>
                      </TableCell>
                      <TableCell>
                        <span>{a.insuredInfo.beneficiaryID}</span>
                      </TableCell>
                      <TableCell>
                        <span>{a.insuredInfo.contactNo}</span>
                      </TableCell>
                      <TableCell>
                        <span>{a.unitno}</span>
                      </TableCell>
                      <TableCell>
                        <span>{parseInt(a.totalAmount)}</span>
                      </TableCell>
                    </TableRow>
                  ))}
              </tbody>
            </SelectTable>

            <div className="flex flex-row justify-between items-center my-5">
              <div>
                {result.status === "CONFIRM" && (
                  <button className="border px-4 py-2 flex w-full rounded-md justify-between items-center content-center border-yellow-400 bg-yellow-400 hover:bg-yellow-600 text-white">
                    <PDFDownloadLink
                      document={
                        <BookingInfoPDF
                          result={result}
                          premiumRate={result.premiumRate}
                          route={result.route}
                          travelStartDate={result.travelStartDate}
                          travelEndDate={result.travelEndDate}
                          paxNo={result.paxNo}
                          status={result.status}
                          insuredInfo={result.insuredInfo}
                          totalAmount={result.totalAmount}
                          loading={false}
                          bookingDetail={result.bookingDetail}
                        />
                      }
                      fileName={`insurance_booking_detail.pdf`}>
                      {({ loading }) =>
                        loading ? "Loading document..." : "Download"
                      }
                    </PDFDownloadLink>
                  </button>
                )}
              </div>

              <div>
                <span className="mr-2">Grand Total:</span>
                <span>{parseInt(result.totalAmount)}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};
