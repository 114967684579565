import React from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";
import { NotFoundPage } from "../pages/404";
import { AuthPage } from "../pages/auth";
import { DashbaordPage } from "../pages/dashbaord";
import { UnauthenticatedPage } from "../pages/unauthenticated";

export const MainRouter: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={AuthPage} />
        <Route path="/dashboard" component={DashbaordPage} />
        <Route exact path="/404" component={NotFoundPage} />
        <Route exact path="/401" component={UnauthenticatedPage} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
};
