export const mptPattern = `^((09|\\+?959)(5\\d{6}|4\\d{7,8}|2\\d{6,8}|3\\d{7,8}|6\\d{6}|8\\d{6}|7\\d{7}|9(0|1|9)\\d{5,6}))$`
export const telenorPattern = `^(09|\\+?959)7([5-9])\\d{7}$`
export const ooredooPattern = `^(09|\\+?959)9(5|7|6)\\d{7}$`
export const mytelPattern = `^(09|\\+?959)6(8|9)\\d{7}$`
export interface resultProps {
  operatorNm: 'mpt' | 'telenor' | 'mytel' | 'ooredoo' | 'unknown'
  billerCode:
    | 'TelenoreLoad'
    | 'MpteLoad'
    | 'OoredooeLoad'
    | 'MyteleLoad'
    | 'unknown'
  dataCode:'MPTDataPack'|'TelenorDataPack'|'OoredooDataPack'|'MyTelDataPack'|'unknown'
}
export const checkMobile = (num: string) => {
  let result: resultProps = {
    operatorNm: 'unknown',
    billerCode: 'unknown',
    dataCode:'unknown'
  }
  if (num.length > 9) {
    if (num.match(mptPattern)) {
      result.operatorNm = 'mpt'
      result.billerCode = 'MpteLoad'
      result.dataCode='MPTDataPack'
    } else if (num.match(telenorPattern)) {
      result.operatorNm = 'telenor'
      result.billerCode='TelenoreLoad'
      result.dataCode='TelenorDataPack'
    } else if (num.match(ooredooPattern)) {
      result.operatorNm = 'ooredoo'
      result.billerCode = 'OoredooeLoad'
      result.dataCode='OoredooDataPack'
    } else if (num.match(mytelPattern)) {
      result.operatorNm = 'mytel'
      result.billerCode = 'MyteleLoad'
      result.dataCode='MyTelDataPack'
    } else {
      result.operatorNm = 'unknown'
      result.billerCode = 'unknown'
      result.dataCode='unknown'
    }
  }

  return result
}
