import React, { useState } from "react";
import { ExchangeRate } from "../../components/dashboard/exRates";
import { userDataProps } from "../../interfaces";
import { DashboardHeader } from "./Header";
import { DashboardSidebar } from "./Sidebar";
interface layoutProps {
  className?: string;
  userData?: userDataProps;
}
export const Layout: React.FC<layoutProps> = ({ className, children }) => {
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);
  return (
    <div className="flex h-screen overflow-hidden bg-gray-200 ">
      {/* Sidebar */}
      <DashboardSidebar
        sideBarOpen={sideBarOpen}
        setSideBarOpen={setSideBarOpen}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto ">
        <DashboardHeader
          sideBarOpen={sideBarOpen}
          setSideBarOpen={setSideBarOpen}
        />
        <main>
          <div className="w-full px-4 py-8 mx-auto sm:px-6 lg:px-8 max-w-9xl mb-24">
            {children}
          </div>
        </main>
        <ExchangeRate />
      </div>
    </div>
  );
};
