import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Bbutton } from '../../../../components/common/Bbutton'
import { BInput } from '../../../../components/common/BInput'
import { BMessage } from '../../../../components/common/BMessage'
import { BSelect } from '../../../../components/common/BSelect'
import { b2bConfig } from '../../../../config'
import { Instance } from '../../../../services'
import { RootState } from '../../../../store'

import './index.css'

interface bzExchangePageProps {
  className?: string
}

interface exchangeFormProps {
  curr: 'usdtmmk' | 'mmktusd'
  amount: number
}

export const ExchangePage: React.FC<bzExchangePageProps> = ({ className }) => {
  const [formData, setFormData] = useState<exchangeFormProps>({
    curr: 'usdtmmk',
    amount: 0
  })

  const { exRates } = useSelector((state: RootState) => state.common)
  const { addToast } = useToasts()
  const SubmitExchange = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      if (formData.amount > 0) {
        Instance({
          url: `${b2bConfig.apiPath.bzEntry}/user/exchange/`,
          method: 'POST',
          data: {
            currfrom: formData.curr === 'mmktusd' ? 'MMK' : 'USD',
            currto: formData.curr === 'mmktusd' ? 'USD' : 'MMK',
            action: formData.curr === 'mmktusd' ? 'buy' : 'sell',
            amount: formData.amount
          }
        })
          .then(res => {
            if (res.status === 200) {
              if (
                res.data &&
                res.data.Status &&
                res.data.Status.Code === '01'
              ) {
                addToast('success', {
                  appearance: 'success',
                  autoDismiss: true
                })
              }
            }
          })
          .catch(err => {
            addToast(`${JSON.stringify(err)}`, {
              appearance: 'warning',
              autoDismiss: true
            })
          })
          .finally(() => {
            setFormData({
              curr: 'usdtmmk',
              amount: 0
            })
          })
      } else {
        addToast('Please enter amount', {
          appearance: 'warning',
          autoDismiss: true
        })
      }
    } catch (error) {
      addToast(`System error - ${JSON.stringify(error)}`, {
        appearance: 'error',
        autoDismiss: true,
        id: 'system_error'
      })
    }
  }
  return (
    <div className={`${className} w-full`}>
      <form
        onSubmit={e => {
          SubmitExchange(e)
        }}
        className='w-full max-w-screen-md shadow-sm rounded-md px-4 py-4 flex flex-col space-y-2 bg-white'
      >
        <BSelect
          name='curr'
          id='curr'
          formData={formData}
          setFormData={setFormData}
          selectOptions={[
            {
              text: 'USD => MMK',
              value: 'usdtmmk'
            },
            {
              text: 'MMK=>USD',
              value: 'mmktusd'
            }
          ]}
          title='Currency From ~ To'
        />

        
        <BInput
          title='Amount'
          formData={formData}
          setFormData={setFormData}
          name='amount'
          id='amount'
          type='number'
        />
        {formData.amount > 0 &&
          exRates?.map((e, i) => (
            <BMessage
              key={i}
              message={`${
                formData.curr === 'mmktusd'
                  ? e.buy * formData.amount +
                    'MMK will be added to your MMK account and ' +
                    formData.amount +
                    ' USD will be reduced from your USD account.'
                  : e.sell * formData.amount +
                    'MMK  will be reduced from your MMK account and' +
                    formData.amount +
                    'USD will be added to your USD account.'
              }`}
              type='danger'
            />
          ))}

        <div className='flex'>
          <Bbutton disabled title='Confirm' type='submit' />
        </div>
      </form>
    </div>
  )
}
