import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Instance } from "../../../../services";
import { b2bConfig } from "../../../../config";
import { BusSearchForm } from "../../../../components/bus/SearchForm";
import { BusCard } from "../../../../components/bus/BusCard";
import { BusFilter } from "../../../../components/bus/BusFilter";
import { useToasts } from "react-toast-notifications";
import format from "date-fns/format";
import { each, find } from "lodash";
import { setBusSearchParms } from "../../../../store/Reducers/common";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";


interface busSearchProps {
  className?: string;
}

export interface busSearchFormProps {
  depfrom: any;
  arrvto: any;
  depdate: any;
  pax: number;
  nationality: any;
  selectedBus: string;
}

export interface destinationsProps {
  destinationCode: string;
  destinationNameEN: string;
  destinationNameMM: string;
  destinationNameMMZaw: string;
  pk: number;
}

export interface localTicketPriceProps {
  amount: string;
  currency: string;
}
export interface foreignerTicketPrice {
  amount: string;
  currency: string;
}

export interface otherBusResponseProps {
  avlseats: string;
  busType: busTypeProps;
  busTypeNameEN: string;
  busTypeNameMM: string;
  departureDate: string;
  departureDateTime: string;
  estArrivalDateTime: string;
  destinationNameEN: string;
  destinationNameMM: string;
  durationInMinutes: string;
  destinationName: destinationNameProps;
  sourceName: destinationNameProps;
  gateNameEN: string;
  gateNameMM: string;
  gateName: string;
  operatorLogoUrl: string;
  operatorNameEN: string;
  operatorNameMM: string;
  operatorcode: string;
  operator: operatorProps
  fullRouteName: fullRouteNameProp;
  route: string;
  routeNameEN: string;
  routeNameMM: string;
  seatPlanUrl: string;
  sourceNameEN: string;
  sourceNameMM: string;
  tripID: string;
  localTicketPrice?: localTicketPriceProps;
  foreignerTicketPrice?: localTicketPriceProps;
  unitTicketPrice: unitTicketPriceProps;
  totalTicketPrice: unitTicketPriceProps;
  correlationIntegrity: string;
  correlationId: string;
  
}

interface destinationNameProps{
  en: string;
  my: string;
}
interface unitTicketPriceProps{
  amount: string;
  currency: string;
}
interface busTypeProps{
  en:string;
  my:string;
}

interface operatorProps {
  code: string;
  name: oprnameProps;
  logoUrl: string;
}
interface oprnameProps{
  en:string;
  my:string;
}

interface fullRouteNameProp{
  en: string;
  my: string;
}

export const BusSearchPage: React.FC<busSearchProps> = ({ className }) => {
  const { busSearchParms } = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();
  const [formData, setformData] = useState<busSearchFormProps>({
    depfrom: busSearchParms.depfrom,
    arrvto: busSearchParms.arrvto,
    depdate: new Date(busSearchParms.depdate),
    pax: busSearchParms.pax,
    nationality: busSearchParms.nationality,
    selectedBus: busSearchParms.selectedBus
  });
  const [busResponseData, setbusResponseData] = useState<
    otherBusResponseProps[]
  >([]);
  const [destinations, setDestinations] = useState<destinationsProps[]>([]);
  const { addToast } = useToasts();
  const [operatorLst, setoperatorLst] = useState<
    { text: string; value: string }[]
  >([]);
  const getDestinations = useCallback(() => {
    Instance({
      url: `${b2bConfig.apiPath.busServices}/destinations/`,
      method: "GET"
    })
      .then(res => {
        if (
          res.data &&
          res.data.data 
        ) {
          const responseData: destinationsProps[] = res.data.data;
          setDestinations(responseData);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(
    () => {
      getDestinations();
    },
    [getDestinations]
  ); 

  const onSubmit = () => {
    if (
      formData.depfrom &&
      formData.arrvto &&
      formData.nationality &&
      formData.pax > 0 &&
      formData.depdate
    ) {
      dispatch(
        setBusSearchParms({
          depdate: format(formData.depdate, "yyyy-MM-dd"),
          depfrom: formData.depfrom,
          arrvto: formData.arrvto,
          nationality: formData.nationality,
          pax: formData.pax,
          selectedBus: formData.selectedBus
        })
      );

      const formInput = new FormData();
      formInput.append("depfrom", formData.depfrom);
      formInput.append("arrvto", formData.arrvto);
      formInput.append("depdate", format(formData.depdate, "yyyy-MM-dd"));
      formInput.append("pax", formData.pax.toString());
      formInput.append("nationality", formData.nationality);

      const params = `depfrom=${formData.depfrom}&arrvto=${formData.arrvto}&depdate=${format(formData.depdate, "yyyy-MM-dd")}&pax=${formData.pax.toString()}&nationality=MM`;

      Instance({
        url: `${b2bConfig.apiPath.busServices}/searchbus-schedule/?${params}`,
        method: "GET",
        data: formInput
      })
        .then(res => {
          if (res.data && res.data.success && res.data.success === true) {
            const response: otherBusResponseProps[] = res.data.data;
            setbusResponseData(response);

            let operatorTmp: { text: string; value: string }[] = [];
            each(response, r => {
              if (find(operatorTmp, v => v.value === r.operator.code)) {
              } else {
                if (r.operator) {
                  operatorTmp.push({
                    text: r.operator.name.en,
                    value: r.operator.code
                  });
                }
              }
            });
            setoperatorLst(operatorTmp);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      addToast(`Please fill form first.`, {
        appearance: "info",
        autoDismiss: true
      });
    }
  };

  return (
    <div
      className={`${className} w-full flex flex-col space-y-2 my-4 px-2 py-2`}
    >
      <BusSearchForm
        onSubmit={onSubmit}
        className="border-b pb-7 border-gray-200"
        formData={formData}
        setFormData={setformData}
        destinations={destinations}
      />
      {busResponseData.length > 0 &&
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 md:col-span-3">
            <BusFilter
              formData={formData}
              setFormData={setformData}
              responseData={operatorLst}
            />
          </div>
          <div className="col-span-12 md:col-span-9">
            <div className="grid grid-cols-1 md:grid-cols-1">
              {busResponseData
                .filter(
                  bus =>
                    formData.selectedBus
                      ? bus.operator.code === formData.selectedBus
                      : formData.selectedBus !== bus.operator.code
                )
                .map((bus, i) =>
                  <BusCard formData={formData} busData={bus} key={i} />
                )}
            </div>
          </div>
        </div>}
    </div>
  );
};
