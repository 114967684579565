import React, { useState } from "react";

import { FSearchForm } from "../../../../components/flight/SearchForm";
import format from "date-fns/format";
import "./index.css";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import { setFSearchFormData } from "../../../../store/Reducers/fligth";

interface flightSearchPageProps {
  className?: string;
}
export interface fsearchformDataProps {
  tripType: "oneway" | "roundtrip";
  origin: any;
  destination: any;
  singleDate: Date;
  rangeDate: Date[];
  execludedAirlines?: any;
  includedAirlines?: any;
  nonStop: "true" | "false";
  adults: number;
  children: number;
  infants: number;
  travelClass: "Y" | "C" | "F" | "S";
  currencyCode: "USD" | "MMK";
  includedAirlineCodes: string;
}
export const FlightSearchPage: React.FC<flightSearchPageProps> = ({
  className
}) => {
  const { addToast } = useToasts();
  const [formData, setformData] = useState<fsearchformDataProps>({
    tripType: "oneway",
    origin: "",
    destination: "",
    singleDate: new Date(),
    rangeDate: [
      new Date(),
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 2
      )
    ],
    execludedAirlines: "_",
    includedAirlines: "_",
    nonStop: "true",
    adults: 1,
    children: 0,
    infants: 0,
    travelClass: "Y",
    currencyCode: "USD",
    includedAirlineCodes:"_"
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const SubmitFlightForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const tripType = formData.tripType;
    const origin =
      formData.origin && formData.origin.value ? formData.origin.value : "";
    const destination =
      formData.origin && formData.destination.value
        ? formData.destination.value
        : "";
    const depatureDate =
      (formData.tripType === "oneway" && formData.singleDate) ||
      (formData.tripType === "oneway" && formData.rangeDate)
        ? format(formData.singleDate, "yyyy-MM-dd")
        : format(formData.rangeDate[0], "yyyy-MM-dd");
    const returnDate =
      formData.tripType === "roundtrip" && formData.rangeDate
        ? format(formData.rangeDate[1], "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd");
    const execludedAirlines = formData.execludedAirlines;
    const includedAirlines = formData.includedAirlines;
    const includedAirlineCodes = formData.includedAirlineCodes;

    
    const nonStop = formData.nonStop;
    const adults = formData.adults;
    const children = formData.children;
    const infants = formData.infants;
    const travelClass = formData.travelClass;
    const currencyCode = formData.currencyCode;
    dispatch(
      setFSearchFormData({
        tripType: formData.tripType,
        origin: formData.origin,
        destination: formData.destination,
        singleDate: format(formData.singleDate, "yyyy-MM-dd"),
        rangeDate: [
          format(formData.rangeDate[0], "yyyy-MM-dd"),
          format(formData.rangeDate[1], "yyyy-MM-dd")
        ],
        execludedAirlines: formData.execludedAirlines,
        includedAirlines: formData.includedAirlines,
        includedAirlineCodes: formData.includedAirlineCodes,
        nonStop: formData.nonStop,
        adults: formData.adults,
        children: formData.children,
        infants: formData.infants,
        travelClass: formData.travelClass,
        currencyCode: formData.currencyCode
      })
    );
    if (origin && destination && origin !== destination) {
      if (tripType === "oneway") {
        const params = `/dashboard/flight/onewaySearch/${tripType}/${origin}/${destination}/${depatureDate}/${execludedAirlines}/${includedAirlineCodes}/${nonStop}/${adults}/${children}/${infants}/${travelClass}/${currencyCode}`;
        history.push(params);
      } else {
        const params = `/dashboard/flight/roundTripSearch/${tripType}/${origin}/${destination}/${depatureDate}/${returnDate}/${execludedAirlines}/${includedAirlineCodes}/${nonStop}/${adults}/${children}/${infants}/${travelClass}/${currencyCode}`;
        history.push(params);
      }
    } else {
      // console.log(formData);
      addToast(`Please fill input completely`, {
        appearance: "warning",
        autoDismiss: true
      });
    }
  };

  return (
    <div className={`${className}`}>
      <form
        onSubmit={(e) => {
          SubmitFlightForm(e);
        }}>
        <FSearchForm
          formData={formData}
          setFormData={setformData}
          className="max-w-screen-lg flex flex-col space-y-3  bg-white mx-auto"
        />
      </form>
    </div>
  );
};
