import React from "react";
import "./index.css";
export interface radioDataProps {
    value: any,
    text: string
}
interface bRadioProps {
    className?: string;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    inline?: boolean;
    name: string;
    id: string;
    radios?: radioDataProps[]
    formData: any
}

export const BRadio: React.FC<bRadioProps> = ({
    className,
    setValue,
    inline = false,
    name,
    id,
    radios,
    formData
}) => {
    return (
        <div className={`${className} ${inline ? 'flex flex-row space-x-4' : 'flex flex-col space-y-2'}`}>
            {
                radios?.map((r: radioDataProps, i: number) => (
                    <div key={i} className={`flex justify-center space-x-4 items-center content-center`}>
                        <label >
                        <input
                           className="form-radio"
                            onChange={e => {
                                setValue({ ...formData, [e.target.name]: e.target.value });
                            }}
                            type="radio"
                            value={r.value}
                            name={name}
                            id={id}
                            checked={r.value===formData[name]}
                        />
                        <span className="text-secondary text-sm md:text-base tracking-widest ml-1 "> {r.text}</span>
                        </label>
                        
                    </div>
                ))
            }

        </div>
    );
};