import React from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { b2bConfig } from "../../../config";
import { busBookingProps } from "../../../pages/dashbaord/BusService/Booking";
import { Instance } from "../../../services";

interface seatPlanProps {
  className?: string;
  formData: busBookingProps;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}

export const ConfirmationForm: React.FC<seatPlanProps> = ({
  className,
  formData,
  setFormData
}) => {
  const { addToast } = useToasts();
  const { push } = useHistory();
  const onPreviousFn = () => {
    setFormData({
      ...formData,
      step: "2"
    });
  };

  const onSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    const rawObj: any = {
      pax_name: formData.pax_name,
      pax_contactno: formData.pax_contactno,
      pax_email: formData.pax_email,
      pax_gender: formData.pax_gender,
      pax_ID: formData.pax_ID,
      pax_remark: formData.pax_remark,
      pax_no: formData.pax_no,
      operatorcode: formData.operatorcode,
      operatorname: formData.operatorname,
      seatPlanUrl: formData.seatPlanUrl,
      tripID: formData.tripID,
      depfrom: formData.depfrom,
      arrvto: formData.arrvto,
      depdate: formData.depdate,
      deptime: formData.deptime,
      nationality: formData.nationality,
      seats: formData.seats.length > 0 ? formData.seats : ["TBA"],
      perpaxprice: formData.perpaxprice
    };
    Instance({
      url: `${b2bConfig.apiPath.busServices}/bookbusticket`,
      method: "POST",
      data: rawObj
    })
      .then(res => {
        if (res.data && res.data.Status && res.data.Status.Code === "01") {
          addToast(res.data.Status.Message, {
            appearance: "success",
            autoDismiss: true
          });
          sessionStorage.removeItem("busData");
          push("/dashboard/bus/bus_bookings");
        }
      })
      .catch(err => {
        addToast("Something wrong", { appearance: "error", autoDismiss: true });
      });
  };
  return (
    <form
      onSubmit={e => onSubmitForm(e)}
      className={`${className} w-full py-8 text-gray-700 tracking-widest text-sm`}
    >
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-3">
          <span> Name</span>
        </div>
        <div className="col-span-9">
          <span>
            {formData.pax_name}
          </span>
        </div>
        <div className="col-span-3">
          <span> Contact</span>
        </div>
        <div className="col-span-9">
          <span>
            {formData.pax_contactno}
          </span>
        </div>
        <div className="col-span-3">
          <span> Email</span>
        </div>
        <div className="col-span-9">
          <span>
            {formData.pax_email}
          </span>
        </div>
        <div className="col-span-3">
          <span> Gender</span>
        </div>
        <div className="col-span-9">
          <span>
            {formData.pax_gender}
          </span>
        </div>
        <div className="col-span-3">
          <span> Passenger ID</span>
        </div>
        <div className="col-span-9">
          <span>
            {formData.pax_ID}
          </span>
        </div>
        <div className="col-span-3">
          <span> Remark</span>
        </div>
        <div className="col-span-9">
          <span>
            {formData.pax_remark}
          </span>
        </div>
        <div className="col-span-3">
          <span> Nationality</span>
        </div>
        <div className="col-span-9">
          <span>
            {formData.nationality}
          </span>
        </div>
      </div>
      <div className="flex w-full justify-between items-center content-center my-6">
        <button
          type="button"
          onClick={() => {
            onPreviousFn();
          }}
          className="flex px-4 py-2 border  justify-center items-center content-center space-x-2 border-gray-400 bg-gray-200 text-gray-600 hover:text-white hover:bg-primary"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-compact-left"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
            />
          </svg>
          <span className="text-sm tracking-widest">Previous</span>
        </button>
        <button className="flex px-4 py-2 border  justify-center items-center content-center space-x-2 border-gray-400 bg-gray-200 text-gray-600 hover:text-white hover:bg-primary">
          <span className="text-sm tracking-widest">Book</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-compact-right"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
            />
          </svg>
        </button>
      </div>
    </form>
  );
};
