import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import {
  DashbaordInnerWrapper,
  tabMenuProps
} from "../../../templates/DashboardInnerWrapper";
import { BalancePage } from "./Balance";
import { ExchangePage } from "./Exchange";
import { ProfilePage } from "./Profile";
import { TransactionPage } from "./Transactions";
export const BusinessEntityPage: React.FC = () => {
  const { path } = useRouteMatch();
  const tabMenu: tabMenuProps[] = [
    {
      name: "Profile",
      path: `${path}`
    },
    {
      name: "Balance",
      path: `${path}/bz_balance`
    },
    {
      name: "Exchange",
      path: `${path}/exchange`
    },
    {
      name: "Transactions",
      path: `${path}/bz_transactions`
    }
  ];
  return (
    <DashbaordInnerWrapper tabMenus={tabMenu}>
      <Switch>
        <Route path={path} exact component={ProfilePage} />
        <Route path={`${path}/bz_balance`} exact component={BalancePage} />
        <Route path={`${path}/exchange`} exact component={ExchangePage} />
        <Route
          path={`${path}/bz_transactions`}
          exact
          component={TransactionPage}
        />
      </Switch>
    </DashbaordInnerWrapper>
  );
};
