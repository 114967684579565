import React from "react";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import "./index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

export const BDataGrid: React.FC<AgGridReactProps> = (
  {
    className,
    pagination,
    columnDefs,
    rowData,
    onRowDoubleClicked,
    onCellClicked
  },
  props
) => {
  const divProps = Object.assign({}, props);
  delete divProps.layout;

  return (
    <div
      {...divProps}
      className={`${className} ag-theme-alpine font-primary`}
      style={{ fontFamily: "Poppins" }}
    >
      <AgGridReact
        pagination={pagination}
        columnDefs={columnDefs}
        rowData={rowData}
        paginationAutoPageSize={false}
        paginationPageSize={10}
        onRowDoubleClicked={onRowDoubleClicked}
        onCellClicked={onCellClicked}
      />
    </div>
  );
};
