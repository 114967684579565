import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { b2bConfig } from "../../../../config";
import { bzBalanceDataProps } from "../../../../interfaces";
import { Instance } from "../../../../services";
import { RootState } from "../../../../store";
import { format } from "date-fns";
import "./index.css";
import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../../components/common/SelectTable";

interface bzBalancePageProps {
  className?: string;
}

export const BalancePage: React.FC<bzBalancePageProps> = ({ className }) => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const [bzBalanceData, setBzBalanceData] = useState<bzBalanceDataProps>();
  const GetBusinessBalance = () => {
    Instance({
      url: `${b2bConfig.apiPath.bzEntry}/user/getbalance/`,
      method: "GET"
    })
      .then(res => {
        if (res.status === 200) {
          if (
            res.data &&
            res.data.Data &&
            res.data.Status &&
            res.data.Status.Code === "01"
          ) {
            const resp: bzBalanceDataProps = res.data.Data;
            setBzBalanceData(resp);
          } else {
            console.log(["fetch user_balance_error_response"]);
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(
    () => {
      if (isAuth) {
        GetBusinessBalance();
      }
    },
    [isAuth]
  );
  return (
    <div className={`${className} w-full max-w-screen-md`}>
      {bzBalanceData &&
        <div className="w-full">
          <SelectTable className="bg-white px-2 py-2 rounded-md">
            <thead className="text-xs font-normal uppercase border-t border-b text-gray-500 bg-gray-50 border-gray-200">
              <TableRow>
                <TableCell isHeader={true}>
                  <span>Account ID</span>
                </TableCell>
                <TableCell isHeader={true}>
                  <span>Balance</span>
                </TableCell>
                <TableCell isHeader={true}>
                  <span>Currency</span>
                </TableCell>
                <TableCell isHeader={true}>
                  <span>Updated At</span>
                </TableCell>
              </TableRow>
            </thead>
            <tbody className="text-xs font-normal divide-y divide-gray-400">
              {bzBalanceData.businessBalance.map((b, i) =>
                <TableRow key={i}>
                  <TableCell>
                    <span>
                      {b.accountID}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {b.balance}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {b.currency}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {format(new Date(b.updateDate), "yyyy-MM-dd")}
                    </span>
                  </TableCell>
                </TableRow>
              )}
            </tbody>
          </SelectTable>
        </div>}
    </div>
  );
};
