import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import { Insurance } from "./Buy";
import { InsuranceBookings } from "./Bookings";
import { BookingDetail } from "./BookingDetail";

export const InsurancePage: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact component={Insurance} />
      <Route path={`${path}/bookings`} exact component={InsuranceBookings} />
      <Route path={`${path}/booking-detail/:id`} exact component={BookingDetail} />
      <Redirect to="/404" />
    </Switch>
  );
};
