import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../index.css";
interface rangePickerProps {
  className?: string;
  range: Date[];
  setRange: React.Dispatch<React.SetStateAction<Date | any>>;
  title?: string;
  name: string;
  id: string;
  maxDate?: Date;
  minDate?: Date;
  formData: any;
}

export const BRangePicker: React.FC<rangePickerProps> = ({
  className,
  range,
  setRange,
  title,
  formData,
  name,
  id,
  maxDate,
  minDate
}) => {
  return (
    <div className={`${className} w-full`}>
      <label className="text-sm text-secondary" htmlFor="">
        {title}
      </label>
      <DatePicker
        maxDate={maxDate}
        name={name}
        id={id}
        dateFormat="yyyy/MM/d"
        className="form-input border border-gray-300 px-2 text-xs tracking-widest"
        placeholderText="select Date"
        selectsRange={true}
        startDate={range[0]}
        endDate={range[1]}
        onChange={update => {
          setRange({ ...formData, [name]: update });
        }}
      />
    </div>
  );
};
