import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import format from "date-fns/format";
import "./index.css";
import { RootState } from "../../../../store";
import { Instance } from "../../../../services";
import { b2bConfig } from "../../../../config";

import { useHistory } from "react-router-dom";
import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../../components/common/SelectTable";
// import { DotsHorizontalIcon } from "@heroicons/react/solid";

interface onlineFlightBookingsPageProps {
  className?: string;
}

interface travlerProps {
  title?: string;
  firstName?: string;
  lastName?: string;
}

export interface tempReponse {
  bookingCreateDate: string;
  ticketStatus: string;
  bookingStatus: string;
  bookingID: string;
  fareSource: string;
  pnr: string;
  fareSourceCode: string;
  id: number;
  searchID: string;
  businessID: string;
  travler: travlerProps[];
}

export const FlightBookings: React.FC<onlineFlightBookingsPageProps> = ({
  className
}) => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const [reponseData, setReponseData] = useState<tempReponse[]>([]);
  const history = useHistory();
  const fetchFlightBookings = useCallback(() => {
    if (isAuth) {
      Instance({
        url: `${b2bConfig.apiPath.filghtServices}/booking-list`,
        method: "GET"
      })
        .then((res) => {
          if (res.data && res.data.data) {
            const tempData: tempReponse[] = res.data.data;
            setReponseData(tempData);
          }
        })
        .catch((err) => {
          console.log(err, "transaction_fetch_error");
        });
    }
  }, [isAuth]);

  const handleClick = (id: any) => {
    history.push(`/dashboard/flight/booking_detail/${id}`);
  };

  useEffect(() => {
    fetchFlightBookings();
  }, [fetchFlightBookings]);

  return (
    <>
      <h4 className="mb-3">Flight Bookings</h4>
      <div className="bg-white rounded-md shadow-sm p-2">
        <SelectTable className="text-gray-800 md:table-fixed">
          <thead className="text-xs font-normal uppercase border-b text-gray-500 bg-gray-50 border-gray-200">
            <TableRow>
              <TableCell isHeader={true}>
                <span> Booking ID</span>
              </TableCell>
              <TableCell isHeader={true}>
                <span>PNR</span>
              </TableCell>
              <TableCell isHeader={true}>
                <span>Booking Status</span>
              </TableCell>
              <TableCell isHeader={true}>
                <span>Ticket Status</span>
              </TableCell>

              <TableCell isHeader={true}>
                <span>Passengers</span>
              </TableCell>

              <TableCell isHeader={true}>
                <span>Business ID</span>
              </TableCell>
              <TableCell isHeader={true}>
                <span> Booking Date</span>
              </TableCell>
              {/* <TableCell isHeader={true}>
              <span className="sr-only">Action</span>
              <span>
                <DotsHorizontalIcon className="w-6 h-6 text-gray-800" />
              </span>
              </TableCell> */}
            </TableRow>
          </thead>
          <tbody className="text-xs font-normal divide-y divide-gray-100">
            {reponseData.map((t, i) => (
              <tr
                className="cursor-pointer hover:bg-gray-100"
                key={i}
                onClick={() => handleClick(t.bookingID)}>
                <TableCell>
                  <span>{t.bookingID}</span>
                </TableCell>
                <TableCell>
                  <span>{t.pnr}</span>
                </TableCell>
                <TableCell>
                  <span className={``}>{t.bookingStatus}</span>
                </TableCell>
                <TableCell>
                  <span className={``}>{t.ticketStatus}</span>
                </TableCell>

                <TableCell>
                    {t.travler && t.travler.map((e: any, i: any) => (
                      <span key={i} className="block">
                        {e.title}  {e.firstName} {e.lastName}
                      </span>
                    ))}                
                </TableCell>

                <TableCell>
                  <span>{t.businessID}</span>
                </TableCell>

                <TableCell>
                  <span>
                    {format(new Date(t.bookingCreateDate), "yyyy-MM-dd")}
                  </span>
                </TableCell>
                {/* <TableCell>
                <button
                  onClick={() => {
                    push(`/dashboard/flight/booking_detail/${t.bookingID}`);
                  }}
                  className="border tracking-widest px-2 py-1 border-gray-400 hover:border-gray-800 text-gray-400 hover:text-gray-800">
                  Booking Detail
                </button>
              </TableCell> */}
              </tr>
            ))}
          </tbody>
        </SelectTable>
      </div>
    </>
  );
};
