import React, { useState } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { Instance } from "../../../services";
import { b2bConfig } from "../../../config";
import { useToasts } from "react-toast-notifications";

interface defaultProps {
  icon: any;
  newPassword: string;
  confirmPassword: string;
  error: boolean;
}

const defaultValue = {
  icon: {
    p1: false,
    p2: false
  },
  newPassword: "",
  confirmPassword: "",
  error: false
};

export const ChangePassword: React.FC<defaultProps> = () => {
  const [formData, setFormData] = useState<defaultProps>(defaultValue);
  const { addToast } = useToasts();

  const handleEye = (type: any) => {
    const newValue = { ...formData.icon };
    newValue[type] = !formData.icon[type];
    setFormData({ ...formData, icon: newValue });
  };

  const reset = () => {
    setFormData(defaultValue);
  };

  const onsubmit = (e: any) => {
    e.preventDefault();
    const newData = { ...formData };
    const error = newData.newPassword !== newData.confirmPassword;

    setFormData({
      ...formData,
      error: error
    });

    if (!error) {
      document.body.classList.add("loading-indicator");
      const accessToken = sessionStorage.getItem("accessToken");

      Instance({
        url: `${b2bConfig.apiPath.bzEntry}/user/passwordreset/`,
        method: "POST",
        headers: {
          Authorization: `JWT ${accessToken}`
        },
        data: {
          newpassword: formData.newPassword,
          confirmnewpassword: formData.confirmPassword
        }
      })
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              addToast(`New password is successfully update.`, {
                appearance: "success",
                autoDismiss: true
              });
              reset();
            }, 300);
          }
        })
        .catch((err) => {
          console.log(err, ["transaction_fetch_error"]);
        });
    }
  };

  return (
    <div className="mx-auto max-w-2xl w-full">
      <h4>Change password</h4>
      <div className="mt-4 bg-white rounded-md shadow-sm p-16">
        <form onSubmit={(e) => onsubmit(e)} className="flex flex-col space-y-6">
          <div className="w-full relative text-gray-400 focus-within:text-gray-600 block">
            <label className="text-secondary required">New password</label>
            <div className="relative">
              <input
                className={`form-input border rounded-md text-secondary w-full px-3 text-sm tracking-widest mt-3 ${
                  formData.error ? "border-red-800" : ""
                }`}
                required
                autoFocus
                minLength={6}
                type={formData.icon.p1 ? "text" : "password"}
                name="newPassword"
                id="new_password"
                placeholder="New password"
                value={formData.newPassword}
                onChange={(e) => {
                  setFormData({ ...formData, [e.target.name]: e.target.value });
                }}
              />
              <div className=" absolute right-2 top-6">
                {formData.icon.p1 ? (
                  <EyeOffIcon
                    className="w-4 h-4 cursor-pointer"
                    color="text-gray-300"
                    onClick={() => handleEye("p1")}
                  />
                ) : (
                  <EyeIcon
                    className="w-4 h-4 cursor-pointer"
                    color="text-gray-300"
                    onClick={() => handleEye("p1")}
                  />
                )}
              </div>
            </div>

            <span className="mb-2 text-xs">
              Password must have at aleast 6 characters.
            </span>
          </div>

          <div className="w-full relative text-gray-400 focus-within:text-gray-600 block">
            <label className="text-secondary required">
              Confirm new password
            </label>
            <div className="relative">
              <input
                className={`form-input border rounded-md text-secondary w-full px-3 text-sm tracking-widest mt-3 ${
                  formData.error ? "border-red-800" : ""
                }`}
                required
                minLength={6}
                type={formData.icon.p2 ? "text" : "password"}
                name="confirmPassword"
                id="confirm_password"
                placeholder="Confirm new password"
                value={formData.confirmPassword}
                onChange={(e) => {
                  setFormData({ ...formData, [e.target.name]: e.target.value });
                }}
              />
              <div className=" absolute right-2 top-6">
                {formData.icon.p2 ? (
                  <EyeOffIcon
                    className="w-4 h-4 cursor-pointer"
                    color="text-gray-300"
                    onClick={() => handleEye("p2")}
                  />
                ) : (
                  <EyeIcon
                    className="w-4 h-4 cursor-pointer"
                    color="text-gray-300"
                    onClick={() => handleEye("p2")}
                  />
                )}
              </div>

              {formData.error && (
                <span className="mb-2 text-xs text-red-800">
                  Password does not match.
                </span>
              )}
            </div>
          </div>

          <div className="w-full text-gray-400 focus-within:text-gray-600">
            <button
              type="submit"
              className="border px-4 py-2 flex w-full rounded-md justify-between items-center content-center border-yellow-400 bg-yellow-400 hover:bg-yellow-600 text-white">
              <span className="w-full text-center">Update Password</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
