import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import "./index.css";

interface bzProfilePageProps {
  className?: string;
}

export const ProfilePage: React.FC<bzProfilePageProps> = ({ className }) => {
  const userData = useSelector((state: RootState) => state.auth);

  return (
    <div className={`${className} w-full tracking-widest`}>
      <div className="grid grid-flow-row grid-cols-12 gap-3">
        <div className="col-span-12 md:col-span-3">
          <span className="text-sm font-normal text-secondary">
          Business Name
          </span>
        </div>
        <div className="col-span-12 md:col-span-9">
          <span className="text-sm font-normal text-primary">{userData.bzNm}</span>
        </div>
        <div className="col-span-12 md:col-span-3">
          <span className="text-sm font-normal text-secondary">
           User Name
          </span>
        </div>
        <div className="col-span-12 md:col-span-9">
          <span className="text-sm font-normal text-primary">{userData.usrNm} </span>
        </div>
        <div className="col-span-12 md:col-span-3">
          <span className="text-sm font-normal text-secondary">
           Business Email
          </span>
        </div>
        <div className="col-span-12 md:col-span-9">
          <span className="text-sm font-normal text-primary">{userData.bzEmail}</span>
        </div>
        <div className="col-span-12 md:col-span-3">
          <span className="text-sm font-normal text-secondary">
           Business Phone
          </span>
        </div>
        <div className="col-span-12 md:col-span-9">
          <span className="text-sm font-normal text-primary">{userData.bzPhone}</span>
        </div>
        <div className="col-span-12 md:col-span-3">
          <span className="text-sm font-normal text-secondary">
           Business Address
          </span>
        </div>
        <div className="col-span-12 md:col-span-9">
          <span className="text-sm font-normal text-primary">{userData.bzAddress}</span>
        </div>
        <div className="col-span-12 md:col-span-3">
          <span className="text-sm font-normal text-secondary">
            User Role
          </span>
        </div>
        <div className="col-span-12 md:col-span-9">
          <span className="text-sm font-normal text-primary">{userData.usrRole}</span>
        </div>
        <div className="col-span-12 md:col-span-3">
          <span className="text-sm font-normal text-secondary">
           Business City
          </span>
        </div>
        <div className="col-span-12 md:col-span-9">
          <span className="text-sm font-normal text-primary">{userData.bzCity}</span>
        </div>
        <div className="col-span-12 md:col-span-3">
          <span className="text-sm font-normal text-secondary">
           Business Country
          </span>
        </div>
        <div className="col-span-12 md:col-span-9">
          <span className="text-sm font-normal text-primary">{userData.bzCountry}</span>
        </div>
        <div className="col-span-12 md:col-span-3">
          <span className="text-sm font-normal text-secondary">
           Avaliable Modules
          </span>
        </div>
        <div className="col-span-12 md:col-span-9">
         <div className='flex flex-row space-x-2'>
              {
                userData.modules?.map((m,i)=>(
                  <span className='text-white bg-gray-500 px-2 py-1  text-xs lowercase' key={i}>{m.moduleName}</span>
                ))
              }
         </div>
        </div>
        
       
      </div>
    </div>
  );
};
