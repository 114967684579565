import { round } from 'lodash'
import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { Bbutton } from '../../../../components/common/Bbutton'
import { BInput } from '../../../../components/common/BInput'
import { BPopup } from '../../../../components/common/Bpopup'
import { Operator } from '../../../../components/dgservices/Operators'
import { b2bConfig } from '../../../../config'
import { checkMobile, resultProps } from '../../../../helper/CheckMobile'
import { Instance } from '../../../../services'

import './index.css'

interface dataPacksPageProps {
  className?: string
}

interface dataPackFormProps {
  mobileNumber: string
}

interface dataPacksProps {
  packageCode?: string
  packageName?: string
  price?: number
  Package?: string
  Price?: number
  OfferName?: string
}

interface slectedPackageProps {
  price: string
  dataCode: string
  packageName: string
  packageCode: string
  mobileNum: string
}

export const DataPackPage: React.FC<dataPacksPageProps> = ({ className }) => {
  const [formData, setFormData] = useState<dataPackFormProps>({
    mobileNumber: process.env.NODE_ENV === 'development' ? '09421022637' : ''
  })
  const [packages, setpackages] = useState<dataPacksProps[]>([])
  const billerCode: resultProps = checkMobile(formData.mobileNumber)
  const [selectedPackage, setSelectedPackage] = useState<slectedPackageProps>({
    price: '',
    dataCode: '',
    packageName: '',
    packageCode: '',
    mobileNum: ''
  })
  const [show, setShow] = useState<boolean>(false)
  const { addToast } = useToasts()
  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      const data = new FormData()
      data.append('billerCode', billerCode.dataCode)
      Instance({
        url: `${b2bConfig.apiPath.dgServices}/mobile/datapackageenquiry`,
        method: 'POST',
        data: data
      })
        .then(res => {
          if (
            res.data &&
            res.data.Data &&
            res.data.Status &&
            res.data.Status.Code === '01' &&
            res.data.Data.packageList
          ) {
            const response: dataPacksProps[] = res.data.Data.packageList
            setpackages(response)
          }
        })
        .catch(err => {
          addToast(JSON.stringify(err), {
            appearance: 'warning',
            autoDismiss: true
          })
        })
    } catch (error) {
      addToast(JSON.stringify(error), {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }
  const choosePackage = (result: dataPacksProps) => {
    let price: string = ''
    let packageCode: string = ''
    if (result.price) {
      price = round(result.price).toFixed(1)
    } else if (result.Price) {
      price = round(result.Price).toFixed(1)
    } else {
      price = ''
    }

    if (result.OfferName) {
      packageCode = result.OfferName
    } else if (result.packageCode) {
      packageCode = result.packageCode
    } else if (result.Package) {
      packageCode = result.Package
    }

    setSelectedPackage({
      ...selectedPackage,
      dataCode: billerCode.dataCode,
      mobileNum: formData.mobileNumber,
      packageCode: packageCode,
      price: price,
      packageName: result.packageName ? result.packageName : ''
    })

    setShow(!show)
  }
  const ConfirmDataPack = (e: React.FormEvent<HTMLFormElement>) => {
    console.log(selectedPackage)
    e.preventDefault()
    const formData = new FormData()
    formData.append('billerCode', selectedPackage.dataCode)
    formData.append('mobileno', selectedPackage.mobileNum)
    formData.append('packageCode', selectedPackage.packageCode)
    formData.append('price', selectedPackage.price)
    try {
      Instance({
        url: `${b2bConfig.apiPath.dgServices}/mobile/datapackageconfirm`,
        method: 'POST',
        data: formData
      })
        .then(res => {
          if (res.data && res.data.Status && res.data.Status.Code === '01') {
            addToast('successful', { appearance: 'success', autoDismiss: true })
            setShow(!show)
          } else if (
            res.data &&
            res.data.Status &&
            res.data.Status.Code === '400'
          ) {
            addToast(JSON.stringify(res.data.Error), {
              appearance: 'warning',
              autoDismiss: true
            })
            setShow(!show)
          } else if (res.data.Status.Code && res.data.Status.Message) {
            addToast(JSON.stringify(res.data.Status.Message), {
              appearance: 'warning',
              autoDismiss: true
            })
            setShow(!show)
          }
        })
        .catch(err => {
          addToast(JSON.stringify(err), {
            appearance: 'error',
            autoDismiss: true
          })
          setShow(!show)
        })
    } catch (error) {
      addToast(JSON.stringify(error), {
        appearance: 'error',
        autoDismiss: true
      })
      setShow(!show)
    }
  }
  return (
    <div className={`${className} w-full block`}>
      <form onSubmit={e => submitForm(e)}>
        <div className='w-full max-w-screen-lg shadow-md bg-white px-2 py-2'>
          <div className='grid grid-cols-12 gap-3'>
            <div className='col-span-12 md:col-span-5'>
              <BInput
                title='Mobile Number'
                formData={formData}
                setFormData={setFormData}
                name='mobileNumber'
                id='mobileNumber'
                type='text'
              />
            </div>
            <div className='col-span-12 md:col-span-5'>
              <Operator operator={billerCode} className='my-5' />
            </div>
            <div className='col-span-12 md:col-span-2 my-4'>
              <Bbutton type='submit' title='Search' />
            </div>
          </div>
        </div>
      </form>
      <div className='grid grid-cols-12 gap-8 my-8'>
       
        {billerCode.dataCode !== 'unknown' &&
          packages.map((p: dataPacksProps, i: number) => (
            <div
              className='col-span-12 md:col-span-4'
              key={i}
              onClick={() => choosePackage(p)}
            >
              <div className='bg-white grid grid-cols-12 rounded-r-md'>
                <div className='bg-indigo-300 text-white col-span-2 rounded-l-md flex justify-center items-center content-center '>
                {billerCode.dataCode !== 'unknown' && (
            <h4 className='text-sm text-white tracking-widest my-4'>
              {' '}
              {billerCode.dataCode === 'MPTDataPack'
                ? 'MPT'
                : billerCode.dataCode === 'MyTelDataPack'
                ? 'Mytel'
                : billerCode.dataCode === 'OoredooDataPack'
                ? 'Ooredoo'
                : billerCode.dataCode === 'TelenorDataPack'
                ? 'Telenor'
                : ''}{' '}
            </h4>
          )}
                </div>
                <div className='col-span-10 '>
                <div className='flex flex-col justify-start items-center content-center py-2'>
                <h4 className='text-sm tracking-widest text-primary my-2'>
                {billerCode.dataCode === 'TelenorDataPack'
                  ? p.Package
                  : p.packageName}
              </h4>
              <p className='text-xs  text-danger tracking-widest'>
                {billerCode.dataCode === 'TelenorDataPack' ? p.Price : p.price}{' '}
                MMK
              </p>
                </div>
                </div>
              </div>

            </div>
          ))}
      </div>

     
      <BPopup
        show={show}
        setShow={setShow}
        title='Data Pack Confirmation'
        size='medium'
      >
        <form className='px-2 py-2' onSubmit={e => ConfirmDataPack(e)}>
          <div className='grid grid-cols-12 gap-8 '>
            <div className='col-span-6'>
              <span className='text-sm text-secondary'>Mobile Number</span>
            </div>
            <div className='col-span-6'>
              <span className='text-sm text-primary'>
                {selectedPackage?.mobileNum}
              </span>
            </div>
            <div className='col-span-6'>
              <span className='text-sm text-secondary'>Package Name</span>
            </div>
            <div className='col-span-6'>
              <span className='text-sm text-primary'>
                {' '}
                {selectedPackage?.packageName}
              </span>
            </div>

            <div className='col-span-6'>
              <span className='text-sm text-secondary'>Package Code</span>
            </div>
            <div className='col-span-6'>
              <span className='text-sm text-primary'>
                {' '}
                {selectedPackage?.dataCode}
              </span>
            </div>
            <div className='col-span-6'>
              <span className='text-sm text-secondary'>Price</span>
            </div>
            <div className='col-span-6'>
              <span className='text-sm text-primary'>
                {' '}
                {selectedPackage?.price} MMK
              </span>
            </div>
            <div className='col-span-12'>
              <div className='flex'>
                <Bbutton type='submit' title='Confirm' color='success' />
              </div>
            </div>
          </div>
        </form>
      </BPopup>
    </div>
  )
}


