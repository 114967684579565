import { filter, find } from 'lodash'
import React from 'react'
import { useToasts } from 'react-toast-notifications'
import {
  busBookingProps,
  seatPlanProps
} from '../../../pages/dashbaord/BusService/Booking'

interface seatPlanFormProps {
  className?: string
  seats?: seatPlanProps[][]
  formData: busBookingProps
  setFormData: React.Dispatch<React.SetStateAction<busBookingProps>>
}

export const SeatPlan: React.FC<seatPlanFormProps> = ({
  className,
  seats,
  formData,
  setFormData
}) => {
  const { addToast } = useToasts()
  const ClickHandler = (seat: string, isAvailable: boolean) => {
    if (isAvailable) {
      const uniqueArray = find(formData.seats, s => s === seat)
      if (!uniqueArray) {
        setFormData({
          ...formData,
          seats: [...formData.seats, seat]
        })
      } else {

        /// to check no of seat

        if(formData.seats.length < formData.pax_no){
          setFormData({
            ...formData,
            seats: filter(formData.seats, s => s !== seat)
          })
        }else{
          return
        }
      }
    } else {
      return
    }
  }

  const NextFunction = () => {
    if (formData.seats.length > 0) {
      setFormData({
        ...formData,
        step: '2'
      })
    } else {
      setFormData({
        ...formData,
        step: '2'
      })
      addToast('TBA selected')
    }
  }
  
  return (
    <div className={`${className} w-full max-w-screen-md`}>
      <h4 className='flex text-lg tracking-widest text-warning  justify-start items-center content-center space-x-2 py-4 px-2 border-b border-gray-200 my-4'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='currentColor'
          className='bi bi-columns'
          viewBox='0 0 16 16'
        >
          <path d='M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V2zm8.5 0v8H15V2H8.5zm0 9v3H15v-3H8.5zm-1-9H1v3h6.5V2zM1 14h6.5V6H1v8z' />
        </svg>
        <span>Choose your seat....</span>
      </h4>
      <div className='grid grid-cols-2 gap-4 md:gap-6'>
        {seats?.map((seat: seatPlanProps[], i: number) => (
          <div className='grid grid-cols-2 gap-4 md:gap-6' key={i}>
            {seat.map(
              (s: seatPlanProps, i) =>
                s.isSeat && (
                  <button
                    onClick={() => {
                      ClickHandler(s.seatId, s.isAvailable)
                    }}
                    key={i}
                    className={`border flex justify-center space-x-2 items-center content-center text-xs tracking-widest border-gray-300 rounded-md px-2 py-2 ${
                      s.isAvailable
                        ? 'bg-gray-100 hover:bg-green-100'
                        : 'bg-red-100'
                    }`}
                  >
                    {find(
                      formData.seats,
                      selectedSeat => selectedSeat === s.seatId
                    ) && (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-bookmark-check-fill text-success'
                        viewBox='0 0 16 16'
                      >
                        <path
                          fillRule='evenodd'
                          d='M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z'
                        />
                      </svg>
                    )}

                    <span>{s.number}</span>
                  </button>
                )
            )}
          </div>
        ))}
      </div>
      <div className='flex justify-end w-full items-center content-center my-6'>
        <button
          onClick={() => {
            NextFunction()
          }}
          type='button'
          className='px-4 text-sm tracking-widest py-2 space-x-2 border border-gray-300 bg-gray-200 hover:bg-primary text-gray-800 hover:text-white hover:border-primary flex justify-center items-center content-center'
        >
          <span>Next</span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            className='bi bi-arrow-right'
            viewBox='0 0 16 16'
          >
            <path
              fillRule='evenodd'
              d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'
            />
          </svg>
        </button>
      </div>
    </div>
  )
}
