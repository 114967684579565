import React from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { removeCredentials } from "../../../store/Reducers/auth";
import { RootState } from "../../../store";
import { LogoutIcon } from "@heroicons/react/outline";
import { KeyIcon } from "@heroicons/react/solid";
interface dashboardProfileProps {
  className?: string;
  user?: any;
}
export const DashboardProfile: React.FC<dashboardProfileProps> = ({ user }) => {
  const { usrNm, usrRole } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const LogoutFn = () => {
    sessionStorage.clear();
    dispatch(removeCredentials());
    history.push("/");
  };
  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-person-fill rounded-full text-gray-700"
              viewBox="0 0 16 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
            <div className="flex items-center truncate group">
              <span className="mx-2 text-sm font-medium text-gray-900 truncate group-hover:gray-blue-600">
                {usrNm}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-compact-down"
                viewBox="0 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z"
                />
              </svg>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                <div className="px-2 py-2">
                  <p className="text-lg text-gray-900">{usrNm}</p>
                  <span className="text-xs font-light text-gray-600">
                    {usrRole}
                  </span>
                </div>
              </Menu.Item>
            </div>
            <div className="">
              <Menu.Item>
                <Link
                  to="/dashboard/user/change-password"
                  className={
                    "text-gray-900 group rounded-md w-full text-sm bg-white hover:text-gray-200"
                  }>
                  <span className="flex items-center py-3 hover:bg-gray-900 rounded-md mx-1 px-2">
                    <KeyIcon className="w-4 h-4 mr-2" />
                    <span className="text-sm font-normal">Change Password</span>
                  </span>
                </Link>
              </Menu.Item>
            </div>
            
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => LogoutFn()}
                    className={`${
                      active ? "bg-gray-900 text-gray-200" : "text-gray-900"
                    } group  rounded-md w-full px-2 py-2 text-sm`}>
                    <span className="flex items-center ">
                      <LogoutIcon className="w-4 h-4 mr-2" />
                      <span className="text-sm font-normal"> logout</span>
                    </span>
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
