import React, { useState } from "react";
import moment from "moment";
import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../components/common/SelectTable";
import "./index.css";
import AirlineLogo from "../../../assets/airlineLogo.png";
import { Instance } from "../../../services";
import { b2bConfig } from "../../../config";
import { useToasts } from "react-toast-notifications";
import { useHistory, useRouteMatch } from "react-router-dom";

export interface fSearchResultCProps {
  className?: string;
  data?: resultDataProps[];
}

export interface resultDataProps {
  searchID?: string;
  fareSourceCode?: string;
  fareSource?: string;
  instantPurchase?: boolean;
  refundable?: string;
  tripType?: "OneWay" | "RoundTrip";
  passportMandatory: boolean;
  lastTicketingDate?: string;
  avlSeats?: number;
  baggageInfo?: string[];
  itineraries?: itinerariersProps[];
  pricing?: pricingProps;
  pricingDetail?: pricingDetailProps[];
  pricingOptions?: string[];
}

interface pricingDetailProps {
  base?: string;
  tax?: string;
  total?: string;
}

interface itinerariersProps {
  segments?: segments[];
}

interface segments {
  departureAirportCode: string;
  departureAirportName: string;
  departureDateTime: string;
  departureTerminal: string;
  arrivalAirportCode: string;
  arrivalAirportName: string;
  arrivalDateTime: string;
  journeyDuration: string;
  numberOfStops: number;
  carrierCode: string;
  carrierName: string;
  flightNo: string;
  operating_carrierCode: string;
  operating_carrierName: string;
  cabin: string;
  class: string;
  mealCode: string;
  avlSeats: string;
  stopQuantityInfo: stopQuantityInfoProps;
  includedCheckedBags: string;
  weightUnit: string;
}

interface stopQuantityInfoProps{
  ArrivalDateTime:string;
  DepartureDateTime: string;
  Duration: string;
  LocationCode: string;
}
interface pricingProps {
  base: string;
  currency: string;
  fareType: [string];
  grandtotal: string;
  total: string;
}

interface selectProps {
  fsc: string;
  src: string;
}

export const FlightSearchResult: React.FC<fSearchResultCProps> = ({
  className,
  data
}) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [show, setShow] = useState<boolean>(false);
  const [selectId, setSelectId] = useState<selectProps>({
    fsc: "",
    src: ""
  });
  const { params } = useRouteMatch();
  const queryString: any = params;
  const tripType = queryString.tripType;
  const origin = queryString.origin;
  const destination = queryString.destination;
  const depatureDate = queryString.departureDate;
  const returnDate = queryString.returnDate;
  const execludedAirlines = queryString.execludedAirlines
    ? queryString.execludedAirlines
    : "_";
  const includedAirlines = queryString.includeAirlines
    ? queryString.includeAirlines
    : "_";
  const nonStop = queryString.nonStop;
  const adults = queryString.adults;
  const children = queryString.children;
  const infants = queryString.infants;
  const travelClass = queryString.travelClass;
  const currencyCode = queryString.currencyCode;
  const [fareRules, setFareRules] = useState([]);
  const [baggageInfo, setBaggageInfo] = useState([]);

  const execAction = (fsc: any, src: any, searchId: any) => {
    setSelectId({
      fsc: fsc,
      src: src
    });
    setShow(!show);
    !show && getSearchFareRule(fsc, src, searchId);
  };

  const getSearchFareRule = (fsc: any, src: any, searchId: any) => {
    const accessToken = sessionStorage.getItem("accessToken");
    setFareRules([]);
    setBaggageInfo([]);
    
    Instance({
      baseURL: `${b2bConfig.apiPath.filghtServices}/search-farerule`,
      method: "POST",
      headers: {
        Authorization: `JWT ${accessToken}`
      },
      data: {
        searchID: searchId,
        src: src,
        fsc: fsc
      }
    })
      .then((res) => {
        if (res.status && res.data.data) {
          setFareRules(res.data.data.fareRules);
          setBaggageInfo(res.data.data.BaggageInfos);
        }
      })
      .catch((err) => {
        console.log(err, "transaction_fetch_error");
      });
  };

  const BookAction = (
    fsc: any,
    src: any,
    searchId: any,
    instantPurchase: any
  ) => {
    try {
      const formData = new FormData();
      formData.append("fsc", fsc);
      formData.append("src", src);
      formData.append("searchID", searchId);
      Instance({
        url: `${b2bConfig.apiPath.filghtServices}/flight-revalidate`,
        method: "POST",
        data: formData
      })
        .then((res) => {
          if (res.data.success && res.data.data) {
            localStorage.setItem(
              "flight-info",
              JSON.stringify({
                ...res.data.data,
                instantPurchase: instantPurchase
              })
            );


            



            if (tripType === "oneway") {
              history.push(
                `/dashboard/flight/onewayFlightBook/${searchId}/${fsc}/${src}/${tripType}/:${origin}/${destination}/${depatureDate}/${execludedAirlines}/${includedAirlines}/${nonStop}/${adults}/${children}/${infants}/${travelClass}/${currencyCode}`
              );
            } else {
              history.push(
                `/dashboard/flight/roundTripFlightBook/${searchId}/${fsc}/${src}/${tripType}/${origin}/${destination}/${depatureDate}/${returnDate}/${execludedAirlines}/${includedAirlines}/${nonStop}/${adults}/${children}/${infants}/${travelClass}/${currencyCode}`
              );
            }
          } else{

            if (res.data.error) {
                addToast(`Error FSR01 : ${res.data.error}`, {
                  appearance: "error",
                  autoDismiss: false
                });
            }else{
              addToast(`Error FSR02 : Selected Fare is expired, Please choose another flight option.`, {
                appearance: "error",
                autoDismiss: false
              });
            }
            
          }
        })
        .catch((err) => {

          addToast(`Error FSR03 : ${JSON.stringify(err)}`, {
            appearance: "error",
            autoDismiss: false
          });
        });
    } catch (error) {
      addToast(`Error FSR04 : ${JSON.stringify(error)}`, {
        appearance: "error",
        autoDismiss: false
      });
    }
  };
  return (
    <div
      className={`${className} flex w-full justify-start px-2 py-2 items-center content-center flex-col`}>
      {data?.map((d: resultDataProps, i: number) => (
        <div
          key={i}
          className={`w-full grid grid-cols-6  shadow-sm hover:shadow-lg   max-w-screen-lg   px-2 py-2 border bg-white ${
            i === 0 ? "rounded-t-md" : ""
          } ${i === data.length - 1 ? "rounded-b-md" : ""}`}>
          <div className="col-span-5">
            {d.itineraries?.map((iti: itinerariersProps, i) => (

              <div key={i} className="w-full py-3">
                {iti.segments?.map((s: segments, i) => (
                  <div key={i} className="w-full grid grid-cols-6 gap-2 flex flex-row space-x-4">
                    <div className="w-full flex flex-row space-x-4">
                      <div
                        style={{ backgroundImage: `url(${AirlineLogo})` }}
                        className={`airline-${s.operating_carrierCode}  h-16 `}
                        key={i}>                          
                      </div>
                      <div>
                        <span className="text-primary text-xs font-bold block">
                          {s.operating_carrierName}
                        </span>
                        <span className="text-secondary text-xs font-bold block" >
                          {s.carrierCode + "" + s.flightNo + ""}
                        </span>
                      </div>

                    </div>


                    <div className="col-span-1 " >
                      <div className="block justify-center items-center content-center">
                        <span className="border border-danger text-danger rounded-md text-xs tracking-widest px-1">
                            {s.numberOfStops === 0
                              ? "nonstop"
                              : `${s.numberOfStops} stop`}
                          </span>
                          {s.stopQuantityInfo.LocationCode && (                  
                            <span className="text-xs rounded-md px-2 ">
                              Stop Location : {s.stopQuantityInfo.LocationCode} 
                              Stop Duration : {s.stopQuantityInfo.Duration} mins
                            </span>                        
                          )}
                            

                        </div>
                        <div className="block justify-center items-center content-center">
                        {s.class && (                  
                          <span className="text-xs rounded-md px-2 text-warning">
                            {s.class} (class)
                          </span>                        
                        )}
                        {s.mealCode && (                  
                          <span className="text-xs lowercase rounded-md px-2 ">
                            Meal Code : {s.mealCode} 
                          </span>                        
                        )}

                        

                        {s.avlSeats && (                  
                          <span className="text-green-600 text-xs lowercase rounded-md px-2 "><br></br>
                            {s.avlSeats} Seat Avail
                          </span>                        
                        )}

                        </div>
                    </div>
                    
                    <div className="col-span-4">
                      <div className="grid grid-cols-5">
                        <div className="px-3 col-span-2">
                            <span className="tracking-widest text-secondary text-xs font-medium">
                              {s.departureAirportName} ({s.departureAirportCode})
                            </span><br></br>
                            <span className="tracking-widest text-warning text-xs">                            
                              {moment(s.departureDateTime).format("LLL")}{" "}
                            </span>
                        </div>
                        
                        <div className="justify-center items-center content-center px-3" >                          
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-lightning-charge"
                            viewBox="0 0 16 16">
                            <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z" />
                          </svg>
                              {s.journeyDuration && (                  
                                <span className="text-xs rounded-md">
                                  {s.journeyDuration} Min
                                </span>                        
                              )}
                          

                        </div>

                        <div className="px-3 col-span-2">
                          <span className="tracking-widest text-secondary text-xs font-medium">
                            {s.arrivalAirportName} ({s.arrivalAirportCode})
                          </span><br></br>
                          <span className="tracking-widest text-warning text-xs">                                                    
                            {moment(s.arrivalDateTime).format("LLL")}{" "}
                          </span>
                        </div>
                        

                        


                      </div>
                      
                      {i + 1 !== iti.segments?.length && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-three-dots-vertical text-secondary"
                          viewBox="0 0 16 16">
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                      )}
                    </div>


                  </div>
                ))}
              </div>
            ))}


          </div>
          <div className="col-span-1">
            <div className="flex justify-center items-center content-center w-full">
              <div className="flex w-full flex-col py-4 justify-center justify-items-center items-center content-center">
                <span className="text-sm text-danger tracking-widest">
                  {d.pricing?.total} {d.pricing?.currency}
                </span>
                <span className="text-sm text-warning tracking-widest">
                  {d.pricing?.fareType?.map((p, i) => (
                    <span
                      className="text-xs lowercase rounded-md border border-success px-2 text-success tracking-widest"
                      key={p}>
                      {p}
                    </span>
                  ))}
                </span>
                <span className="text-sm text-warning tracking-widest">
                  {d.pricingOptions?.map((p, i) => (
                    <span
                      className="text-xs lowercase rounded-md border border-success px-2 text-success tracking-widest"
                      key={p}>
                      {p}
                    </span>
                  ))}
                </span>
              </div>
              <div>
                <button
                  key={d.fareSourceCode}
                  onClick={() => {
                    execAction(d.fareSourceCode, d.fareSource, d.searchID);
                  }}
                  className="px-2 py-2 rounded-full border border-primary">
                  {show && d.fareSourceCode === selectId.fsc ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="bi bi-chevron-up text-primary"
                      viewBox="0 0 16 16">
                      <path
                        fillRule="evenodd"
                        d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="bi bi-chevron-down text-primary"
                      viewBox="0 0 16 16">
                      <path
                        fillRule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          
          <div className="additional-info w-full px-1 py-1 col-span-12">
              {show && d.fareSourceCode === selectId.fsc && (
                <div className="grid grid-cols-4">
                <div className="col-span-2 ">  

                  <div className="baggageInfo">
                    

                    <div className="grid grid-cols-2">
                      <div className="px-2">
                        <h3 className="text-xs text-primary text-bold py-2">
                          Baggage Info 
                        </h3>
                        <SelectTable className="text-gray-800 md:table-fixed">
                            <thead className="md:table-fixed text-xs m-4 font-normal border-b text-gray-500 bg-gray-200 border-gray-200">
                              <tr>
                                <th>
                                  <span>Flight </span>
                                </th>
                                <th>
                                  <span>Sector</span>
                                </th>
                                <th>
                                  <span>Allowance</span>
                                </th>                          
                              </tr>
                            </thead>
                            <tbody className="text-xs font-normal divide-y divide-gray-100">
                                {baggageInfo &&
                                  baggageInfo.map((r: any, i: any) => (
                                    <tr key={i}>                              
                                    <td className="text-center py-2">
                                      <span
                                        className="text-xs w-32 text-secondary"
                                        key={i}>
                                          {r.FlightNo}                          
                                      </span>
                                      </td>
                                      <td className="text-center">
                                      <span
                                        className="text-xs w-32 text-secondary"
                                        key={i}>
                                      {r.Departure}-{r.Arrival}
                                      </span>
                                      </td>
                                      <td className="text-center">
                                      <span
                                        className="text-xs w-32 text-secondary items-center content-center"
                                        key={i}>                          
                                        {r.Baggage}
                                      </span>
                                      </td>                                    
                                    </tr>
                                  ))}                                                    
                            </tbody>
                        </SelectTable>
                    </div>   

                      <div className="px-4">

                      <h3 className="text-xs text-primary text-bold py-2">
                          Policy and Rules
                        </h3>


                          <div className="flex justify-start items-center content-center space-x-4 mb-2">
                              <span className="text-xs text-secondary">
                                Last Ticketing Date
                              </span>
                              <span className="text-xs text-primary">
                                {" "}
                                {d.lastTicketingDate}{" "}
                              </span>
                          </div>
                          <div className="flex justify-start items-center content-center space-x-4 mb-2">
                              <span className="text-xs text-secondary">
                                Refundable
                              </span>
                              <span className="text-xs text-primary">
                                {" "}
                                {d.refundable}
                              </span>
                          </div>

                          <div className="flex justify-start items-center content-center space-x-4 mb-2">
                              <span className="text-xs text-secondary flex ">
                                Passport Mandatary
                              </span>
                              {d.passportMandatory ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-check2-circle text-success"
                                  viewBox="0 0 16 16">
                                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-x-circle text-danger"
                                  viewBox="0 0 16 16">
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                              )}
                          </div>

                          <div className="flex justify-start items-center content-center space-x-4 mb-2">
                                <span className="text-xs text-secondary flex ">
                                  InstantPurchase
                                </span>
                                {d.instantPurchase ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-check2-circle text-success"
                                    viewBox="0 0 16 16">
                                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-x-circle text-danger"
                                    viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                )}
                          </div>


                      </div>
                    </div>

                  </div>   

                  
                </div>

                <div className="">

                    <h3 className="text-xs text-primary text-bold py-2">
                      Detail Price
                    </h3>

                    <SelectTable className="text-gray-800 md:table-fixed">
                            <thead className="justify-center items-center content-center md:table-fixed text-xs m-1 font-normal border-b text-gray-500 bg-gray-200 border-gray-200">
                              <tr>
                                <th>
                                  <span>Type</span>
                                </th>
                                <th>
                                  <span>Base</span>
                                </th>
                                <th>
                                  <span>Tax</span>
                                </th> 
                                <th>
                                  <span>Total</span>
                                </th>                         
                              </tr>
                            </thead>
                            <tbody className="text-xs font-normal divide-y divide-gray-100">
                                {d.pricingDetail &&
                                  d.pricingDetail.map((r: any, i: any) => (
                                    <tr key={i}>                              
                                    <td className="text-center px-1 py-1">
                                      <span
                                        className="text-xs text-secondary"
                                        key={i}>
                                          {r.PassengerTypeQuantity.Code} x {r.PassengerTypeQuantity.Quantity}                     
                                      </span>
                                      </td>
                                      <td className="text-center  px-1 py-1">
                                      <span
                                        className="text-xs text-secondary"
                                        key={i}>
                                          {(r.base)*(r.PassengerTypeQuantity.Quantity)} 
                                      </span>
                                      </td>
                                      <td className="text-center px-1 py-1">
                                      <span
                                        className="text-xs text-secondary"
                                        key={i}>                          
                                        {(r.tax)*(r.PassengerTypeQuantity.Quantity)}
                                      </span>
                                      </td>
                                      <td className="text-center px-1 py-1">
                                        <span
                                          className="text-xs text-secondary"
                                          key={i}>                          
                                          {(r.total)*(r.PassengerTypeQuantity.Quantity)}
                                        </span>
                                      </td>                                    
                                    </tr>
                                  ))}                                                    
                            </tbody>
                        </SelectTable>                  
                </div>


                <div className="">
                  <div className="flex justify-end items-center content-center my-4">
                    <button
                      onClick={() => {
                        BookAction(
                          d.fareSourceCode,
                          d.fareSource,
                          d.searchID,
                          d.instantPurchase
                        );
                      }}
                      className="px-3 py-2 text-xs tracking-widest rounded-md border border-primary text-primary hover:bg-primary hover:text-white cursor-pointer appearance-none focus:outline-none">
                      Book
                    </button>
                  </div>
                </div>

                <div className="col-span-6">
                  <div className="fareRules ">                             
                      {fareRules &&
                        fareRules.map((f: any, i: any) =>
                          f.RuleDetails.map(
                            (a: any, idx: any) =>
                              a.Rules && (                            
                                <div key={i} className={a.Rules ? "h-40" : ""}>
                                  <div className="h-full overflow-y-auto">
                                    <span className="text-xs rounded-md text-warning">
                                      Fare rules
                                    </span>
                                    <div>
                                      <span className="text-xs text-secondary tracking-widest flex mb-2">
                                        {a.Category}
                                      </span>
                                      <span
                                        className="text-xs text-secondary tracking-widest"
                                        dangerouslySetInnerHTML={{
                                          __html: a.Rules
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                          )
                        )}
                    </div>
                    
                </div>


                </div>
              )}
          </div>

        </div>
      ))}
    </div>
  );
};
