import React from "react";
import { anouncementProps } from "../../../../pages/dashbaord/Home";
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'

import "./index.css";
interface dashbarodCard1Props {
  className?: string;
  annoucements?: anouncementProps[];
}
export const DashbaordAnnouncements: React.FC<dashbarodCard1Props> = ({annoucements}) => {
  return (
    <div>
      <div className="w-full">
        <h6 className="py-4 text-lg font-normal text-gray-400 uppercase">Announcements</h6>
      <div className="w-full max-w-screen-xl p-2  bg-white rounded-2xl">
        {
          annoucements?.map((a,i)=>(
            <Disclosure key={i} >
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 mb-2 text-sm font-medium text-left text-primary bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span> <span className=" px-2 py-2 rounded-full">{i+1}. </span> {a.title}</span>
                <ChevronUpIcon
                  className={`${
                    !open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-blue-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                 <div className="flex flex-col justify-start space-y-3">
                      {
                        a.attached && 
                        <img src={a.attached} className='w-full max-w-screen-sm h-auto object-center object-fill' alt="" />
                      }
                      <div className="text-sm font-normal text-gray-600">
                        {a.message}
                      </div>
                      <div className="flex justify-end items-center content-center px-2 py-3">
                      <p className="text-xs text-primary"> {new Date(a.postdate).toLocaleString()} </p>
                      </div>
                 </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
          ))
        }
        
      </div>
    </div>
    </div>
  );
};
