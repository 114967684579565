import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { BusBookingPage } from "./BusBookings";
import { BusSearchPage } from "./BusSearch";
import { BusBooking } from "./Booking";
import { BusBookingDetail } from "./BookingDetail";
export const BusServicePage: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact component={BusSearchPage} />
      <Route path={`${path}/bus_bookings`} exact component={BusBookingPage} />
      <Route
        path={`${path}/booking/:depfrom/:arrvto/:depdate/:pax/:nationality/:operatorcode/:tripID`}
        exact
        component={BusBooking}
      />
      <Route
        path={`${path}/booking_detail/:bookingId`}
        exact
        component={BusBookingDetail}
      />
    </Switch>
  );
};
