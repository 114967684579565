import { ChartOptions } from "chart.js";
import React from "react";
import {  serarchProps } from "../../../../pages/dashbaord/Home";
import { HorizontalBarChat } from "../../../common/Chart";
import { dataBarProps } from "../../../common/LineChart";

interface dashbarodCard1Props {
  className?: string;
  search?: serarchProps;
}
export const DashbaordSearchCard: React.FC<dashbarodCard1Props> = ({
  search
}) => {
  const searchData: dataBarProps = {
    labels: ['SEARCH'],
    datasets: [
     
      {
        label: 'ONLINE FLIGHT',
        data: [search?.onlineFlight?parseInt(search.onlineFlight) : 0],
        backgroundColor: '#2196f3'
      },
      {
        label: 'ONLINE BUS',
        data: [search?.onlineBus?parseInt(search.onlineBus) : 0],
        backgroundColor: '#9c27b0'
      },
      {
        label: 'ONLINE HOTEL',
        data: [search?.onlineHotel?parseInt(search.onlineHotel) : 0],
        backgroundColor: '#ffeb3b'
      }
    ]
  }

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: false,
        text: 'BOOKINGS'
      }
    },
    
  }
  return (
    
    <div className="px-2 py-2 shadow-md bg-white">
      <HorizontalBarChat options={options} data={searchData} />
    </div>
  );
};
