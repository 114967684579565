import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { authState } from "../../../../store/Reducers/auth";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { useToasts } from "react-toast-notifications";

import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../../components/common/SelectTable";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { b2bConfig } from "../../../../config";
import { Instance } from "../../../../services";
import { DatabaseIcon } from "@heroicons/react/solid";

import "./index.css";
import "../../../dashbaord/FlightServices/Results/spite.css";
import AirlineLogo from "../../../../assets/airlineLogo.png";
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { TicketInfoPDF } from "./pdf";

// import format from "date-fns/format";
export interface bookingDetailProps {
  [x: string]: any;
  FareType: any;
  Itineraries: any;
  result: object | null;
  booker: any;
  BookingStatus: string;
  TicketingTimeLimit: Date | undefined;
}

export const BookingDetail: React.FC<bookingDetailProps> = () => {
  const params: any = useParams();
  const history = useHistory();
  const bookingId: string = params.bookingId;
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<bookingDetailProps | undefined>();
  const [fareRule, setFareRule] = useState<any | undefined>();
  const [baggageInfo, setBaggaeInfo] = useState<any | undefined>();
  const [active, setActive] = useState(1);

  const { addToast } = useToasts();

  const userData = useSelector((state: RootState) => state.auth);

  const getData = useCallback(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (bookingId) {
      setLoading(true);
      
      Instance({
        baseURL: `${b2bConfig.apiPath.filghtServices}/booking-detail/${bookingId}`,
        headers: {
          Authorization: `JWT ${accessToken}`
        }
      })
        .then((res) => {
          
          if (res.data.success && res.data.data) {
            const response: bookingDetailProps = res.data.data;
            setResult(response);
            setLoading(false);
            // console.log(response);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err, "transaction_fetch_error");
        });
    } else {
      history.goBack();
    }
  }, [bookingId, history]);

  const getFareRule = useCallback(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    Instance({
      baseURL: `${b2bConfig.apiPath.filghtServices}/farerule/${bookingId}`,
      headers: {
        Authorization: `JWT ${accessToken}`
      }
    })
      .then((res) => {
        if (res.data.success && res.data.data) {
          const response: any = res.data.data;
          setFareRule(response.fareRules);
          setBaggaeInfo(response.BaggageInfos);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "transaction_fetch_error");
      });
  }, [bookingId]);

  useEffect(() => {
    getData();
    getFareRule();
  }, [getData, getFareRule]);

  const handleOrderTicket = (uniqueid: any) => {
    const accessToken = sessionStorage.getItem("accessToken");
    Instance({
      baseURL: `${b2bConfig.apiPath.filghtServices}/order-ticket`,
      method: "POST",
      headers: {
        Authorization: `JWT ${accessToken}`
      },
      data: {
        uniqueid: uniqueid,
        bookingID: bookingId
      }
    })
      .then((res) => {
        if (res.status === 200) {
          getData();
          getFareRule();
        }else if(res.status === 400){
          addToast(`Error FTO03 : ${JSON.stringify(res.data.error)}`, {
            appearance: "error",
            autoDismiss: false
          });
        }else{
          addToast(`Error FTO04 : ${JSON.stringify(res.data.error)}`, {
            appearance: "error",
            autoDismiss: false
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "transaction_fetch_error");

        addToast(`Error FTO03 : ${JSON.stringify(err.error)}`, {
          appearance: "error",
          autoDismiss: false
        });


      });
  };

  const handleCancelTicket = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    Instance({
      baseURL: `${b2bConfig.apiPath.filghtServices}/cancel-booking/${bookingId}`,
      headers: {
        Authorization: `JWT ${accessToken}`
      }
    })
      .then((res) => {
        if (res.status === 200) {
          getData();
          getFareRule();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "transaction_fetch_error");
      });
  };

  return (
    <div className="">
      <div className="flex mb-3">
        <div className="">
          <button
            onClick={() => history.goBack()}
            className="px-2 py-1 hover:bg-gray-300 rounded-md">
            <ChevronLeftIcon className="w-4 h-4 mt-1" />
          </button>
        </div>
        <div className="ml-1 mt-1">
          <span className="text-md">Flight Booking Detail</span>  
        </div>
      </div>

      {!loading && result && (
        <>
          <div className="flex flex-col space-y-4">
            <div className="bg-white rounded-md shadow-sm p-2">
              <SelectTable className="text-gray-800 md:table-fixed">
                <tbody className="text-xs font-normal divide-y divide-gray-100">
                  <TableRow>
                    <TableCell>
                      <span>Booking ID</span>
                    </TableCell>
                    <TableCell>
                      <span>{bookingId}</span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <span>Book On</span>
                    </TableCell>
                    <TableCell>
                      <span>
                      {moment(result.Booker.BookingDate).format("LLL")}{" "}                        
                      </span>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <span>Order On</span>
                    </TableCell>
                    <TableCell>
                      <span>

                      {result.Booker.OrderTicketDate !== null && (
                          moment(result?.Booker.OrderTicketDate).format("LLL")
                      )}
                                  
                      </span>
                    </TableCell>
                  </TableRow>


                  <TableRow>
                    <TableCell>
                      <span>Business Name</span>
                    </TableCell>
                    <TableCell>
                      <span>
                        {userData.bzNm}
                        
                        {/* result.Booker.BizID */}
                        
                      </span>
                    </TableCell>
                  </TableRow>
                </tbody>
              </SelectTable>
            </div>

            <div className="py-3">
              <h3 className="uppercase">Itineray</h3>
              <div className="flex justify-between mt-3 text-xs">
                <div>
                  <span>
                    Booking Status :{" "}
                    <span className="font-bold pl-1">
                      {result?.BookingStatus}
                    </span>
                  </span>
                  <span><br></br>
                    Ticket Status :{" "}
                    <span className="font-bold pl-1">
                      {result?.TicketStatus}
                    </span>
                  </span>

                </div>
                <div className="space-x-3">
                  <span>
                    Ticket Time Limit:
                    <span className=" text-gray-900">
                      {" "}
                      {moment(result.TicketingTimeLimit).format(
                        "DD MMM YYYY HHMM"
                      )}{" "}
                      LT*
                    </span>
                  </span>

                  {result.FareType !== "WebFare" && (
                    <>
                      {result.BookingStatus === "Booked" &&
                        !result.TicketStatus && (
                          <span
                            className="text-red-600 cursor-pointer underline"
                            onClick={() => handleOrderTicket(result.MFRef)}>
                            Order E-ticket
                          </span>
                        )}

                      {result.BookingStatus === "Booked" &&
                        result.TicketStatus !== "CANCEL" &&
                        result.TicketStatus !== "BookingInProcess" && (
                          <span
                            className="text-red-600 cursor-pointer underline"
                            onClick={handleCancelTicket}>
                            Cancel
                          </span>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="bg-white rounded-md shadow-sm p-2">
              <SelectTable className="text-gray-800 md:table-fixed">
                <thead className="md:table-fixed text-xs m-4 font-normal border-b text-gray-500 bg-gray-200 border-gray-200">
                  <TableRow>
                    <TableCell isHeader={true}>
                      <span>Airline</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span>Flight</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span>Class</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span>Depart</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span>Arrivies</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span>Duration</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span>Status</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span>Airline PNR</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span className="">Fare Type</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span className="">Baggage</span>
                    </TableCell>
                  </TableRow>
                </thead>

                <tbody className="text-xs font-normal divide-y divide-gray-100">
                  {result.Itineraries[0].ItineraryInfo.ReservationItems.map(
                    (m: any, idx: any) => (
                      <TableRow key={idx}>
                        <TableCell>
                          <span
                            style={{ backgroundImage: `url(${AirlineLogo})` }}
                            className={`airline-${m.MarketingAirlineCode} block`}></span>
                            {m.MarketingAirlineName}
                        </TableCell>
                        <TableCell>
                          {/* <span className="block">Emriates</span> */}
                          
                          <span className="block">{m.MarketingAirlineCode} {m.FlightNumber}</span>
                            {m.MarketingAirlineCode !== m.OperatingAirlineCode &&(
                              <span>Operate By {m.OperatingAirlineName}</span>
                            )}
                        </TableCell>
                        <TableCell>
                          <span className="block">{m.FareFamily}</span>
                        </TableCell>
                        <TableCell>
                          <span className="block">
                            {m.DepartureAirportLocationName}
                            ({m.DepartureAirportLocationCode})
                          </span>
                          <span className="block">
                            <br></br>                            
                            {moment(m.DepartureDateTime).format(
                             "LLL"
                            )}{" "}

                          </span>
                        </TableCell>
                        <TableCell>
                          <span className="block">
                            {m.ArrivalAirportLocationName}
                            ({m.ArrivalAirportLocationCode})
                          </span>
                          <span className="block">
                            <br></br>
                            {moment(m.ArrivalDateTime).format(
                             "LLL"
                            )}{" "}                            
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>{m.JourneyDuration} min</span>
                        </TableCell>
                        <TableCell>
                          <span>{m.FlightStatus}</span>
                        </TableCell>
                        <TableCell>
                          <span>{m.AirlinePNR}</span>
                        </TableCell>
                        <TableCell>
                          <span>{result.FareType}</span>
                        </TableCell>
                        <TableCell>
                          <span>{m.Baggage}</span>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </tbody>
              </SelectTable>
            </div>


            <h3 className="pt-4 uppercase">Passenger Details</h3>
            <div className="bg-white rounded-md shadow-sm mt-2 pt-4 p-2">
              <SelectTable className="text-gray-800 md:table-fixed">
                <thead className="md:table-fixed text-xs m-4 font-normal border-b text-gray-500 bg-gray-200 border-gray-200">
                  <TableRow>
                    <TableCell isHeader={true}>
                      <span>Name</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span>Date of birth</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span>Gender</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span>Passport No</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span>Expires On</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span>Nationality</span>
                    </TableCell>
                    <TableCell isHeader={true}>
                      <span>Issuace Country</span>
                    </TableCell>

                    <TableCell isHeader={true}>
                      <span>ETicket Number</span>
                    </TableCell>
 
                  </TableRow>
                </thead>

                <tbody className="text-xs font-normal divide-y divide-gray-100">
                  {result.PassengerInfos.map((m: any, idx: any) => (
                    <TableRow key={idx}>
                      <TableCell>
                        <span className="block">
                          {`${m.Passenger.PaxName.PassengerTitle} ${m.Passenger.PaxName.PassengerFirstName} ${m.Passenger.PaxName.PassengerLastName}`}{" "}
                          <span>({m.Passenger.PassengerType})</span>
                        </span>
                        
                      </TableCell>
                      <TableCell>
                        <span>
                          {moment(m.Passenger.DateOfBirth).format(
                            "DD MMM YYYY"
                          )}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span>{m.Passenger.Gender}</span>
                      </TableCell>
                      <TableCell>
                        <span>{m.Passenger.PassportNumber}</span>
                      </TableCell>
                      <TableCell>
                        <span>
                          {moment(m.Passenger.PassportExpiresOn).format(
                            "DD MMM YYYY"
                          )}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span>{m.Passenger.PassengerNationality}</span>
                      </TableCell>
                      <TableCell>
                        <span>{m.Passenger.PassportIssuanceCountry}</span>
                      </TableCell>
                      <TableCell>
                        {m.ETickets &&
                          m.ETickets.map((e: any, i: any) => (
                            <span key={i} className="block">
                              {e.ETicketNumber}
                            </span>
                          ))}                        
                      </TableCell>
                  
                    </TableRow>
                  ))}
                </tbody>
              </SelectTable>
            </div>






            <div className="mt-1 flex justify-between">
              <div>
                
              </div>
              <div className="text-xs space-x-3">
                <span
                  className={`${
                    active === 1 ? "text-red-700 font-bold" : "text-red-500"
                  } underline cursor-pointer`}
                  onClick={() => setActive(1)}>
                  Fare Break Down
                </span>
                <span
                  className={`${
                    active === 2 ? "text-red-700 font-bold" : "text-red-500"
                  } underline cursor-pointer`}
                  onClick={() => setActive(2)}>
                  Baggage
                </span>
                <span
                  className={`${
                    active === 3 ? "text-red-700 font-bold" : "text-red-500"
                  } underline cursor-pointer`}
                  onClick={() => setActive(3)}>
                  Fare Rule
                </span>
              </div>
            </div>

            <div>
                <p className="text-sm font-bold">
                  Total Fare:{" "}
                  <span className="text-red-500">                    
                      {result.TripDetailsPTC_FareBreakdowns.map(
                        (r: any, i: any) => (
                          <span>
                            {`${
                            r.TripDetailsPassengerFare.EquiFare.CurrencyCode
                          } ${
                            ((r.TripDetailsPassengerFare.EquiFare.Amount + r.TripDetailsPassengerFare.Tax.Amount) *
                            r.PassengerTypeQuantity.Quantity).toFixed(2)
                          }`}
                          </span>   
                        )
                      )}
                  </span>
                </p>
            </div>

            {active === 1 && (
              <>
                <h3 className="pt-4 uppercase">Fare break down</h3>
                <div className="bg-white rounded-md shadow-sm mt-2 pt-4 p-2">
                  <SelectTable className="text-gray-800 md:table-fixed">
                    <thead className="md:table-fixed text-xs m-4 font-normal border-b text-gray-500 bg-gray-200 border-gray-200">
                      <TableRow>
                        <TableCell isHeader={true}>
                          <span>Passenger Type</span>
                        </TableCell>
                        <TableCell isHeader={true}>
                          <span>Bass Fare</span>
                        </TableCell>
                        <TableCell isHeader={true}>
                          <span>{`Tax & Fee`}</span>
                        </TableCell>
                        <TableCell isHeader={true}>
                          <span>Per Passenger</span>
                        </TableCell>
                        <TableCell isHeader={true}>
                          <span>Total</span>
                        </TableCell>
                      </TableRow>
                    </thead>

                    <tbody className="text-xs font-normal divide-y divide-gray-100">
                      {result.TripDetailsPTC_FareBreakdowns.map(
                        (r: any, i: any) => (
                          <TableRow key={i}>
                            <TableCell>
                              <span>{`${r.PassengerTypeQuantity.Code}`} x {`${r.PassengerTypeQuantity.Quantity}`}</span>
                            </TableCell>
                            <TableCell>
                              <span>{`${r.TripDetailsPassengerFare.EquiFare.CurrencyCode} ${r.TripDetailsPassengerFare.EquiFare.Amount}`} x {`${r.PassengerTypeQuantity.Quantity}`}</span>
                            </TableCell>
                            <TableCell>
                              <span>{`${r.TripDetailsPassengerFare.Tax.CurrencyCode} ${r.TripDetailsPassengerFare.Tax.Amount}`} x {`${r.PassengerTypeQuantity.Quantity}`}</span>
                            </TableCell>
                            <TableCell>
                              <span>{`${r.TripDetailsPassengerFare.TotalFare.CurrencyCode} ${r.TripDetailsPassengerFare.TotalFare.Amount}`} x {`${r.PassengerTypeQuantity.Quantity}`} </span>                              
                            </TableCell>
                            <TableCell>
                              <span>{`${
                                  r.TripDetailsPassengerFare.EquiFare.CurrencyCode
                                } ${
                                  ((r.TripDetailsPassengerFare.EquiFare.Amount + r.TripDetailsPassengerFare.Tax.Amount) *
                                  r.PassengerTypeQuantity.Quantity).toFixed(2)
                                }`}</span>                                                        
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </tbody>
                  </SelectTable>
                </div>
              </>
            )}

            {active === 2 && baggageInfo && (
              <div className="py-3">
                <h3 className="uppercase">Baggage Information</h3>
                <div className="bg-white rounded-md shadow-sm mt-2 p-2">
                  <SelectTable className="text-gray-800 md:table-fixed">
                    <thead className="md:table-fixed text-xs m-4 font-normal border-b text-gray-500 bg-gray-200 border-gray-200">
                      <TableRow>
                        <TableCell isHeader={true}>
                          <span>Arrival</span>
                        </TableCell>
                        <TableCell isHeader={true}>
                          <span>Baggage</span>
                        </TableCell>
                        <TableCell isHeader={true}>
                          <span>Departure</span>
                        </TableCell>
                        <TableCell isHeader={true}>
                          <span>FlightNo</span>
                        </TableCell>
                      </TableRow>
                    </thead>
                    <tbody className="text-xs font-normal divide-y divide-gray-100">
                      {baggageInfo.map((b: any, idx: any) => (
                        <TableRow key={idx}>
                          <TableCell>
                            <span>{b.Arrival}</span>
                          </TableCell>
                          <TableCell>
                            <span>{b.Baggage}</span>
                          </TableCell>
                          <TableCell>
                            <span>{b.Departure}</span>
                          </TableCell>
                          <TableCell>
                            <span>{b.FlightNo}</span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </SelectTable>
                </div>
              </div>
            )}

            {active === 3 && fareRule && (
              <div className="py-3">
                <h3 className="uppercase">Fare Rule</h3>
                <div className="bg-white rounded-md shadow-sm mt-2 p-4 h-96 overflow-y-auto">
                  {fareRule.map((f: any) =>
                    f.RuleDetails.map(
                      (r: any, idx: any) =>
                        r.Category && (
                          <div key={idx} className="pb-4">
                            <h5>{r.Category}</h5>
                            <p
                              className="text-xs"
                              dangerouslySetInnerHTML={{
                                __html: r.Rules
                              }}
                            />
                          </div>
                        )
                    )
                  )}
                </div>
              </div>
            )}

            
          </div>

          {result.TicketStatus === "Ticketed" && (
            <div className="mt-4">
              <button className="border px-4 py-2 flex rounded-md justify-between items-center content-center border-yellow-400 bg-yellow-400 hover:bg-yellow-600 text-white">
                <PDFDownloadLink
                  document={
                    <TicketInfoPDF
                      result={result}
                      FareType={result.FareType}
                      Itineraries={result.Itineraries}
                      BookingStatus={""}
                      bookingId={bookingId}
                      TicketingTimeLimit={undefined}
                      booker={result.Booker}
                      PassengerInfos={result.PassengerInfos}
                      businessName= {userData.bzNm}
                    />
                  }
                  fileName={`ticket_detail.pdf`}>
                  {({ loading }) =>
                    loading ? "Loading document..." : "Download"
                  }
                </PDFDownloadLink>
              </button>
            </div>
          )}
        </>
      )}

      {!loading && !result && (
        <div className="bg-white w-full p-6 rounded-md shadow-sm mt-4">
          <div className="flex justify-center items-center h-12">
            <DatabaseIcon className="w-4 h-4 mr-2" />
            <span className="text-sm">No data for now.</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingDetail;
