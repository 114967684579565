import React from 'react'
import LogoImg from '../../../assets/admin_logo.png'
import { DashboardDivider } from '../Divider'

import FnFAdmin from '../../../assets/fna.png'
import BusinessAdmin from '../../../assets/business.png'
import HotelAdmin from '../../../assets/hotel.png'
import AttractionAdmin from '../../../assets/tour.png'
import CarrentAdmin from '../../../assets/carrent.png'
// import InsuranceAdmin from '../../../assets/insurance.png'
import TourAdmin from '../../../assets/tour_admin.png'
import DashbaordImg from '../../../assets/dashboard.png'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { Disclosure } from '@headlessui/react'
import { BookmarkIcon, ChevronUpIcon, DatabaseIcon, DocumentIcon, PaperAirplaneIcon, PhoneIncomingIcon, SearchIcon, UserIcon,OfficeBuildingIcon, TruckIcon, DeviceMobileIcon, ShieldCheckIcon, DocumentTextIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
interface dashboardsideBarProps {
  className?: string
  setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>
  sideBarOpen: boolean
}
export const DashboardSidebar: React.FC<dashboardsideBarProps> = ({
  setSideBarOpen,
  sideBarOpen
}) => {
  const { modules } = useSelector((state: RootState) => state.auth)  
  return (
    <div className=''>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sideBarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      />

      {/* Sidebar */}
      <div
        className={`flex flex-col absolute z-40 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto w-64 shrink-0  bg-gray-900 transition-all duration-200 ease-in-out ${
          sideBarOpen
            ? ' w-0 lg:w-20 translate-x-0 transition duration-700'
            : '-translate-x-64 transition duration-700'
        }`}
        aria-hidden='true'
      >
        {/* Sidebar Header */}
        <div className='flex w-full space-x-2 justify-start border-b border-gray-800 content-center items-center h-16 px-2'>
          {/* Logo */}
          <img src={LogoImg} alt='' className='w-auto h-8' />
          <span className='text-xs font-medium tracking-widest text-gray-300'>TRAVELS.COM.MM</span>
        </div>

        {/* Links */}
        <div className='space-y-8 '>
          {/* Pages Groups */}
          <div className=''>
            <div className='mt-3'>
              <div className='w-full p-2 mx-auto bg-gray-900 rounded-md tracking-widest'>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        as={Link}
                        to='/dashboard'
                        className='flex justify-between items-center content-center w-full px-4 py-2 text-sm font-medium text-left text-gray-400 bg-gray-900 rounded-lg hover:bg-gray-900 hover:text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500 mb-2 focus-visible:ring-opacity-75'
                      >
                        <div className='flex space-x-2 justify-center items-center content-center'>
                          <img
                            src={DashbaordImg}
                            alt=''
                            className='w-auto h-6'
                          />
                          <span className='font-normal'>Dashboard</span>
                        </div>
                        <ChevronUpIcon
                          className={`${
                            !open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-purple-500`}
                        />
                      </Disclosure.Button>
                    </>
                  )}
                </Disclosure>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        as={Link}
                        to='/dashboard/bz_entity'
                        className='flex justify-between items-center content-center w-full px-4 py-2 text-sm font-medium text-left text-gray-400 bg-gray-900 rounded-lg hover:bg-gray-900 hover:text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500 mb-2 focus-visible:ring-opacity-75'
                      >
                        <div className='flex space-x-2 justify-center items-center content-center'>
                          <OfficeBuildingIcon className='w-5 h-5' />
                          <span className='font-normal'>Business</span>
                        </div>
                        <ChevronUpIcon
                          className={`${
                            !open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-purple-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className='px-4 py-2 text-sm text-gray-500'>
                        <div className='flex justify-start items-center content-center w-full flex-col space-y-3'>
                        <Link
                            to='/dashboard/bz_entity'
                            className='w-full text-gray-100 hover:text-purple-800 ml-4'
                          >
                            <div className='flex w-full justify-start items-center content-center space-x-2'>
                              <UserIcon className='w-5 h-5' />
                              <span className='text-xs font-normal'>Profile</span>
                            </div>
                          </Link>
                          <Link
                            to='/dashboard/bz_entity/bz_balance'
                            className='w-full text-gray-100 hover:text-purple-800 ml-4'
                          >
                            <div className='flex w-full justify-start items-center content-center space-x-2'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                fill='currentColor'
                                className='bi bi-wallet'
                                viewBox='0 0 16 16'
                              >
                                <path d='M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z' />
                              </svg>
                              <span className='text-xs font-normal'>Balance</span>
                            </div>
                          </Link>
                          <Link
                            to='/dashboard/bz_entity/exchange'
                            className='w-full text-gray-100 hover:text-purple-800 ml-4'
                          >
                            <div className='flex w-full justify-start items-center content-center space-x-2'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                fill='currentColor'
                                className='bi bi-arrow-repeat'
                                viewBox='0 0 16 16'
                              >
                                <path d='M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z' />
                                <path
                                  fillRule='evenodd'
                                  d='M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z'
                                />
                              </svg>
                              <span className='text-xs font-normal'>Exchange</span>
                            </div>
                          </Link>
                          <Link
                            to='/dashboard/bz_entity/bz_transactions'
                            className='w-full text-gray-100 hover:text-purple-800 ml-4'
                          >
                            <div className='flex w-full justify-start items-center content-center space-x-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bar-chart-steps" viewBox="0 0 16 16">
  <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0zM2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1z"/>
</svg>
                              <span className='text-xs font-normal'>Transactions</span>
                            </div>
                          </Link>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                {!sideBarOpen && <DashboardDivider title='Services' />}
                {modules?.map((m, i) => (
                  <Disclosure key={i}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          as={Link}
                          to={`/dashboard/${m.moduleURL}`}
                          className='flex justify-between items-center content-center w-full px-4 py-2 text-sm font-medium text-left text-gray-400 bg-gray-900 rounded-lg hover:bg-gray-900 hover:text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500 mb-2 focus-visible:ring-opacity-75'
                        >
                          <div className='flex space-x-2 justify-center items-center content-center'>
                            {
                                m.moduleURL === 'bus'
                                  ? <TruckIcon className='w-5 h-5' />
                                  : m.moduleURL === 'flight'
                                  ? <PaperAirplaneIcon className='w-5 h-5' />
                                  : m.moduleURL === 'mobile'
                                  ? <DeviceMobileIcon className='w-5 h-5' />
                                  : m.moduleURL === 'biz-admin'
                                  ? BusinessAdmin
                                  : m.moduleURL === 'acct-admin'
                                  ? FnFAdmin
                                  : m.moduleURL === 'carrental'
                                  ? CarrentAdmin
                                  : m.moduleURL === 'hotel'
                                  ? HotelAdmin
                                  : m.moduleURL === 'insurance'
                                  ? <ShieldCheckIcon className='w-5 h-5' /> //InsuranceAdmin
                                  : m.moduleURL === 'attractions'
                                  ? AttractionAdmin
                                  : m.moduleURL === 'tour'
                                  ? TourAdmin
                                  : ''
                              }
                             
                            <span className='font-normal text-sm tracking-widest capitalize'>
                              {m.moduleName.toLocaleLowerCase()} 
                            </span>
                          </div>
                          <ChevronUpIcon
                            className={`${
                              !open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-purple-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className='px-4 py-2 text-sm text-gray-500'>
                          {m.moduleURL === 'bus' ? (
                            <div className='flex justify-start items-center content-center w-full flex-col space-y-3'>
                               <Link
                                to='/dashboard/bus'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <SearchIcon className='w-5 h-5' />
                                  <span className='text-xs font-normal'>Search</span>
                                </div>
                              </Link>
                              <Link
                                to='/dashboard/bus/bus_bookings'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <BookmarkIcon className='w-5 h-5' />
                                  <span className='text-xs font-normal'>Bookings</span>
                                </div>
                              </Link>
                            </div>
                          ) : m.moduleURL === 'flight' ? (
                            <div className='flex justify-start items-center content-center w-full flex-col space-y-3'>
                              <Link
                                to='/dashboard/flight'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <SearchIcon className='w-5 h-5' />
                                  <span className='text-xs font-normal'>Online Book</span>
                                </div>
                              </Link>
                              <Link
                                to='/dashboard/flight/online-bookings'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <BookmarkIcon className='w-5 h-5' />
                                  <span className='text-xs font-normal'>Online Bookings</span>
                                </div>
                              </Link>
                              <Link
                                to='/dashboard/flight/offline-order-booking'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <SearchIcon className='w-5 h-5' />
                                  <span className='text-xs font-normal'>Offline Book</span>
                                </div>
                              </Link>
                              <Link
                                to='/dashboard/flight/offline-bokings'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <BookmarkIcon className='w-5 h-5' />
                                  <span className='text-xs font-normal'>Offline Bookings</span>
                                </div>
                              </Link>
                            </div>
                          ) : m.moduleURL === 'mobile' ? (
                            <div className='flex justify-start items-center content-center w-full flex-col space-y-3'>
                              <Link
                                to='/dashboard/mobile'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <PhoneIncomingIcon className='w-5 h-5 text-gray-300' />
                                  <span>Topup</span>
                                </div>
                              </Link>
                              <Link
                                to='/dashboard/mobile/data-packs'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <DatabaseIcon className='w-5 h-5 text-gray-300' />
                                  <span>Data Pack</span>
                                </div>
                              </Link>
                              <Link
                                to='/dashboard/mobile/transactions'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <DocumentIcon className='w-5 h-5 text-gray-300' />
                                  <span>Mobile History</span>
                                </div>
                              </Link>
                            </div>
                          ) : m.moduleURL === 'biz-admin' ? (
                            <div className='flex justify-start items-center content-center w-full flex-col space-y-3'>
                              <Link
                                to='/dashboard/bz_entity/bz_balance'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-wallet'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z' />
                                  </svg>
                                  <span>Balance</span>
                                </div>
                              </Link>
                              <Link
                                to='/dashboard/bz_entity/exchange'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-arrow-repeat'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z' />
                                    <path
                                      fillRule='evenodd'
                                      d='M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z'
                                    />
                                  </svg>
                                  <span>Exchange</span>
                                </div>
                              </Link>
                            </div>
                          ) : m.moduleURL === 'acct-admin' ? (
                            <div className='flex justify-start items-center content-center w-full flex-col space-y-3'>
                              <Link
                                to='/dashboard/bz_entity/bz_balance'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-wallet'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z' />
                                  </svg>
                                  <span>Balance</span>
                                </div>
                              </Link>
                              <Link
                                to='/dashboard/bz_entity/exchange'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-arrow-repeat'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z' />
                                    <path
                                      fillRule='evenodd'
                                      d='M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z'
                                    />
                                  </svg>
                                  <span>Exchange</span>
                                </div>
                              </Link>
                            </div>
                          ) : m.moduleURL === 'carrental' ? (
                            <div className='flex justify-start items-center content-center w-full flex-col space-y-3'>
                              <Link
                                to='/dashboard/bz_entity/bz_balance'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-wallet'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z' />
                                  </svg>
                                  <span>Balance</span>
                                </div>
                              </Link>
                              <Link
                                to='/dashboard/bz_entity/exchange'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-arrow-repeat'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z' />
                                    <path
                                      fillRule='evenodd'
                                      d='M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z'
                                    />
                                  </svg>
                                  <span>Exchange</span>
                                </div>
                              </Link>
                            </div>
                          ) : m.moduleURL === 'hotel' ? (
                            <div className='flex justify-start items-center content-center w-full flex-col space-y-3'>
                              <Link
                                to='/dashboard/bz_entity/bz_balance'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-wallet'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z' />
                                  </svg>
                                  <span>Balance</span>
                                </div>
                              </Link>
                              <Link
                                to='/dashboard/bz_entity/exchange'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-arrow-repeat'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z' />
                                    <path
                                      fillRule='evenodd'
                                      d='M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z'
                                    />
                                  </svg>
                                  <span>Exchange</span>
                                </div>
                              </Link>
                            </div>
                          ) : m.moduleURL === 'insurance' ? (
                            <div className='flex justify-start items-center content-center w-full flex-col space-y-3'>
                              <Link
                                to='/dashboard/insurance'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <DocumentTextIcon className='w-5 h-5'/>
                                  <span className='text-xs font-normal'>Buy</span>
                                </div>
                              </Link>
                              <Link
                                to='/dashboard/insurance/bookings'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <BookmarkIcon  className='w-5 h-5' />
                                  <span className='text-xs font-normal'>Booking</span>
                                </div>
                              </Link>
                            </div>
                          ) : m.moduleURL === 'attractions' ? (
                            <div className='flex justify-start items-center content-center w-full flex-col space-y-3'>
                              <Link
                                to='/dashboard/bz_entity/bz_balance'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-wallet'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z' />
                                  </svg>
                                  <span>Balance</span>
                                </div>
                              </Link>
                              <Link
                                to='/dashboard/bz_entity/exchange'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-arrow-repeat'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z' />
                                    <path
                                      fillRule='evenodd'
                                      d='M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z'
                                    />
                                  </svg>
                                  <span>Exchange</span>
                                </div>
                              </Link>
                            </div>
                          ) : m.moduleURL === 'tour' ? (
                            <div className='flex justify-start items-center content-center w-full flex-col space-y-3'>
                              <Link
                                to='/dashboard/bz_entity/bz_balance'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-wallet'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z' />
                                  </svg>
                                  <span>Balance</span>
                                </div>
                              </Link>
                              <Link
                                to='/dashboard/bz_entity/exchange'
                                className='w-full text-gray-100 hover:text-purple-800 ml-4'
                              >
                                <div className='flex w-full justify-start items-center content-center space-x-2'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    fill='currentColor'
                                    className='bi bi-arrow-repeat'
                                    viewBox='0 0 16 16'
                                  >
                                    <path d='M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z' />
                                    <path
                                      fillRule='evenodd'
                                      d='M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z'
                                    />
                                  </svg>
                                  <span>Exchange</span>
                                </div>
                              </Link>
                            </div>
                          ) : (
                            ''
                          )}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className='inline-flex justify-end pt-3 mt-auto border-t border-gray-600'>
          <div className='px-3 py-2'>
            <button onClick={() => setSideBarOpen(!sideBarOpen)}>
              <span className='sr-only'>Expand / collapse sidebar</span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-backspace text-gray-100'
                viewBox='0 0 16 16'
              >
                <path d='M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z' />
                <path d='M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z' />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
