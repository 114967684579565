import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from "chart.js";

ChartJs.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export interface dataBarProps {
  labels: string[];
  datasets: dataSetProps[];
}
interface dataSetProps {
  label: string;
  data: number[];
  backgroundColor: string;
}
interface barChartProps {
  data: dataBarProps;
  className?: string;

  options: ChartOptions;
}

export const HorizontalBarChat: React.FC<barChartProps> = ({
  data,
  options
}) => {
  return <Bar options={options} data={data} />;
};
