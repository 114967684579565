import React, { useState } from "react";

import { useToasts } from "react-toast-notifications";
import { Bbutton } from "../../../../components/common/Bbutton";
import { BInput } from "../../../../components/common/BInput";
import { BPopup } from "../../../../components/common/Bpopup";
import { BRadio } from "../../../../components/common/BRadio";
import { Operator } from "../../../../components/dgservices/Operators";
import { b2bConfig } from "../../../../config";
import { checkMobile, resultProps } from "../../../../helper/CheckMobile";
import { Instance } from "../../../../services";

import "./index.css";

interface mobileTopupPageProps {
  className?: string;
}
interface formDataProps {
  mobileno: string;
  rechargeamount: string;
  otherAmount: number;
  remark: string;
}
export const MobileTopupPage: React.FC<mobileTopupPageProps> = ({
  className
}) => {
  const [formData, setFormData] = useState<formDataProps>({
    mobileno: process.env.NODE_ENV === "development" ? "09421022637" : "",
    rechargeamount: "1000",
    otherAmount: 0,
    remark: ""
  });
  const billerCode: resultProps = checkMobile(formData.mobileno);
  const { addToast } = useToasts();
  const [showForm, setShowForm] = useState<boolean>(false);
  const SubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      let formObj: any = {};
      formObj["mobileno"] = formData.mobileno;
      formObj["BillerCode"] = billerCode.billerCode;
      if (parseInt(formData.rechargeamount) > 0) {
        formObj["rechargeamount"] = parseInt(formData.rechargeamount);
      } else {
        formObj["rechargeamount"] = formData.otherAmount;
      }
      if (formObj.rechargeamount > 0) {
        Instance({
          url: `${b2bConfig.apiPath.dgServices}/mobile/rechargeenquiry`,
          method: "POST",
          data: formObj
        })
          .then(res => {
            if (res.data && res.data.Status && res.data.Status.Code === "01") {
              formData.rechargeamount = formObj["rechargeamount"]
              setShowForm(true);
            } else if (
              res.data &&
              res.data.Status &&
              res.data.Status.Code === "404"
            ) {
              addToast(res.data.Status.Message, {
                appearance: "warning",
                autoDismiss: true
              });
            }else if(
              res.data.Status.Code === "400"
            ){
              addToast(res.data.Status.Message, {
                appearance: "error",
                autoDismiss: false
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        addToast(`Please enter amount.`, {
          appearance: "error",
          autoDismiss: true
        });
      }
    } catch (error) {
      addToast(JSON.stringify(error), {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  const ConfirmTopup = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("mobileno", formData.mobileno);
      data.append("rechargeamount", formData.rechargeamount);
      data.append("BillerCode", billerCode.billerCode);
      data.append("remark", formData.remark);
      Instance({
        url: `${b2bConfig.apiPath.dgServices}/mobile/rechargeconfirm`,
        data: data,
        method: "POST"
      })
        .then(res => {
          setShowForm(false);
          if (res.data && res.data.Status && res.data.Status.Code) {
            addToast("Mobile recharge successful", {
              appearance: "success",
              autoDismiss: true
            });
          } else if (res.data && res.data.Status) {
            addToast(`${res.data.Status.Code} - ${res.data.Status.Message}`);
          } else {
            addToast("Something wrong", {
              appearance: "warning",
              autoDismiss: true
            });
          }
        })
        .catch(err => {
          addToast("Error Code: DS03", {
            appearance: "error",
            autoDismiss: true
          });
          setShowForm(false);
        });
    } catch (error) {
      addToast("Error Code: DS04", {
        //JSON.stringify(error)
        appearance: "error",
        autoDismiss: true
      });
      setShowForm(false);
    }
  };
  return (
    <div className={`${className} w-full`}>
      <div className="w-full max-w-screen-md shadow-md px-4 py-4 rounded-md bg-white">
        <form
          onSubmit={e => SubmitForm(e)}
          className="flex flex-col space-y-2 w-full"
        >
          <BInput
            title="Mobile Number"
            name="mobileno"
            id="mobileno"
            formData={formData}
            setFormData={setFormData}
            type="tel"
          />
          <Operator operator={billerCode} />
          <label
            className="my-2 text-sm text-secondary ml-2"
            htmlFor="rechargeamount"
          >
            Recharge Amount
          </label>
          <BRadio
            radios={[
              { text: "1000", value: "1000" },
              { text: "3000", value: "3000" },
              { text: "5000", value: "5000" },
              { text: "10000", value: "10000" },
              { text: "Other", value: "" }
            ]}
            name="rechargeamount"
            id="rechargeamount"
            formData={formData}
            setValue={setFormData}
            inline
          />
          {formData.rechargeamount === "" &&
            <BInput
              formData={formData}
              setFormData={setFormData}
              name="otherAmount"
              id="otherAmount"
              type="number"
            />}
          <div className="flex">
            <Bbutton title="Submit" type="submit" />
          </div>
        </form>
        <BPopup
          show={showForm}
          setShow={setShowForm}
          size="small"
          title="Topup Confirm"
        >
          <form
            onSubmit={e => {
              ConfirmTopup(e);
            }}
          >
            <div className="flex justify-start items-center content-center space-x-2 flex-col px-3 py-3">
              <BInput
                disabled
                formData={formData}
                setFormData={setFormData}
                name="mobileno"
                id="mobileno"
                type="text"
                className="text-gray-600"
                title="Mobile Number"
              />
              <BInput
                disabled
                formData={formData}
                setFormData={setFormData}
                name="rechargeamount"
                id="rechargeamount"
                type="text"
                className="text-gray-600"
                title="Amount"
              />
              <div className="w-full flex flex-row space-x-4 my-2">
                <p className="text-sm text-gray-600 ml-2">Biller Code</p>
                <p className="text-sm text-blue-600 ml-2">
                  {billerCode.billerCode}
                </p>
              </div>
              <BInput
                formData={formData}
                setFormData={setFormData}
                name="remark"
                id="remark"
                type="text"
                title="Remark"
              />
            </div>
            <Bbutton type="submit" title="Sumbit" />
          </form>
        </BPopup>
      </div>
    </div>
  );
};
