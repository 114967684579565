import React from "react";
import { busBookingProps } from "../../../pages/dashbaord/BusService/Booking";
import { BInput } from "../../common/BInput";
import { BRadio } from "../../common/BRadio";
import { BTextAreaInput } from "../../common/BTextAreaInput";

interface seatPlanProps {
  className?: string;
  numOfPax: number;
  formData: busBookingProps;
  setFormData: React.Dispatch<React.SetStateAction<busBookingProps>>;
}

export const TranvellerInput: React.FC<seatPlanProps> = ({
  className,

  formData,
  setFormData
}) => {
  const onPreviousFn = () => {
    setFormData({
      ...formData,
      step: "1"
    });
  };
  const onNextFn = (e: React.FormEvent) => {
    e.preventDefault();
    setFormData({
      ...formData,
      step: "3"
    });
  };
  return (
    <form
      onSubmit={e => onNextFn(e)}
      className={`${className} w-full max-w-screen-md`}
    >
      <div className="grid grid-cols-1 tracking-widest  my-6 gap-3">
        <BInput
          title="Name"
          name="pax_name"
          id="pax_name"
          formData={formData}
          setFormData={setFormData}
          type="text"
          required={true}
        />
        <BInput
          title="Contact No"
          name="pax_contactno"
          id="pax_contactno"
          formData={formData}
          setFormData={setFormData}
          type="text"
          required={true}
        />
        <BInput
          title="Email"
          name="pax_email"
          id="pax_email"
          formData={formData}
          setFormData={setFormData}
          type="text"
          required={true}
        />
        <div>
          <label className="text-sm tracking-widest text-gray-400" htmlFor="">
            Gender
          </label>
          <BRadio
            inline={true}
            radios={[
              { text: "Male", value: "Male" },
              { text: "Female", value: "Female" }
            ]}
            setValue={setFormData}
            formData={formData}
            name="pax_gender"
            id="pax_gender"
          />
        </div>
        <BInput
          title="Passenger ID"
          name="pax_ID"
          id="pax_ID"
          formData={formData}
          setFormData={setFormData}
          required={true}
          type="text"
        />
        <BTextAreaInput
          name="pax_remark"
          id="pax_remark"
          formData={formData}
          setFormData={setFormData}
          required={true}
          type="text"
          rows={3}
          title="Remark"
        />
      </div>
      <div className="flex w-full justify-between items-center content-center my-6">
        <button
          type="button"
          onClick={() => {
            onPreviousFn();
          }}
          className="flex px-4 py-2 border  justify-center items-center content-center space-x-2 border-gray-400 bg-gray-200 text-gray-600 hover:text-white hover:bg-primary"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-compact-left"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
            />
          </svg>
          <span className="text-sm tracking-widest">Previous</span>
        </button>
        <button className="flex px-4 py-2 border  justify-center items-center content-center space-x-2 border-gray-400 bg-gray-200 text-gray-600 hover:text-white hover:bg-primary">
          <span className="text-sm tracking-widest">Next</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-compact-right"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
            />
          </svg>
        </button>
      </div>
    </form>
  );
};
