import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { b2bConfig } from "../../../../config";
import { Instance } from "../../../../services";
import { format } from "date-fns";
interface bookingDataProps {
  arrvto: string;
  bookDateTime: string;
  bookingStatus: string;
  businessID: string;
  depdate: string;
  depfrom: string;
  deptime: string;
  nationality: string;
  operatorcode: string;
  pax_ID: string;
  pax_contactno: string;
  pax_email: string;
  pax_gender: string;
  pax_name: string;
  pax_no: string;
  pax_remark: string;
  perpaxprice: string;
  perpaxpricecurrency: string;
  seats: string;
}
export const BusBookingDetail: React.FC = () => {
  const [busData, setbusData] = useState<bookingDataProps>();
  const param: any = useParams();

  const bookingId = param.bookingId;
  const getBookingData = useCallback(
    () => {
      Instance({
        method: "GET",
        url: `${b2bConfig.apiPath
          .busServices}/bookingdetail?bookingid=${bookingId}`
      })
        .then(res => {
          if (res.data && res.data.Status && res.data.Status.Code === "01") {
            const bookingData: bookingDataProps = res.data.Data;
            setbusData(bookingData);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    [bookingId]
  );

  useEffect(
    () => {
      getBookingData();
    },
    [getBookingData]
  );

  return (
    <div className="w-full max-w-screen-lg px-2 py-2">
      {busData &&
        <div>
          <h3 className="flex flex-row space-x-2 justify-start font-bold items-center content-center text-gray-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-send text-warning"
              viewBox="0 0 16 16"
            >
              <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
            </svg>
            <span className="text-sm tracking-widest my-4 uppercase">
              Trip Information
            </span>
          </h3>
          <div className="w-full grid grid-cols-2 gap-3 shadow-sm text-xs max-w-screen-md text-gray-600 tracking-widest bg-white px-2 py-2  rounded-md">
            <span>Route</span>
            <span className="text-gray-800">
              {busData.depfrom} - {busData.arrvto}
            </span>
            <span>Operator</span>
            <span className="text-gray-800">
              {busData.operatorcode}
            </span>
            <span>Bus DateTime</span>
            <span className="text-gray-800">
              {busData.depdate} / {busData.deptime}
            </span>
            <span>Created At</span>
            <span className="text-gray-800">
              {format(new Date(busData.bookDateTime), "yyyy-MM-dd")}
            </span>

            <span>Seats</span>
            <span className="text-gray-800">
              {busData.seats}
            </span>
            <span>Price</span>
            <span className="text-gray-800">
              {busData.perpaxprice} X {busData.pax_no}{" "}
              <span className="text-warning">
                ( {parseInt(busData.perpaxprice) *
                  parseInt(busData.pax_no)}{" "}
                {busData.perpaxpricecurrency} )
              </span>
            </span>
            <span>Status</span>
            <span className="text-gray-800">
              {busData.bookingStatus}
            </span>
          </div>
          <h3 className="flex flex-row space-x-2 justify-start font-bold items-center content-center text-gray-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-person-fill"
              viewBox="0 0 16 16"
            >
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
            <span className="text-sm tracking-widest my-4 uppercase">
              Contact Information
            </span>
          </h3>
          <div className="w-full grid grid-cols-2 gap-3 shadow-sm text-xs max-w-screen-md text-gray-600 tracking-widest bg-white px-2 py-2  rounded-md">
            <span>Name</span>
            <span className="text-gray-800">
              {busData.pax_name}
            </span>
            <span>Gender</span>
            <span className="text-gray-800">
              {busData.pax_gender}
            </span>

            <span>Nationality</span>
            <span className="text-gray-800">
              {busData.nationality}
            </span>
            <span>ID</span>
            <span className="text-gray-800">
              {busData.pax_ID}
            </span>

            <span>Contact No.</span>
            <span className="text-gray-800">
              {busData.pax_contactno}
            </span>
            <span>Email</span>
            <span className="text-gray-800">
              {busData.pax_email}
            </span>
            <span>Business ID</span>
            <span className="text-gray-800">
              {busData.businessID}
            </span>
            <span>Remark</span>
            <span className="text-gray-800">
              {busData.pax_remark}
            </span>
          </div>
        </div>}
    </div>
  );
};
