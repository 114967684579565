import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BRangePicker } from '../../../../components/common/BPicker/Range'
import { BSelect } from '../../../../components/common/BSelect'
import { RootState } from '../../../../store'
import './index.css'
import { format } from 'date-fns'
import { Instance } from '../../../../services'
import { b2bConfig } from '../../../../config'
import { transactionDataProps } from '../../../../interfaces'
import {
  SelectTable,
  TableCell,
  TableRow
} from '../../../../components/common/SelectTable'

interface bzTransactionPageProps {
  className?: string
}
interface transactionFormProps {
  range: Date[]
  accountId: string
}
interface tFormDataProps {
  fromdate: string
  todate: string
  accountid: string
}

export const TransactionPage: React.FC<bzTransactionPageProps> = ({
  className
}) => {
  const { accountInfo } = useSelector((state: RootState) => state.common)
  const [formData, setformData] = useState<transactionFormProps>({
    range: [
      new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        new Date().getDate()
      ),
      new Date()
    ],
    accountId: accountInfo ? accountInfo[0]?.value : ''
  })

  const [transactionData, setTransactionData] = useState<
    transactionDataProps[]
  >([])

  useEffect(() => {
    if (formData.range[0] && formData.range[1] && formData.accountId) {
      const data: tFormDataProps = {
        accountid: formData.accountId,
        fromdate: format(formData.range[0], 'yyyy-MM-dd'),
        todate: format(formData.range[1], 'yyyy-MM-dd')
      }
      Instance({
        url: `${b2bConfig.apiPath.bzEntry}/user/transactions/`,
        method: 'POST',
        data: data
      })
        .then(res => {
          if (res.status === 200) {
            if (
              res.data &&
              res.data.Data &&
              res.data.Status &&
              res.data.Status.Code === '01'
            ) {
              const response: transactionDataProps[] = res.data.Data
              setTransactionData(response)
            }
          }
        })
        .catch(err => {
          console.log(err, ['transaction_fetch_error'])
        })
    }
  }, [formData])

  return (
    <div className={`${className} w-full`}>
      <div className='w-full'>
        <form className='grid grid-cols-4 gap-2'>
          <div className=''>
            <BSelect
              name='accountId'
              id='accountId'
              selectOptions={accountInfo}
              formData={formData}
              setFormData={setformData}
              title='Select your account'
            />
          </div>
          <div className=''>
            <BRangePicker
              name='range'
              className='w-full'
              formData={formData}
              maxDate={new Date()}
              range={formData.range}
              setRange={setformData}
              id='range'
              title='From ~ To'
            />
          </div>
        </form>
      </div>
      <SelectTable className='text-gray-800'>
        <thead className='text-xs font-normal uppercase border-t border-b text-gray-500 bg-gray-50 border-gray-200'>
          <TableRow>
            <TableCell isHeader={true}>
              <span>No</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Account</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Type</span>
            </TableCell>
            {/* <TableCell isHeader={true}>
              <span>Before</span>
            </TableCell> */}
            <TableCell isHeader={true}>
              <span>Amount</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Balance</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Description</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Created At</span>
            </TableCell>
            {/* <TableCell isHeader={true}>
              <span> Created By</span>
            </TableCell> */}
            <TableCell isHeader={true}>
              <span> Attach file</span>
            </TableCell>
          </TableRow>
        </thead>
        <tbody className="text-xs font-normal divide-y divide-gray-400">
          {
            transactionData.map((t,i)=>(
              <TableRow key={i}>
                  <TableCell>
                    <span> {i+1} </span>
                  </TableCell>
                  <TableCell>
                    <span> {t.account} </span>
                  </TableCell>
                  <TableCell>
                    <span className={`${t.transactionType==='CREDIT'?'text-warning':'text-danger'}`}> {t.transactionType} </span>
                  </TableCell>
                  {/* <TableCell>
                    <span> {t.beforeBalance} {t.currency} </span>
                  </TableCell> */}
                  <TableCell>
                    <span> {t.amount} {t.currency} </span>
                  </TableCell>
                  <TableCell>
                    <span> {t.balance} </span>
                  </TableCell>
                  <TableCell>
                    <span> {t.description} </span>
                  </TableCell>                  
                  <TableCell>
                    <span> {format(new Date(t.createDate),'yyyy-MM-dd')} </span>
                  </TableCell>
                  {/* <TableCell>
                    <span> {t.createdBy} </span>
                  </TableCell> */}
                  <TableCell>
                    {
                      t.refAttach && 
                      <a className='px-2 py-1 border border-gray-400 text-yellow-400' href={t.refAttach}  target="_blank" rel="noopener noreferrer">
                        download
                      </a>
                    }
                  </TableCell>
              </TableRow>
            ))
          }
        </tbody>
      </SelectTable>
    </div>
  )
}
