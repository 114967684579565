import React from "react";
import "./index.css";

export const NotFoundPage: React.FC = () => {
  return (
    <div>
      <h5>Page not found </h5>
    </div>
  );
};
