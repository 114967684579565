import React from "react";
import "./index.css";
interface binputPorps {
  className?: string;
  name: string;
  id?: string;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  type: React.HTMLInputTypeAttribute;
  title?: string;
  required?: boolean;
  disabled?: boolean;
  rows?: number;
}
export const BTextAreaInput: React.FC<binputPorps> = ({
  className,
  name,
  id,
  formData,
  setFormData,
  rows = 3,
  title,
  required = true,
  disabled = false
}) => {
  return (
    <div className={`${className} w-full`}>
      {title &&
        <label htmlFor={name} className="text-sm text-secondary ml-2">
          {title}
        </label>}
      <textarea
        className="form-textarea border border-gray-300 rounded-sm w-full px-2 text-xs tracking-widest"
        required={required}
        disabled={disabled}
        rows={rows}
        name={name}
        id={id}
        value={formData[name]}
        onChange={e => {
          setFormData({ ...formData, [e.target.name]: e.target.value });
        }}
      />
    </div>
  );
};
