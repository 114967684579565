import React from "react";
import { DashboardProfile } from "../ProfileDropDown";

interface dashboardHeaderProps {
  className?: string;
  setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sideBarOpen: boolean;
}
export const DashboardHeader: React.FC<dashboardHeaderProps> = ({
  setSideBarOpen,
  sideBarOpen
}) => {
  return (
    <header className="sticky top-0 z-30 bg-white shadow-md border-b border-gray-200">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex">
            {/* Hamburger button */}
            <button
              aria-controls="sidebar"
              onClick={() => setSideBarOpen(!sideBarOpen)}
              className=" text-gray-500 hover:text-gray-600"
            >
              <span className="sr-only ">Open Sidebar</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-list"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </button>
          </div>
          {/* Header: Right side */}
          <div className="flex items-center space-x-3">
            <button className="flex items-center justify-center w-8 h-8 ml-3 transition duration-150 rounded-full bg-gray-100 hover:bg-gray-200">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
            <DashboardProfile />
          </div>
        </div>
      </div>
    </header>
  );
};
