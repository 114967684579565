import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { userDataProps, userModuleProps } from '../../interfaces'

export interface authState {
  usrNm?: string
  usrId?: number
  bzNm?: string
  bzEmail?: string
  bzPhone?: string
  bzAddress?: string
  usrRole?: string
  bzCity?: string
  bzCountry?: string
  accessToken?: string
  refreshToken?: string
  isAuth: boolean
  loginDt:string
  loginTm:string
  bzId:string
  modules?:userModuleProps[]
}



export interface setCredentials{
    usrNm?:string
    accessToken?:string
    refreshToken?:string
    loginTm?:string
}

const initialState:authState={
    usrId:0,
    usrNm:'',
    bzNm:'',
    bzEmail:'',
    bzPhone:'',
    bzAddress:'',
    usrRole:'',
    bzCity:'',
    bzCountry:'',
    accessToken:'',
    refreshToken:'',
    bzId:'',
    isAuth:false,
    loginDt:new Date().toDateString(),
    loginTm:new Date().toTimeString(),
    modules:[]
    
}

export interface credentialProps{
    usrNm:string
    accessToken:string
    refreshToken:string
}

export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        setCredential:(state,action:PayloadAction<credentialProps>)=>{
            state.usrNm=action.payload.usrNm 
            state.accessToken=action.payload.accessToken 
            state.refreshToken=action.payload.refreshToken
            state.loginDt = new Date().toDateString()
            state.loginTm = new Date().toTimeString()
            state.isAuth=true
        },
        removeCredentials:(state,action:PayloadAction)=>{
            state = initialState
        },
        refreshToken:(state,action:PayloadAction<string>)=>{
            state.accessToken=action.payload
        },
        setProfile:(state,action:PayloadAction<userDataProps>)=>{
            state.accessToken=JSON.stringify(localStorage.getItem('accessToken'))
            state.refreshToken=JSON.stringify(localStorage.getItem('refreshToken'))
            state.modules=action.payload.userModule
            state.bzEmail=action.payload.businessInfo.businessEmail
            state.usrRole=action.payload.userRole
            state.usrId=action.payload.ID 
            state.usrNm = action.payload.userName 
            state.bzAddress = action.payload.businessInfo.businessAddress
            state.bzCity = action.payload.businessInfo.businessCity
            state.bzPhone =action.payload.businessInfo.businessPhone
            state.bzNm = action.payload.businessInfo.businessName
            state.bzCountry = action.payload.businessInfo.businessCountry
            state.bzId = action.payload.businessInfo.businessID
            state.isAuth=true 
            state.usrId=action.payload.ID
            state.loginDt=new Date().toDateString()
            state.loginTm=new Date().toLocaleTimeString()
        }
    }
})

export const {setCredential,removeCredentials,refreshToken,setProfile} = authSlice.actions

export default authSlice.reducer