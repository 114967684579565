import React from "react";
import "./index.css";
interface binputPorps {
  className?: string;
  name: string;
  id?: string;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  type: React.HTMLInputTypeAttribute;
  title?: string;
  required?: boolean;
  disabled?: boolean;
}
export const BInput: React.FC<binputPorps> = ({
  className,
  name,
  id,
  formData,
  setFormData,
  type,
  title,
  required = true,
  disabled = false
}) => {
  return (
    <div
      className={`${className} w-full relative text-gray-400 focus-within:text-gray-600 block`}
    >
      {title &&
        <label htmlFor={name} className="text-sm text-secondary ml-2">
          {title}
        </label>}
      <input
        className="form-input border border-gray-300 rounded-sm text-secondary w-full px-2 text-xs tracking-widest"
        required={required}
        disabled={disabled}
        type={type}
        name={name}
        id={id}
        value={formData[name]}
        onChange={e => {
          setFormData({ ...formData, [e.target.name]: e.target.value });
        }}
      />
    </div>
  );
};
