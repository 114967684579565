import React, { useCallback, useEffect } from "react";
import { each } from "lodash";
import AsyncSelect from "react-select/async";
import { useHistory, useParams } from "react-router-dom";
import serialize from "form-serialize";
import { Bbutton } from "../../../../components/common/Bbutton";

import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

import "./index.css";
import { useToasts } from "react-toast-notifications";
import { Instance } from "../../../../services";
import { b2bConfig } from "../../../../config";
// import { resultDataProps } from "../../../../components/flight/Result";
import moment from "moment";
import planeLanding from "../../../../assets/plane-landing.png";
import planeTakeoff from "../../../../assets/takeoff-the-plane.png";

interface onlineFlightBookProps {
  className?: string;
  previewData?: any | null;
}

export interface travellerProps {
  passengerName: passengerNameProps;
  gender: string;
  passengerType: string;
  passengerNationality: string;
  passengerDocuments: passengerDocumentsProps;
  dateOfBirth: Date;
}

interface passengerDocumentsProps {
  documentType: string;
  issuanceCountry: string;
  issuanceLocation: string;
  documentNumber: string;
  documentissuanceDate: Date;
  documentExpireDate: Date;
}

interface passengerNameProps {
  title: string;
  firstName: string;
  lastName: string;
}

// interface paymentProps {
//   paymentType: string;
//   totalAmount: string;
//   currencyCode: string;
//   paymentDesc: string;
// }

interface contactsProps {}

interface formDataProps {
  searchID?: string;
  fsc?: string;
  src?: string;
  travelers?: travellerProps[];
  contacts?: contactsProps;
  payment?: any;
  instantPurchase?: string;
}

interface aSearchOptions {
  label?: string;
  value?: any;
}

interface countrySearchProps {
  countryName?: string;
  iso2?: string;
  phoneCode?: string;
  continent?: string;
  timeZoneinCapital?: string;
  currency?: string;
}

export const ValidateFlight = async (fsc: any, src: any, searchID: any) => {
  const formData = new FormData();
  formData.append("fsc", fsc);
  formData.append("src", src);
  formData.append("searchID", searchID);

  try{
    const response = await Instance({
      url: `${b2bConfig.apiPath.filghtServices}/flight-revalidate`,
      method: "POST",
      data: formData
    });
    return response;

  }catch (error) {
      return {
        "data":null
      };

  }
  
};

export const OnlineFlightBook: React.FC<onlineFlightBookProps> = ({
  className
}) => {
  const queryString: any = useParams();
  const searchId = queryString.searchID;
  const fsc = queryString.fsc;
  const src = queryString.src;
  const adults = parseInt(queryString.adults);
  const children = parseInt(queryString.children);
  const infants = parseInt(queryString.infants);
  // const currencyCode = queryString.currencyCode
  // const [flightData, setFlightData] = useState<resultDataProps[]>([])
  const { addToast } = useToasts();
  const history = useHistory();
  const travelers: any = [];
  const [preview, setPreview] = React.useState(false);
  const [previewData, setPreviewData] = React.useState<any>({});
  const [countryList, setCountryList] = React.useState<any>([]);
  const [, setCountryOptions] = React.useState([]);
  const infoData = localStorage.getItem("flight-info");
  const flightInfo = infoData ? JSON.parse(infoData) : null;
  const { bzNm, usrNm, bzPhone, bzEmail } = useSelector(
    (state: RootState) => state.auth
  );

  const { goBack } = useHistory();

  const handleBack = () => {
    localStorage.removeItem("flight_info");
    setTimeout(() => {
      goBack();
    }, 100);
  };

  const getCountryList = useCallback(() => {
    Instance({
      url: `${b2bConfig.apiPath.filghtServices}/countrysearch`
    })
      .then((res) => {
        if (res.data.success) {
          const response = res.data.data;
          setCountryList(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getCountryList();
  }, [getCountryList]);

  const FetchData = (inputValue: string, callback: CallableFunction) => {
    Instance({
      url: `${b2bConfig.apiPath.filghtServices}/countrysearch?q=${inputValue}`,
      method: "GET"
    })
      .then((res) => {
        let tempData: aSearchOptions[] = [];
        if (res.data && res.data.data) {
          const responseData: countrySearchProps[] = res.data.data;
          each(responseData, (e) => {
            const parms: aSearchOptions = {
              label: e.countryName,
              value: e.iso2
            };
            tempData.push(parms);
          });
        }
        callback(tempData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  for (let index = 0; index < adults; index++) {
    travelers.push(
      <div key={index} className={index !== 0 ? "pt-3" : "pt-0"}>
        <h6 className="text-sm text-warning my-4 flex justify-start items-center content-center space-x-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-person-plus-fill"
            viewBox="0 0 16 16">
            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            <path
              fillRule="evenodd"
              d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
            />
          </svg>
          <span className="">{`Adult(${index + 1})`}</span>
        </h6>
        <div className="grid grid-cols-12 grid-flow-row gap-5">
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required">Title</label>
            <select
              className={`w-full px-2 py-2 border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm ${
                preview ? "hidden" : ""
              }`}
              id={`travelers[${index}][passengerName][title]`}
              name={`travelers[${index}][passengerName][title]`}
              required>
              <option value="">Select</option>
              <option value="Mr"> Mr </option>
              <option value="Ms"> Ms </option>
              <option value="Mrs"> Mrs </option>
            </select>
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview && previewData?.travelers[index]?.passengerName?.title}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required">
              First Name
            </label>
            <input
              type="text"
              id={`travelers[${index}][passengerName][firstName]`}
              name={`travelers[${index}][passengerName][firstName]`}
              required
              // placeholder="First Name"
              className={`gem_custom_input ${preview ? "hidden" : ""}`}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview &&
                previewData?.travelers[index]?.passengerName?.firstName}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required">Last Name</label>
            <input
              type="text"
              id={`travelers[${index}][passengerName][lastName]`}
              name={`travelers[${index}][passengerName][lastName]`}
              required
              // placeholder="Last Name"
              className={`gem_custom_input ${preview ? "hidden" : ""}`}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview &&
                previewData?.travelers[index]?.passengerName?.lastName}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required">
              Passenger nationality
            </label>
            {/* <select
              className=" w-full px-2 py-2 border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm "
              id={`travelers[${index}][passengerNationality]`}
              name={`travelers[${index}][passengerNationality]`}
              required>
              <option value=""></option>
              <option value="MY"> Local </option>
              <option value="FO"> Foreign </option>
            </select> */}

            <AsyncSelect
              id={`travelers[${index}][passengerNationality]`}
              name={`travelers[${index}][passengerNationality]`}
              placeholder="Search.."
              className={`w-full border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm ${
                preview ? "hidden" : ""
              }`}
              onChange={(e: any) => setCountryOptions(e)}
              loadOptions={(inputValue: string, callback: CallableFunction) => {
                FetchData(inputValue, callback);
              }}
            />

            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview &&
                countryList.filter(
                  (d: any) =>
                    d.iso2 ===
                    previewData?.travelers[index]?.passengerNationality
                )[0].countryName}
            </div>
          </div>

          <div className="col-span-12 md:col-span-4 ">
            <label
              className="text-sm  text-secondary required"
              htmlFor="gender">
              Gender
            </label>
            <div
              className={`flex justify-start items-center space-x-4 py-2 ${
                preview ? "hidden" : ""
              }`}>
              <div>
                <label className="text-sm ">
                  <input
                    type="radio"
                    value="MALE"
                    defaultChecked
                    id={`travelers[${index}][gender]`}
                    name={`travelers[${index}][gender]`}
                  />
                  &nbsp; Male
                </label>
              </div>
              <div>
                <label className="text-sm ">
                  <input
                    type="radio"
                    value="FEMALE"
                    id={`travelers[${index}][gender]`}
                    name={`travelers[${index}][gender]`}
                  />
                  &nbsp; Female
                </label>
              </div>
            </div>
            <input
              type="hidden"
              value="ADT"
              name={`travelers[${index}][passengerType]`}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview && previewData?.travelers[index]?.gender}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required" htmlFor="">
              Date Of Birth
            </label>
            <input
              type="date"
              required
              // max={new Date(
              //   (new Date().getFullYear() - 25,
              //   new Date().getMonth(),
              //   new Date().getDate())
              // ).toDateString()}
              defaultValue={moment("1900-01-01").format("YYYY-MM-DD")}
              // max={moment("2000-12-31").format("YYYY-MM-DD")}
              // placeholder="DateOfBirth"
              name={`travelers[${index}][dateOfBirth]`}
              className={`gem_custom_input ${preview ? "hidden" : ""}`}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview && previewData?.travelers[index]?.dateOfBirth}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary">
              Frequently fly airline
            </label>
            <input
              type="text"
              maxLength={2}
              id={`travelers[${index}][frequentflyairline]`}
              name={`travelers[${index}][frequentflyairline]`}
              className={`gem_custom_input ${preview ? "hidden" : ""}`}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview && previewData?.travelers[index]?.frequentflyairline}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary">
              Frequently fly code
            </label>{" "}
            <input
              type="text"
              id={`travelers[${index}][frequentflycode]`}
              name={`travelers[${index}][frequentflycode]`}
              // placeholder="Last Name"
              className={`gem_custom_input ${preview ? "hidden" : ""}`}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview && previewData?.travelers[index]?.frequentflycode}
            </div>
          </div>

          <div className="col-span-12 border border-dashed border-secondary p-4 rounded-md">
            <h6 className="flex justify-start mb-3 items-center content-center space-x-4 text-sm text-warning">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-file-text"
                viewBox="0 0 16 16">
                <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
              </svg>
              Passenger Documents
            </h6>
            <div className="grid grid-flow-row grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-4">
                <label className="text-sm text-secondary required">
                  Document type
                </label>
                <select
                  className={`w-full px-2 py-2 border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm ${
                    preview ? "hidden" : ""
                  }`}
                  id={`travelers[${index}][passengerDocuments][documentType]`}
                  name={`travelers[${index}][passengerDocuments][documentType]`}
                  required>
                  <option value="">Select</option>
                  <option value="PASSPORT"> Passport </option>
                </select>
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.documentType}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <label className="text-sm text-secondary required">
                  Issuance Country
                </label>
                {/* <select
                  className=" w-full px-2 py-2 border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm "
                  id={`travelers[${index}][passengerDocuments][issuanceCountry]`}
                  name={`travelers[${index}][passengerDocuments][issuanceCountry]`}
                  required>
                  <option value=""></option>
                  <option value="MY"> Local </option>
                  <option value="FO"> Foreign </option>
                </select> */}
                <AsyncSelect
                  id={`travelers[${index}][passengerDocuments][issuanceCountry]`}
                  name={`travelers[${index}][passengerDocuments][issuanceCountry]`}
                  placeholder="Search.."
                  className={`w-full border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm ${
                    preview ? "hidden" : ""
                  }`}
                  onChange={(e: any) => setCountryOptions(e)}
                  loadOptions={(
                    inputValue: string,
                    callback: CallableFunction
                  ) => {
                    FetchData(inputValue, callback);
                  }}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    countryList.filter(
                      (d: any) =>
                        d.iso2 ===
                        previewData?.travelers[index]?.passengerDocuments
                          ?.issuanceCountry
                    )[0].countryName}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <label className="text-sm text-secondary required">
                  Issuance Location
                </label>
                <input
                  type="text"
                  required
                  // placeholder="IssuanceLocation"
                  className={`gem_custom_input ${preview ? "hidden" : ""}`}
                  id={`travelers[${index}][passengerDocuments][issuanceLocation]`}
                  name={`travelers[${index}][passengerDocuments][issuanceLocation]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.issuanceLocation}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <label className="text-sm text-secondary required">
                  Document Number
                </label>
                <input
                  type="text"
                  required
                  // placeholder="DocumentNumber"
                  className={`gem_custom_input ${preview ? "hidden" : ""}`}
                  id={`travelers[${index}][passengerDocuments][documentNumber]`}
                  name={`travelers[${index}][passengerDocuments][documentNumber]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.documentNumber}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4 ">
                <label className="text-sm  text-secondary required" htmlFor="">
                  Issuance Date
                </label>
                <input
                  type="date"
                  required
                  max={moment().format("YYYY-MM-DD")}
                  // placeholder="documentissuanceDate"
                  className={`gem_custom_input w-full ${
                    preview ? "hidden" : ""
                  }`}
                  id={`travelers[${index}][passengerDocuments][documentissuanceDate]`}
                  name={`travelers[${index}][passengerDocuments][documentissuanceDate]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.documentissuanceDate}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4 ">
                <label className="text-sm  text-secondary required" htmlFor="">
                  Expire Date
                </label>
                <input
                  type="date"
                  required
                  // placeholder="documentExpireDate"
                  className={`gem_custom_input w-full ${
                    preview ? "hidden" : ""
                  }`}
                  id={`travelers[${index}][passengerDocuments][documentExpireDate]`}
                  name={`travelers[${index}][passengerDocuments][documentExpireDate]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.documentExpireDate}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  for (let index = adults; index < infants + adults; index++) {
    travelers.push(
      <div key={index} className="pt-3">
        <h6 className="text-sm text-warning my-4 flex justify-start items-center content-center space-x-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-person-plus-fill"
            viewBox="0 0 16 16">
            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            <path
              fillRule="evenodd"
              d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
            />
          </svg>
          <span className="">{`Infant(${index + 1})`}</span>
        </h6>
        <div className="grid grid-cols-12 grid-flow-row gap-5">
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required">Title</label>
            <select
              className={`w-full px-2 py-2 border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm ${
                preview ? "hidden" : ""
              }`}
              id={`travelers[${index}][passengerName][title]`}
              name={`travelers[${index}][passengerName][title]`}
              required>
              <option value="">Select</option>
              <option value="Mr"> Mr </option>
              <option value="Ms"> Ms </option>
              <option value="Mrs"> Mrs </option>
            </select>
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview && previewData?.travelers[index]?.passengerName?.title}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required">
              First Name
            </label>
            <input
              type="text"
              id={`travelers[${index}][passengerName][firstName]`}
              name={`travelers[${index}][passengerName][firstName]`}
              required
              // placeholder="FirstName"
              className={`gem_custom_input ${preview ? "hidden" : ""}`}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview &&
                previewData?.travelers[index]?.passengerName?.firstName}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required">Last Name</label>
            <input
              type="text"
              id={`travelers[${index}][passengerName][lastName]`}
              name={`travelers[${index}][passengerName][lastName]`}
              required
              // placeholder="LastName"
              className={`gem_custom_input ${preview ? "hidden" : ""}`}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview &&
                previewData?.travelers[index]?.passengerName?.lastName}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required">
              Passenger Nationality
            </label>
            {/* <select
              className=" w-full px-2 py-2 border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm "
              id={`travelers[${index}][passengerNationality]`}
              name={`travelers[${index}][passengerNationality]`}
              required>
              <option value=""></option>
              <option value="MY"> Local </option>
              <option value="FO"> Foreign </option>
            </select> */}
            <AsyncSelect
              id={`travelers[${index}][passengerNationality]`}
              name={`travelers[${index}][passengerNationality]`}
              placeholder="Search.."
              className={`w-full border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm ${
                preview ? "hidden" : ""
              }`}
              onChange={(e: any) => setCountryOptions(e)}
              loadOptions={(inputValue: string, callback: CallableFunction) => {
                FetchData(inputValue, callback);
              }}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview &&
                countryList.filter(
                  (d: any) =>
                    d.iso2 ===
                    previewData?.travelers[index]?.passengerNationality
                )[0].countryName}
            </div>
          </div>

          <div className="col-span-12 md:col-span-4 ">
            <label
              className="text-sm  text-secondary required"
              htmlFor="gender">
              Gender
            </label>
            <div
              className={`flex justify-start items-center content-center space-x-4 py-3 ${
                preview ? "hidden" : ""
              }`}>
              <div>
                <label className="text-sm">
                  <input
                    type="radio"
                    value="MALE"
                    defaultChecked
                    id={`travelers[${index}][gender]`}
                    name={`travelers[${index}][gender]`}
                  />
                  &nbsp; Male
                </label>
              </div>
              <div>
                <label className="text-sm">
                  <input
                    type="radio"
                    value="FEMALE"
                    id={`travelers[${index}][gender]`}
                    name={`travelers[${index}][gender]`}
                  />
                  &nbsp; Female
                </label>
              </div>
            </div>
            <input
              type="hidden"
              value="INT"
              name={`travelers[${index}][passengerType]`}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview && previewData?.travelers[index]?.gender}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required" htmlFor="">
              Date Of Birth
            </label>
            <input
              type="date"
              required
              // max={new Date(
              //   (new Date().getFullYear() - 25,
              //   new Date().getMonth(),
              //   new Date().getDate())
              // ).toDateString()}
              // placeholder="DateOfBirth"
              defaultValue={moment("1900-01-01").format("YYYY-MM-DD")}
              name={`travelers[${index}][dateOfBirth]`}
              className={`gem_custom_input ${preview ? "hidden" : ""}`}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview && previewData?.travelers[index]?.dateOfBirth}
            </div>
          </div>
          <div className="col-span-12 border border-dashed border-secondary p-4 rounded-md">
            <h6 className="flex justify-start mb-3 items-center content-center space-x-4 text-sm text-warning">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-file-text"
                viewBox="0 0 16 16">
                <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
              </svg>
              Passenger Documents
            </h6>
            <div className="grid grid-flow-row grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-4">
                <label className="text-sm text-secondary required">
                  Document Type
                </label>
                <select
                  className={`w-full px-2 py-2 border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm ${
                    preview ? "hidden" : ""
                  }`}
                  id={`travelers[${index}][passengerDocuments][documentType]`}
                  name={`travelers[${index}][passengerDocuments][documentType]`}
                  required>
                  <option value="">Select</option>
                  <option value="PASSPORT"> Passport </option>
                </select>
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.documentType}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <label className="text-sm text-secondary required">
                  Issuance Country
                </label>
                {/* <select
                  className=" w-full px-2 py-2 border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm "
                  id={`travelers[${index}][passengerDocuments][issuanceCountry]`}
                  name={`travelers[${index}][passengerDocuments][issuanceCountry]`}
                  required>
                  <option value=""></option>
                  <option value="MY"> Local </option>
                  <option value="FO"> Foreign </option>
                </select> */}
                <AsyncSelect
                  id={`travelers[${index}][passengerDocuments][issuanceCountry]`}
                  name={`travelers[${index}][passengerDocuments][issuanceCountry]`}
                  placeholder="Search.."
                  className={`w-full border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm ${
                    preview ? "hidden" : ""
                  }`}
                  onChange={(e: any) => setCountryOptions(e)}
                  loadOptions={(
                    inputValue: string,
                    callback: CallableFunction
                  ) => {
                    FetchData(inputValue, callback);
                  }}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.issuanceCountry}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <label className="text-sm text-secondary required">
                  Issuance Location
                </label>
                <input
                  type="text"
                  required
                  // placeholder="IssuanceLocation"
                  className={`gem_custom_input ${preview ? "hidden" : ""}`}
                  id={`travelers[${index}][passengerDocuments][issuanceLocation]`}
                  name={`travelers[${index}][passengerDocuments][issuanceLocation]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.issuanceLocation}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <label className="text-sm text-secondary required">
                  Document Number
                </label>
                <input
                  type="text"
                  required
                  // placeholder="DocumentNumber"
                  className={`gem_custom_input ${preview ? "hidden" : ""}`}
                  id={`travelers[${index}][passengerDocuments][documentNumber]`}
                  name={`travelers[${index}][passengerDocuments][documentNumber]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.documentNumber}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4 ">
                <label className="text-sm  text-secondary required" htmlFor="">
                  Issuance Date
                </label>
                <input
                  type="date"
                  required
                  // placeholder="documentissuanceDate"
                  className={`gem_custom_input w-full ${
                    preview ? "hidden" : ""
                  }`}
                  id={`travelers[${index}][passengerDocuments][documentissuanceDate]`}
                  name={`travelers[${index}][passengerDocuments][documentissuanceDate]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.documentissuanceDate}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4 ">
                <label className="text-sm  text-secondary required" htmlFor="">
                  Expire Date
                </label>
                <input
                  type="date"
                  required
                  // placeholder="documentExpireDate"
                  className={`gem_custom_input w-full ${
                    preview ? "hidden" : ""
                  }`}
                  id={`travelers[${index}][passengerDocuments][documentExpireDate]`}
                  name={`travelers[${index}][passengerDocuments][documentExpireDate]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.documentExpireDate}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  for (
    let index = adults + infants;
    index < children + infants + adults;
    index++
  ) {
    travelers.push(
      <div key={index} className="pt-3">
        <h6 className="text-sm text-warning my-4 flex justify-start items-center content-center space-x-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-person-plus-fill"
            viewBox="0 0 16 16">
            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            <path
              fillRule="evenodd"
              d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
            />
          </svg>
          <span className="">{`Child(${index + 1})`}</span>
        </h6>
        <div className="grid grid-cols-12 grid-flow-row gap-5">
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required">Title</label>
            <select
              className={`w-full px-2 py-2 border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm ${
                preview ? "hidden" : ""
              }`}
              id={`travelers[${index}][passengerName][title]`}
              name={`travelers[${index}][passengerName][title]`}
              required>
              <option value="">Select</option>
              <option value="Mr"> Mr </option>
              <option value="Ms"> Ms </option>
              <option value="Mrs"> Mrs </option>
            </select>
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview && previewData?.travelers[index]?.passengerName?.title}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required">
              First Name
            </label>
            <input
              type="text"
              id={`travelers[${index}][passengerName][firstName]`}
              name={`travelers[${index}][passengerName][firstName]`}
              required
              // placeholder="FirstName"
              className={`gem_custom_input ${preview ? "hidden" : ""}`}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview &&
                previewData?.travelers[index]?.passengerName?.firstName}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required">Last Name</label>
            <input
              type="text"
              id={`travelers[${index}][passengerName][lastName]`}
              name={`travelers[${index}][passengerName][lastName]`}
              required
              // placeholder="LastName"
              className={`gem_custom_input ${preview ? "hidden" : ""}`}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview &&
                previewData?.travelers[index]?.passengerName?.lastName}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required">
              Passenger Nationality
            </label>
            {/* <select
              className=" w-full px-2 py-2 border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm "
              id={`travelers[${index}][passengerNationality]`}
              name={`travelers[${index}][passengerNationality]`}
              required>
              <option value=""></option>
              <option value="MY"> Local </option>
              <option value="FO"> Foreign </option>
            </select> */}
            <AsyncSelect
              id={`travelers[${index}][passengerNationality]`}
              name={`travelers[${index}][passengerNationality]`}
              placeholder="Search.."
              className={`w-full border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm ${
                preview ? "hidden" : ""
              }`}
              onChange={(e: any) => setCountryOptions(e)}
              loadOptions={(inputValue: string, callback: CallableFunction) => {
                FetchData(inputValue, callback);
              }}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview &&
                countryList.filter(
                  (d: any) =>
                    d.iso2 ===
                    previewData?.travelers[index]?.passengerNationality
                )[0].countryName}
            </div>
          </div>

          <div className="col-span-12 md:col-span-4 ">
            <label
              className="text-sm  text-secondary required"
              htmlFor="gender">
              Gender
            </label>
            <div
              className={`flex justify-start items-center content-center space-x-4 py-3 ${
                preview ? "hidden" : ""
              }`}>
              <div>
                <label className="text-sm">
                  <input
                    type="radio"
                    value="MALE"
                    defaultChecked
                    id={`travelers[${index}][gender]`}
                    name={`travelers[${index}][gender]`}
                  />
                  &nbsp; Male
                </label>
              </div>
              <div>
                <label className="text-sm">
                  <input
                    type="radio"
                    value="FEMALE"
                    id={`travelers[${index}][gender]`}
                    name={`travelers[${index}][gender]`}
                  />
                  &nbsp; Female
                </label>
              </div>
              <input
                type="hidden"
                value="CHT"
                name={`travelers[${index}][passengerType]`}
              />
            </div>
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview && previewData?.travelers[index]?.gender}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <label className="text-sm text-secondary required" htmlFor="">
              Date Of Birth
            </label>
            <input
              type="date"
              required
              // max={new Date(
              //   (new Date().getFullYear() - 25,
              //   new Date().getMonth(),
              //   new Date().getDate())
              // ).toDateString()}
              // placeholder="DateOfBirth"
              defaultValue={moment("1900-01-01").format("YYYY-MM-DD")}
              name={`travelers[${index}][dateOfBirth]`}
              className={`gem_custom_input ${preview ? "hidden" : ""}`}
            />
            <div className={`text-md ${preview ? "" : "hidden"}`}>
              {preview && previewData?.travelers[index]?.dateOfBirth}
            </div>
          </div>
          <div className="col-span-12 border border-dashed border-secondary p-4 rounded-md">
            <h6 className="flex justify-start mb-3 items-center content-center space-x-4 text-sm text-warning">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-file-text"
                viewBox="0 0 16 16">
                <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
              </svg>
              Passenger Documents
            </h6>
            <div className="grid grid-flow-row grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-4">
                <label className="text-sm text-secondary required">
                  Document Type
                </label>
                <select
                  className={`" w-full px-2 py-2 border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm ${
                    preview ? "hidden" : ""
                  }`}
                  id={`travelers[${index}][passengerDocuments][documentType]`}
                  name={`travelers[${index}][passengerDocuments][documentType]`}
                  required>
                  <option value="">Select</option>
                  <option value="PASSPORT"> Passport </option>
                </select>
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.documentType}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <label className="text-sm text-secondary required">
                  Issuance Country
                </label>
                {/* <select
                  className=" w-full px-2 py-2 border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm "
                  id={`travelers[${index}][passengerDocuments][issuanceCountry]`}
                  name={`travelers[${index}][passengerDocuments][issuanceCountry]`}
                  required>
                  <option value=""></option>
                  <option value="MY"> Local </option>
                  <option value="FO"> Foreign </option>
                </select> */}
                <AsyncSelect
                  id={`travelers[${index}][passengerDocuments][issuanceCountry]`}
                  name={`travelers[${index}][passengerDocuments][issuanceCountry]`}
                  placeholder="Search.."
                  className={`w-full border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm ${
                    preview ? "hidden" : ""
                  }`}
                  onChange={(e: any) => setCountryOptions(e)}
                  loadOptions={(
                    inputValue: string,
                    callback: CallableFunction
                  ) => {
                    FetchData(inputValue, callback);
                  }}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    countryList.filter(
                      (d: any) =>
                        d.iso2 ===
                        previewData?.travelers[index]?.passengerDocuments
                          ?.issuanceCountry
                    )[0].countryName}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <label className="text-sm text-secondary required">
                  Issuance Location
                </label>
                <input
                  type="text"
                  required
                  // placeholder="IssuanceLocation"
                  className={`gem_custom_input ${preview ? "hidden" : ""}`}
                  id={`travelers[${index}][passengerDocuments][issuanceLocation]`}
                  name={`travelers[${index}][passengerDocuments][issuanceLocation]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.issuanceLocation}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <label className="text-sm text-secondary required">
                  Document Number
                </label>
                <input
                  type="text"
                  required
                  // placeholder="DocumentNumber"
                  className={`gem_custom_input ${preview ? "hidden" : ""}`}
                  id={`travelers[${index}][passengerDocuments][documentNumber]`}
                  name={`travelers[${index}][passengerDocuments][documentNumber]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.documentNumber}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4 ">
                <label className="text-sm  text-secondary required" htmlFor="">
                  Issuance Date
                </label>
                <input
                  type="date"
                  required
                  // placeholder="documentissuanceDate"
                  className={`gem_custom_input w-full ${
                    preview ? "hidden" : ""
                  }`}
                  id={`travelers[${index}][passengerDocuments][documentissuanceDate]`}
                  name={`travelers[${index}][passengerDocuments][documentissuanceDate]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.documentissuanceDate}
                </div>
              </div>
              <div className="col-span-12 md:col-span-4 ">
                <label className="text-sm  text-secondary required" htmlFor="">
                  Expire Date
                </label>
                <input
                  type="date"
                  required
                  // placeholder="documentExpireDate"
                  className={`gem_custom_input w-full ${
                    preview ? "hidden" : ""
                  }`}
                  id={`travelers[${index}][passengerDocuments][documentExpireDate]`}
                  name={`travelers[${index}][passengerDocuments][documentExpireDate]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview &&
                    previewData?.travelers[index]?.passengerDocuments
                      ?.documentExpireDate}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const OnSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!preview) {
      const form: any = e.target;
      let obj: formDataProps = serialize(form, { hash: true });
      obj.instantPurchase = flightInfo.instantPurchase;
      setTimeout(() => {
        setPreviewData(obj);
        setPreview(true);
      }, 300);
    } else {
      const result = await ValidateFlight(fsc, src, searchId);
      if (result.data.success) {
        if (result.data.data) {
          const form: any = e.target;
          let obj: formDataProps = serialize(form, { hash: true });
          obj.instantPurchase = flightInfo.instantPurchase;

          // obj.payment.totalAmount = flightInfo.pricing.total.toString();
          // obj.payment.currencyCode = flightInfo.pricing.currencyCode;

          // if (result.data && result.data.data && result.data.data.length > 0) {
          //   const flightResult: resultDataProps[] = result.data.data;
          //   const totalAmount = flightResult[0].pricing?.grandtotal;
          //   const currencyCode = flightResult[0].pricing?.currency;
          //   if (
          //     obj.payment &&
          //     obj.payment.totalAmount &&
          //     totalAmount &&
          //     currencyCode
          //   ) {
          //     obj.payment.totalAmount = totalAmount.toString();
          //     obj.payment.currencyCode = currencyCode;
          //   }
          // }

          try {
            Instance({
              url: `${b2bConfig.apiPath.filghtServices}/book-flight`,
              method: "POST",
              data: obj
            })
              .then((res) => {
                if (res.data.success) {
                  addToast("Flight booking successful", {
                    appearance: "success",
                    autoDismiss: true
                  });
                  history.push("/dashboard/flight/online-bookings");
                } else if (res.data && res.data.error) {
                  addToast(`Error FB01 : ${JSON.stringify(res.data.error)}`, {
                    appearance: "error",
                    autoDismiss: false
                  });
                }
              })
              .catch((err) => {
                addToast(`Error FB02 : ${JSON.stringify(err)}`, {
                  appearance: "error",
                  autoDismiss: false
                });
              });
          } catch (error) {
            addToast(`Error FB03 : ${JSON.stringify(error)}`, {
              appearance: "error",
              autoDismiss: false
            });
          }
        } else {
          if (result.data && result.data.error) {
            addToast(`Fare Source has been changed(Error Code: FSC 003). Please Rebook again.`, {
              appearance: "warning",
              autoDismiss: false
            });
          } else {
            addToast("Fare Source has been changed(Error Code: FSC 001). Please Rebook again. ", {
              appearance: "warning",
              autoDismiss: false
            });
          }
        }
      }else{
        // to show error alert
        addToast(`Fare Source has been changed(Error Code: FSC 002). Please Rebook again.`, {
          appearance: "error",
          autoDismiss: false
        });

      }
    }
  };

  return (
    <div
      className={`${className} w-full grid grid-flow-row grid-cols-12 gap-3`}>
      {!preview && (
        <div className="col-span-12">
          <div className="flex">
            <button
              onClick={handleBack}
              className="px-2 py-2 border border-gray-600 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-90deg-left"
                viewBox="0 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <div className="col-span-12 md:col-span-9 order-2 md:order-1">
        <form
          id="flight_booking_form"
          onSubmit={(e) => {
            OnSubmitForm(e);
          }}
          className="flex flex-col space-y-6 ">
          <input type="hidden" name="searchID" id="searchID" value={searchId} />
          <input type="hidden" name="fsc" id="fsc" value={fsc} />
          <input type="hidden" name="src" id="src" value={src} />
          <input
            type="hidden"
            name="payment[totalAmount]"
            value={flightInfo.pricing.total}
          />
          <input
            type="hidden"
            name="payment[currencyCode]"
            value={flightInfo.pricing.currency}
          />
          <div className="w-full bg-white px-4 py-4 block max-w-screen-lg  rounded-md mb-3   ">
            <h6 className="text-primary text-lg flex justify-start items-center content-center space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-people-fill"
                viewBox="0 0 16 16">
                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                <path
                  fillRule="evenodd"
                  d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                />
                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
              </svg>
              <span>Traveller Information</span>
            </h6>
            <hr className="my-4" />
            {travelers}
          </div>
          <div className="w-full bg-white px-4 py-4 block max-w-screen-lg  rounded-md mb-3   ">
            <h6 className="text-primary text-lg flex justify-start items-center content-center space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-person-video3"
                viewBox="0 0 16 16">
                <path d="M14 9.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-6 5.7c0 .8.8.8.8.8h6.4s.8 0 .8-.8-.8-3.2-4-3.2-4 2.4-4 3.2Z" />
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h5.243c.122-.326.295-.668.526-1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7.81c.353.23.656.496.91.783.059-.187.09-.386.09-.593V4a2 2 0 0 0-2-2H2Z" />
              </svg>
              <span>Contact Information</span>
            </h6>
            <hr className="my-4" />
            <div className="grid grid-flow-row grid-cols-12 gap-4">
              <input
                type="hidden"
                value="2"
                name={`contacts[userID]`}
                id={`contacts[userID]`}
              />
              <div className="col-span-12 md:col-span-6">
                <label className="text-sm text-secondary required">
                  First Name
                </label>
                <input
                  type="text"
                  required
                  className={`gem_custom_input ${preview ? "hidden" : ""}`}
                  // placeholder="FirstName"
                  defaultValue={bzNm}
                  name={`contacts[contactName][firstName]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview && previewData?.contacts?.contactName?.firstName}
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <label className="text-sm text-secondary required">
                  Last Name
                </label>
                <input
                  type="text"
                  required
                  className={`gem_custom_input ${preview ? "hidden" : ""}`}
                  // placeholder="LastName"
                  defaultValue={usrNm}
                  name={`contacts[contactName][lastName]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview && previewData?.contacts?.contactName?.lastName}
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="">
                  <label className="text-sm text-secondary required">
                    Contact Number
                  </label>
                  <div className={`flex ${preview ? "hidden" : ""}`}>
                    <div className="flex-none w-32">
                      <select
                        className="px-2 py-2 border-secondary border bg-white appearance-none focus:outline-none hover:border-primary text-sm"
                        name="contacts[phone][countryCallingCode]"
                        required
                        id="contacts[phone][countryCallingCode]">
                        <option value="">Country Code</option>
                        <option value="95" selected>
                          +95
                        </option>
                        <option value="63"> +63 </option>
                        <option value="98"> +98 </option>
                      </select>
                    </div>
                    <div className="flex-initial w-full">
                      <input
                        type="text"
                        required
                        className={`gem_custom_input ${
                          preview ? "hidden" : ""
                        }`}
                        placeholder="contact Number"
                        defaultValue={bzPhone}
                        name={`contacts[phone][contactNumber]`}
                      />
                    </div>
                  </div>
                  <div className={`text-md ${preview ? "" : "hidden"}`}>{`${
                    preview && previewData?.contacts?.phone?.countryCallingCode
                  } ${
                    preview && previewData?.contacts?.phone?.contactNumber
                  }`}</div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <label className="text-sm text-secondary required">
                  Email address
                </label>
                <input
                  type="email"
                  required
                  className={`gem_custom_input ${preview ? "hidden" : ""}`}
                  // placeholder="Email Address"
                  defaultValue={bzEmail}
                  name={`contacts[emailAddress]`}
                />
                <div className={`text-md ${preview ? "" : "hidden"}`}>
                  {preview && previewData?.contacts?.emailAddress}
                </div>
              </div>
            </div>
          </div>
          {/* <div className='w-full bg-white px-4 py-4 block max-w-screen-lg  rounded-md mb-3'>
            <h6 className='text-primary text-lg flex justify-start items-center content-center space-x-4'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-credit-card-2-back-fill'
                viewBox='0 0 16 16'
              >
                <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z' />
              </svg>
              <span>Payment Information</span>
            </h6>
            <hr className='my-4' />
            <div className='grid grid-flow-row grid-cols-12 gap-4'>
              <div className='col-span-12 md:col-span-12'>
                <select
                  className='px-2 py-2 w-full border-secondary border text-secondary bg-white appearance-none focus:outline-none hover:border-primary text-sm '
                  name='payment[paymentType]'
                  required
                  id='payment[paymentType]'
                >
                  <option value=''>Please select payment method</option>
                  <option value='cardpayment'>Card Payment</option>
                  <option value='kpay'>KBZ Pay</option>
                  <option value='wavepay'>Wave Pay</option>
                </select>
              </div>
              <div className='col-span-12 md:col-span-12'>
                <textarea
                  name='payment[paymentDesc]'
                  className='gem_custom_input'
                  rows={4}
                  placeholder='Payment Description'
                  id='payment[paymentDesc]'
                />
              </div>
              <input
                type='hidden'
                name='payment[totalAmount]'
                id=''
                value='3'
              />
              <input
                type='hidden'
                name='payment[currencyCode]'
                id=''
                value={currencyCode}
              />
            </div>
          </div> */}
          <div className="flex ">
            {!preview ? (
              <Bbutton title="Preview" type="submit" />
            ) : (
              <>
                <div className="mr-4 flex flex-col py-2">
                  <button
                    onClick={() => setPreview(!preview)}
                    type="button"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium  text-white  hover:bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-secondary">
                    Back
                  </button>
                </div>
                <Bbutton title="Book Now" type="submit" />
              </>
            )}
          </div>
        </form>
      </div>

      <div className="col-span-12 md:col-span-3 mt-6 order-1 md:order-2">
        {flightInfo && (
          <div className="w-full bg-white px-4 py-4 block max-w-screen-lg  rounded-md mb-3">
            <h5 className="text-primary text-lg">{`Itinerary & Price`}</h5>
            <hr className="my-4" />

            {flightInfo.itineraries.length &&
              flightInfo.itineraries[0].segments.map((f: any, idx: any) => (
                <div
                  key={idx}
                  className={`flex flex-col space-2 border-dashed  ${
                    idx !== 0 ? "py-5 border-t" : "pb-5"
                  }`}>
                  <div className="flex justify-between items-baseline">
                    <div>
                      <img className="w-5 h-5" src={planeTakeoff} alt="" />
                      <div>
                        <h5 className="text-lg">{f.departureAirportCode}</h5>
                        <span className="text-xs">
                          {moment(f.departureDateTime).format("ll")}
                        </span>
                      </div>
                      <span className="text-xs">
                        {moment(f.departureDateTime).format("HH:mm")}
                      </span>
                    </div>

                    <div className="flex flex-col justify-center items-center self-center">
                      <span className="text-sm text-primary">
                        {f.carrierCode || "N/A"}
                      </span>
                      <span className="text-md text-primary">
                        {f.flightNo || "N/A"}
                      </span>
                    </div>

                    <div className="text-left">
                      <span className="">
                        <img className="w-5 h-5" src={planeLanding} alt="" />
                      </span>
                      <div>
                        <h5 className="text-lg">{f.arrivalAirportCode}</h5>
                        <span className="text-xs">
                          {moment(f.arrivalDateTime).format("ll")}
                        </span>
                      </div>
                      <span className="text-xs">
                        {moment(f.arrivalDateTime).format("HH:mm")}
                      </span>
                    </div>
                  </div>
                </div>
              ))}

            {flightInfo.itineraries.length > 1 &&
              flightInfo.itineraries[1].segments.map((f: any, idx: any) => (
                <div
                  key={idx}
                  className={`flex flex-col space-2 border-dashed  ${
                    idx === 0 ? "py-5 border-t" : "py-5 border-b"
                  }`}>
                  <div className="flex justify-between items-baseline">
                    <div>
                      <img className="w-5 h-5" src={planeTakeoff} alt="" />
                      <div>
                        <h5 className="text-lg">{f.departureAirportCode}</h5>
                        <span className="text-xs">
                          {moment(f.departureDateTime).format("ll")}
                        </span>
                      </div>
                      <span className="text-xs">
                        {moment(f.departureDateTime).format("HH:mm")}
                      </span>
                    </div>

                    <div className="flex flex-col justify-center items-center self-center">
                      <span className="text-sm text-primary">
                        {f.carrierCode || "N/A"}
                      </span>
                      <span className="text-md text-primary">
                        {f.flightNo || "N/A"}
                      </span>
                    </div>

                    <div className="text-left">
                      <span className="">
                        <img className="w-5 h-5" src={planeLanding} alt="" />
                      </span>
                      <div>
                        <h5 className="text-lg">{f.arrivalAirportCode}</h5>
                        <span className="text-xs">
                          {moment(f.arrivalDateTime).format("ll")}
                        </span>
                      </div>
                      <span className="text-xs">
                        {moment(f.arrivalDateTime).format("HH:mm")}
                      </span>
                    </div>
                  </div>
                </div>
              ))}

            {flightInfo.pricing && (
              <>
                <div className="flex flex-col space-y-2 border-dashed border-b border-t py-5">
                  <div className="flex justify-between items-baseline text-sm">
                    <span>Subtotal</span>
                    <span className="text-right">{`${flightInfo.pricing.base} ${flightInfo.pricing.currency}`}</span>
                  </div>

                  <div className="flex justify-between items-baseline text-sm">
                    <span>Tax</span>
                    <span className="text-right">{`${flightInfo.pricing.tax} ${flightInfo.pricing.currency}`}</span>
                  </div>
                </div>

                <div className="flex flex-col space-y-2 justify-center items-center text-xl pt-4 font-semibold">
                  <span>Total</span>
                  <span>{`${flightInfo.pricing.total} ${flightInfo.pricing.currency}`}</span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
