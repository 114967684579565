import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import {
  DashbaordInnerWrapper,
  tabMenuProps
} from "../../../templates/DashboardInnerWrapper";
import { DataPackPage } from "./DataPacks";
import { MobileTopupPage } from "./MobileTopup";
import { MobileTransactionsPage } from "./Transactions";

export const MobileServicePage: React.FC = () => {
  const { path } = useRouteMatch();
  const tabMenu: tabMenuProps[] = [
    {
      name: "Mobile Topup",
      path: `${path}`
    },
    {
      name: "DataPacks",
      path: `${path}/data-packs`
    },

    {
      name: "Transactions",
      path: `${path}/transactions`
    }
  ];
  return (
    <DashbaordInnerWrapper tabMenus={tabMenu}>
      <Switch>
        <Route exact path={`${path}`} component={MobileTopupPage} />
        <Route exact path={`${path}/data-packs`} component={DataPackPage} />
        <Route
          exact
          path={`${path}/transactions`}
          component={MobileTransactionsPage}
        />
      </Switch>
    </DashbaordInnerWrapper>
  );
};
