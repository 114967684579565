import React from "react";
import "./index.css";

export const UnauthenticatedPage: React.FC = () => {
  return (
    <div>
      <h4>401 page</h4>
    </div>
  );
};
