import React, { useCallback, useEffect, useState } from "react";
import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../../components/common/SelectTable";
import { Instance } from "../../../../services";
import { b2bConfig } from "../../../../config";
import { DatabaseIcon } from "@heroicons/react/solid";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";

import "./index.css";

interface BookingsPageProps {
  className?: string;
  data: [];
  filterData: [];
  route: string;
  startDate: Date;
  endDate: Date;
  createDate: Date;
  paxNo: string;
  status: string;
  active: string;
  loading: boolean;
  bookingID: string;
}

const tagList = [
  {
    label: "all",
    value: ""
  },
  {
    label: "pending",
    value: "0"
  },
  {
    label: "cancel",
    value: "2"
  },
  {
    label: "confirm",
    value: "1"
  }
];

const useQuery = () =>{
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const InsuranceBookings: React.FC<BookingsPageProps> = () => {
  const [data, setData] = useState<BookingsPageProps[]>([]);
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const query = useQuery();

  const getData = useCallback(
    (status: any) => {
      document.body.classList.add("loading-indicator");
      const accessToken = sessionStorage.getItem("accessToken");

      Instance({
        url: `${b2bConfig.apiPath.insuranceServices}/insurancebooking?status=${status.value}`,
        headers: {
          Authorization: `JWT ${accessToken}`
        }
      })
        .then((res) => {
          if (res.status === 200) {
            const response = res.data.data;
            setData(response);
            setActive(status.label);
            setLoading(false);
            status.value !== ""
              ? history.push(
                  `${history.location.pathname}?status=${status.value}`
                )
              : history.push(`${history.location.pathname}`);
          }
        })
        .catch((err) => {
          console.log(err, ["transaction_fetch_error"]);
        });
    },
    [history]
  );

  useEffect(() => {
    const status = query.get("status");
    const path = tagList.filter((t) => t.value === status && t);
    getData({
      label: path.length ? path[0].label : "all",
      value: path.length ? path[0].value : ""
    });
  }, [getData, query]);

  const handleClick = (id: any) => {
    history.push(`/dashboard/insurance/booking-detail/${id}`);
  };

  return (
    <React.Fragment>
      <h4>Insurance Bookings</h4>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
        <ul className="flex flex-wrap -mb-px">
          {tagList.map((d, i) => (
            <li className="mr-2" key={i}>
              <button
                className={`${
                  active === d.label
                    ? "border-gray-800 text-gray-800 rounded-t-lg border-b-2"
                    : "border-gray-500 text-gray-500 hover:text-gray-600 hover:border-gray-500"
                } inline-block p-4 capitalize`}
                onClick={() => getData(d)}>
                {d.label}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {!loading && data.length > 0 && (
        <div className="bg-white rounded-md shadow-sm mt-4 p-2">
          <SelectTable className="text-gray-800 md:table-fixed">
            <thead className="text-xs font-normal uppercase border-b text-gray-500 bg-gray-50 border-gray-200">
              <TableRow>
                <TableCell isHeader={true}>
                  <span>No</span>
                </TableCell>
                <TableCell isHeader={true}>
                  <span>Route</span>
                </TableCell>
                <TableCell isHeader={true}>
                  <span>Start Date</span>
                </TableCell>
                <TableCell isHeader={true}>
                  <span>End Date</span>
                </TableCell>
                <TableCell isHeader={true}>
                  <span>Create Date</span>
                </TableCell>
                <TableCell isHeader={true}>
                  <span>Pax No</span>
                </TableCell>
                <TableCell isHeader={true}>
                  <span>Status</span>
                </TableCell>
              </TableRow>
            </thead>
            <tbody className="text-xs font-normal divide-y divide-gray-100">
              {data.map((m, i) => (
                <tr
                  key={i}
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => handleClick(m.bookingID)}>
                  <TableCell>
                    <span className="px-2">{i + 1}</span>
                  </TableCell>
                  <TableCell>
                    <span>{m.route}</span>
                  </TableCell>
                  <TableCell>
                    <span>{moment(m.startDate).format("YYYY-MM-DD")}</span>
                  </TableCell>
                  <TableCell>
                    <span>{moment(m.endDate).format("YYYY-MM-DD")}</span>
                  </TableCell>
                  <TableCell>
                    <span>{moment(m.createDate).format("YYYY-MM-DD")}</span>
                  </TableCell>
                  <TableCell className="text-center">
                    <span>{m.paxNo}</span>
                  </TableCell>
                  <TableCell>
                    <span>{m.status}</span>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </SelectTable>
        </div>
      )}

      {!loading && !data.length && (
        <div className="bg-white w-full p-6 rounded-md shadow-sm mt-4">
          <div className="flex justify-center items-center h-12">
            <DatabaseIcon className="w-4 h-4 mr-2" />
            <span className="text-sm">No data for now.</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
