import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { BRadio } from "../../../../components/common/BRadio";
import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../../components/common/SelectTable";
import { b2bConfig } from "../../../../config";
import { Instance } from "../../../../services";
import { RootState } from "../../../../store";
import "./index.css";
interface offlineBookingProps {
  className?: string;
}
interface filterFormProps {
  option: string;
}

interface bookingsProps {
  agentPrice?: string;
  airline?: string;
  bookDate?: string;
  bookingStatus?: string;
  businessID?: string;
  currency?: string;
  flightTicket?: string;
  id?: number;
  issueFee?: string;
  pax_name?: string;
  pax_no?: string;
  paymentStatus?: string;
  pnr?: string;
  remark?: string;
  totalPrice?: string;
  userID?: string;
}

export const OfflineBookingsPage: React.FC<offlineBookingProps> = ({
  className
}) => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const [formData, setformData] = useState<filterFormProps>({
    option: "all"
  });
  const [bookings, setBookings] = useState<bookingsProps[]>([]);
  useEffect(
    () => {
      if (isAuth) {
        Instance({
          url: `${b2bConfig.apiPath
            .filghtServices}/getofflinebooking/${formData.option}`,
          method: "GET"
        })
          .then(res => {
            if (res.status === 200) {
              if (
                res.data &&
                res.data.Data &&
                res.data.Status &&
                res.data.Status.Code === "01"
              ) {
                const response: bookingsProps[] = res.data.Data;
                setBookings(response);
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    [isAuth, formData]
  );
  return (
    <div className={`${className} w-full`}>
      <form className="flex justify-start items-center content-center px-2 py-2 max-w-screen-sm w-full rounded-md  bg-white mb-4">
        <BRadio
          className="space-x-5"
          radios={[
            { text: "All", value: "all" },
            { text: "Pending", value: "pending" },
            { text: "Ticketed", value: "ticketed" },
            { text: "Cancel", value: "cancel" }
          ]}
          formData={formData}
          setValue={setformData}
          inline={true}
          name="option"
          id="option"
        />
      </form>

      <SelectTable className="bg-white px-2 py-2 rounded-md">
        <thead className="text-xs font-semibold uppercase border-t border-b text-gray-500 bg-gray-50 border-gray-200">
          <TableRow>
            <TableCell isHeader={true}>
              <span> ID </span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Airline</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> PNR </span>
            </TableCell>

            <TableCell isHeader={true}>
              <span> Name </span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Total Price </span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Net Price </span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Remark </span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Status </span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Ticket </span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Booked By </span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Date </span>
            </TableCell>
          </TableRow>
        </thead>
        {bookings.length > 0
          ? <tbody className="text-xs font-normal divide-y divide-gray-400">
              {bookings.map((b, i) =>
                <TableRow key={i}>
                  <TableCell>
                    <span>
                      {b.id}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {b.airline}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {b.pnr}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {b.pax_name}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="text-right">
                      {b.totalPrice ? b.totalPrice + b.currency : "-"}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="text-right">
                      {b.agentPrice ? b.agentPrice + b.currency : "-"}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {b.remark}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span>
                      {b.bookingStatus}
                    </span>
                  </TableCell>
                  <TableCell>
                    {b.flightTicket
                      ? <a className="text-center border px-3 py-2  rounded-md  border-green-400 bg-green-400 hover:bg-green-600 text-white" href={b.flightTicket}>Download</a>
                      : <span>-</span>}
                  </TableCell>
                  <TableCell>
                    <span>
                      {b.businessID}
                    </span>
                  </TableCell>
                  <TableCell>
                    {b.bookDate &&
                      <span>
                        {" "}{format(new Date(b.bookDate), "yyyy-MM-dd")}{" "}
                      </span>}
                  </TableCell>
                </TableRow>
              )}
            </tbody>
          : <div className="flex justify-center items-center content-center px-2 py-10 w-full">
              <div className="flex flex-col justify-center items-center content-center px-2  w-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-folder2-open text-yellow-300"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z" />
                </svg>
                <p className="text-gray-400 text-xs uppercase">No data!</p>
              </div>
            </div>}
      </SelectTable>
    </div>
  );
};
