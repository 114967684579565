import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import {
  SelectTable,
  TableCell,
  TableRow
} from "../../../../components/common/SelectTable";
import { b2bConfig } from "../../../../config";
import { Instance } from "../../../../services";
import {
  Document,
  Page,
  Text,
  PDFDownloadLink,
  View
} from "@react-pdf/renderer";

import "./index.css";

interface busBookingsProps {
  className?: string;
}

interface busBookingProps {
  ID: number;
  arrvto: string;
  bookingDate: string;
  businessID: string;
  depdate: string;
  depfrom: string;
  deptime: string;
  fareSource: string;
  nationality: string;
  operatorcode: string;
  pax_ID: string;
  pax_email: string;
  pax_gender: string;
  pax_name: string;
  pax_no: string;
  pax_remark: string;
  perpaxprice: string;
  seats: string;
  status: string;
  userID: string;
}

export const BusBookingPage: React.FC<busBookingsProps> = ({ className }) => {
  const [bookings, setBookings] = useState<busBookingProps[]>([]);
  const [status, setstatus] = useState<"pending" | "cancel" | "ticketed" | "">(
    ""
  );

  const getBookings = useCallback(
    () => {
      Instance({
        url:
          status === ""
            ? `${b2bConfig.apiPath.busServices}/busbookings?status=${status}`
            : `${b2bConfig.apiPath.busServices}/busbookings?status=${status}`,
        method: "GET"
      })
        .then(res => {
          if (res.data && res.data.Status && res.data.Status.Code === "01") {
            const response: busBookingProps[] = res.data.Data;
            setBookings(response);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    [status]
  );

  useEffect(
    () => {
      getBookings();
    },
    [getBookings]
  );

  return (
    <div className={`${className} w-full px-2 py-2 bg-white rounded-sm`}>
      <div className="flex w-full max-w-screen-sm uppercase bg-white my-5 border-r-0 border-l-0 border-b border-t border-gray-600 rounded-md tracking-widest text-gray-600 text-xs">
        <button
          onClick={() => {
            setstatus("");
          }}
          className={`flex w-full justify-center border-l border-r rounded-l-md border-gray-700 items-center content-center px-4  py-2 space-x-2 ${status ===
            "" && "bg-gray-600 text-white"}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          <span>All</span>
        </button>
        <button
          onClick={() => {
            setstatus("pending");
          }}
          className={`flex w-full rounded-none justify-center  border-r rounded-l-md border-gray-700 items-center content-center px-4  py-2 space-x-2 ${status ===
            "pending" && "bg-gray-600 text-white"}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          <span>Pending</span>
        </button>
        <button
          onClick={() => {
            setstatus("cancel");
          }}
          className={`flex w-full rounded-none  justify-center  border-r rounded-l-md border-gray-700 items-center content-center px-4  py-2 space-x-2 ${status ===
            "cancel" && "bg-gray-600 text-white"}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          <span>Cancel</span>
        </button>
        <button
          onClick={() => {
            setstatus("ticketed");
          }}
          className={`flex w-full  justify-center  border-r rounded-l-none border-gray-700 rounded-r-md items-center content-center px-4  py-2 space-x-2 ${status ===
            "ticketed" && "bg-gray-600 text-white"}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          <span>Confirmed</span>
        </button>
      </div>
      <SelectTable className="text-gray-800">
        <thead className="text-xs font-normal uppercase border-t border-b text-gray-500 bg-gray-50 border-gray-200">
          <TableRow>
            <TableCell isHeader={true}>
              <span>No</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Business ID</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Route</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Price</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Date</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span>Time</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Operator Code</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> ID</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> email</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Gender</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Remark</span>
            </TableCell>

            <TableCell isHeader={true}>
              <span> Booking Date</span>
            </TableCell>
            <TableCell isHeader={true}>
              <span> Status</span>
            </TableCell>
            <TableCell isHeader={true}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-three-dots"
                viewBox="0 0 16 16"
              >
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg>
            </TableCell>
          </TableRow>
        </thead>
        <tbody className="text-xs font-normal divide-y divide-gray-400">
          {bookings.map((t, i) =>
            <TableRow key={i}>
              <TableCell>
                <span>
                  {" "}{i + 1}{" "}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.businessID}
                </span>
              </TableCell>

              <TableCell>
                <span>
                  {t.depfrom} - {t.arrvto}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  MMK {t.perpaxprice}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.depdate}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.deptime}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.operatorcode}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.pax_ID}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.pax_email}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.pax_gender}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.pax_remark}
                </span>
              </TableCell>

              <TableCell>
                <span>
                  {format(new Date(t.bookingDate), "yyyy-MM-dd")}
                </span>
              </TableCell>
              <TableCell>
                <span>
                  {t.status}
                </span>
              </TableCell>
              <TableCell>
                {t.status === "CONFIRM" &&
                  <button className="px-2 py-1 border rounded-md flex justify-center items-center content-center space-x-2 border-gray-500 hover:border-gray-800 text-gray-500 hover:text-gray-800">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fillRule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    <PDFDownloadLink
                      document={
                        <BusTicket
                          ID={t.ID}
                          userID={t.userID}
                          seats={t.seats}
                          status={t.status}
                          nationality={t.nationality}
                          perpaxprice={t.perpaxprice}
                          pax_remark={t.pax_remark}
                          pax_no={t.pax_no}
                          pax_name={t.pax_name}
                          pax_gender={t.pax_gender}
                          pax_email={t.pax_email}
                          bookingDate={t.bookingDate}
                          fareSource={t.fareSource}
                          businessID={t.businessID}
                          arrvto={t.arrvto}
                          pax_ID={t.pax_ID}
                          depfrom={t.depfrom}
                          depdate={t.depdate}
                          deptime={t.deptime}
                          operatorcode={t.operatorcode}
                        />
                      }
                      fileName={`bus_ticket_${t.ID}`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? "Loading document..." : "Download now!"}
                    </PDFDownloadLink>
                  </button>}
              </TableCell>
            </TableRow>
          )}
        </tbody>
      </SelectTable>
    </div>
  );
};

export const BusTicket: React.FC<busBookingProps> = ({
  ID,
  userID,
  seats,
  status,
  nationality,
  perpaxprice,
  pax_remark,
  pax_no,
  pax_name,
  pax_gender,
  pax_email,
  bookingDate,
  fareSource,
  businessID,
  arrvto,
  pax_ID,
  depfrom,
  depdate,
  deptime,
  operatorcode
}) => {
  return (
    <Document creator="GEM">
      <Page size="A4" style={{ paddingHorizontal: 10, paddingVertical: 10 }}>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",

            paddingHorizontal: 100,
            flexDirection: "row",
            borderBottom: 1,
            marginBottom: 10,
            borderBottomColor: "#f2f2f2"
          }}
        >
          <Text
            style={{
              fontSize: 12,
              textTransform: "uppercase",
              paddingBottom: 10,
              color: "#0e96d2"
            }}
          >
            Trip Information
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 4,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            Route
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {`from ${depfrom} to ${arrvto}`}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 20,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            Operator
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {operatorcode}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 4,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            Bus DateTime
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {depdate} / {deptime}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 4,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            Seats
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {seats}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 4,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            Price
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {parseInt(perpaxprice) * parseInt(pax_no)} MMK
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 4,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            Status
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {status}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",

            paddingHorizontal: 100,
            flexDirection: "row",
            borderBottom: 1,
            marginBottom: 10,
            marginTop: 20,
            borderBottomColor: "#f2f2f2"
          }}
        >
          <Text
            style={{
              fontSize: 12,
              textTransform: "uppercase",
              paddingBottom: 10,
              color: "#0e96d2"
            }}
          >
            Contact Information
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 4,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            Name
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {pax_name}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 4,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            Gender
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {pax_gender}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 4,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            Nationality
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {nationality}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 4,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            ID
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {pax_ID}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 4,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            Contact No
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {pax_no}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 4,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            Email
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {pax_email}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 4,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            Business ID
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {businessID}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingVertical: 4,
            paddingHorizontal: 100,
            flexDirection: "row"
          }}
        >
          <Text color="#333" style={{ fontSize: 12 }}>
            Remark
          </Text>
          <Text color="#333" style={{ fontSize: 12 }}>
            {pax_remark}
          </Text>
        </View>
      </Page>
    </Document>
  );
};
