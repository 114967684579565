import {
  FlagIcon,
  TagIcon
} from "@heroicons/react/solid";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { airDataResponseProps } from "../../../pages/dashbaord/FlightServices/OfflineOrderBooking";
interface airlineInfoProps extends airDataResponseProps {
  className?: string;
}
export const AirlineInfo: React.FC<airlineInfoProps> = ({
  className,
  domestic,
  international
}) => {
  const { addToast } = useToasts();
  return (
    <div className={`${className} w-full max-w-screen-xl mx-auto`}>
      {domestic &&
        <div>
          <div className="flex justify-center my-5 text-primary items-center content-center flex-row space-x-2">
            <TagIcon className="w-6 h-6" />
            <span className="text-lg tracking-widest"> Domestic Airlines</span>
          </div>         
          <div className="grid grid-cols-12 gap-3">
            {domestic.map((d, i) =>
              <div
                key={i}
                className="col-span-12 md:col-span-4 bg-white rounded-md"
              >
                <h4 className="border-b border-gray-200 text-center px-2 py-2 tracking-widest text-primary">
                  {d.airline}
                </h4>
                <div className="flex flex-col justify-center items-center content-center px-2 py-2">
                  <a href={d.url} target="blank"><img src={d.logo} alt="airline" className="w-auto h-12" /></a>
                  <div className="w-full my-3 border border-gray-200 px-2 py-2 border-dashed">
                    <div className="flex text-gray-500 my-3">
                      <span className="text-sm w-full ">Code</span>
                      <div className="flex w-full justify-between">
                        <span className="flex-auto lg:w-40 md:w-10 text-danger text-sm tracking-widest">
                          {d.companycode ? d.companycode : ' '}
                        </span>
                        <CopyToClipboard
                          onCopy={text =>
                            addToast("copied", {
                              appearance: "success",
                              autoDismiss: true
                            })}
                          text={d.companycode}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-files text-gray-600 hover:text-warning"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                          </svg>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="flex text-gray-500 my-2">
                    <span className="text-sm w-full ">User Name</span>
                      
                      <div className="flex w-full justify-between">
                        <span className="flex-auto lg:w-40 md:w-10 text-danger text-sm tracking-widest">
                          {d.username}
                        </span>
                        <CopyToClipboard
                          onCopy={text =>
                            addToast("copied", {
                              appearance: "success",
                              autoDismiss: true
                            })}
                          text={d.username}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-files text-gray-600 hover:text-warning"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                          </svg>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="flex text-gray-500 my-2">
                      <span className="text-sm w-full ">Password</span>
                      <div className="flex w-full justify-between">
                        <span className="flex-auto lg:w-40 md:w-10 text-danger text-sm tracking-widest">
                          {d.password}
                        </span>
                        <CopyToClipboard
                          onCopy={text =>
                            addToast("copied", {
                              appearance: "success",
                              autoDismiss: true
                            })}
                          text={d.password}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-files text-gray-600 hover:text-warning"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                          </svg>
                        </CopyToClipboard>
                      </div>
                    </div>

                    <div className="flex text-gray-500 my-2">                      
                      <div className="flex w-full justify-between text-gray-500">
                        <span className="flex-auto text-sm tracking-widest text-red-300">
                          {d.adminRemark}
                        </span>                       
                      </div>
                    </div>

                    <div className="flex text-gray-500 my-2">                      
                      <div className="flex w-full justify-between text-gray-500">                                   
                          {d.userguide ? (
                            <button className="border px-4 py-2 flex w-full rounded-md justify-between items-center content-center border-yellow-400 bg-yellow-400 hover:bg-yellow-600 text-white text-center">
                              <a href= {d.userguide} className="text-center" target="_blank" rel="noreferrer">Downlod User Guide</a>
                            </button>
                          ) : (
                            <span></span>
                          )}                            
                      </div>
                    </div>





                  </div>
                </div>
              </div>
            )}
          </div>
        </div>}

      {international &&
        <div className="mt-12">
          <div className="flex justify-center my-5 text-primary items-center content-center flex-row space-x-2">
            <FlagIcon className="w-6 h-6" />
            <span className="text-lg tracking-widest">
              International Airlines
            </span>
          </div>
         
          <div className="grid grid-cols-12 gap-3">
            {international.map((d, i) =>
              <div
                key={i}
                className="col-span-12 md:col-span-4 bg-white rounded-md"
              >
                <h4 className="border-b border-gray-200  tracking-widest text-center px-2 py-2 text-primary">
                  {d.airline}
                </h4>
                <div className="flex flex-col justify-center items-center content-center px-2 py-2">
                <a href={d.url} target="blank"><img src={d.logo} alt="airline" className="w-auto h-12" /></a>
                  <div className="w-full my-3 border border-gray-200 px-2 py-2 border-dashed">
                    <div className="flex text-gray-500 my-2">
                    <span className="text-sm w-full ">Code</span>
                      <div className="flex w-full justify-between">
                        <span className="flex-auto lg:w-40 md:w-10 text-danger text-sm tracking-widest">
                          
                        </span>
                        <CopyToClipboard
                          onCopy={text =>
                            addToast("copied", {
                              appearance: "success",
                              autoDismiss: true
                            })}
                          text={d.url}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-files text-gray-600 hover:text-warning"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                          </svg>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="flex text-gray-500 my-2">
                    <span className="text-sm w-full ">User Name</span>
                      <div className="flex w-full justify-between">
                        <span className="flex-auto lg:w-40 md:w-10 text-danger text-sm tracking-widest">
                          {d.username}
                        </span>
                        <CopyToClipboard
                          onCopy={text =>
                            addToast("copied", {
                              appearance: "success",
                              autoDismiss: true
                            })}
                          text={d.username}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-files text-gray-600 hover:text-warning"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                          </svg>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="flex text-gray-500 my-2">
                    <span className="text-sm w-full ">Password</span>
                      <div className="flex w-full justify-between">
                        <span className="flex-auto lg:w-40 md:w-10 text-danger text-sm tracking-widest">
                          {d.password}
                        </span>                        
                        <CopyToClipboard
                          onCopy={text =>
                            addToast("copied", {
                              appearance: "success",
                              autoDismiss: true
                            })}
                          text={d.password}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-files text-gray-600 hover:text-warning"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                          </svg>
                        </CopyToClipboard>
                      </div>
                    </div>

                    <div className="flex text-gray-500 my-2">                      
                      <div className="flex w-full justify-between text-gray-500">
                        <span className="flex-auto text-sm tracking-widest text-red-300">
                          {d.adminRemark}
                        </span>                       
                      </div>
                    </div>

                    <div className="flex text-gray-500 my-2">                      
                      <div className="flex w-full justify-between text-gray-500">                                   
                          {d.userguide ? (
                            <button className="border px-4 py-2 flex w-full rounded-md justify-between items-center content-center border-yellow-400 bg-yellow-400 hover:bg-yellow-600 text-white text-center">
                              <a href= {d.userguide} className="text-center" target="_blank" rel="noreferrer">Downlod User Guide</a>
                            </button>
                          ) : (
                            <span></span>
                          )}                            
                      </div>
                    </div>

                    
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>}
    </div>
  );
};
