import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { each } from 'lodash'
import { format } from 'date-fns'
import {
  accountInfo,
  accountRespInfo,
  exchangeRateProps
} from '../../interfaces'

import { fsearchformDataProps } from '../../pages/dashbaord/FlightServices/FlightSearch'

export interface commonStateProps {
  exRates?: exchangeRateProps[]
  accountInfo?: accountInfo[]
  busSearchParms: bSearchQueryStringProps
  flightSearchParms?: fSearchQueryStringProps
}

export interface bSearchQueryStringProps {
  depfrom: any
  arrvto: any
  depdate: any
  pax: number
  nationality: string
  selectedBus: string
}

export interface fSearchQueryStringProps {
  tripType: string
  origin: string
  destination: string
  singleDate: string
  rangeDate: string[]
  execludedAirlines: string
  includedAirlines: string
  nonStop: string
  adults: number
  children: number
  infants: number
  travelClass: string
  currencyCode: string
}

const initialState: commonStateProps = {
  exRates: [],
  accountInfo: [],
  busSearchParms: {
    depfrom: '',
    arrvto: '',
    depdate: format(new Date(), 'yyyy-MM-dd'),
    pax: 1,
    nationality: 'MM',
    selectedBus: ''
  },
  flightSearchParms: {
    tripType: 'oneway',
    origin: '',
    destination: '',
    singleDate: format(new Date(), 'yyyy-MM-dd'),
    rangeDate: [
      format(new Date(), 'yyyy-MM-dd'),
      format(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 2
        ),
        'yyyy-MM-dd'
      )
    ],
    execludedAirlines: '_',
    includedAirlines: '_',
    nonStop: 'true',
    adults: 1,
    children: 0,
    infants: 0,
    travelClass: 'Y',
    currencyCode: 'USD'
  }
}
export const commonSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setExchangeRates: (state, action: PayloadAction<exchangeRateProps[]>) => {
      state.exRates = action.payload
    },
    setAccountInfo: (state, action: PayloadAction<accountRespInfo[]>) => {
      let data: accountInfo[] = []
      each(action.payload, e => {
        const parms: accountInfo = {
          text: e.accountID,
          value: e.accountID
        }
        data.push(parms)
      })
      state.accountInfo = data
    },
    setBusSearchParms: (
      state,
      action: PayloadAction<bSearchQueryStringProps>
    ) => {
      const busSearchQueryString: bSearchQueryStringProps = {
        depfrom: action.payload.depfrom,
        arrvto: action.payload.arrvto,
        depdate: action.payload.depdate,
        pax: action.payload.pax,
        nationality: action.payload.nationality,
        selectedBus: action.payload.selectedBus
      }
      state.busSearchParms = busSearchQueryString
    },
    setFlightSearchParms: (
      state,
      action: PayloadAction<fsearchformDataProps>
    ) => {
      const flightSearchParms: fSearchQueryStringProps = {
        tripType: action.payload.tripType,
        origin: action.payload.origin,
        destination: action.payload.destination,
        singleDate: format(action.payload.singleDate, 'yyyy-MM-dd'),
        rangeDate: [
          format(action.payload.rangeDate[0], 'yyyy-MM-dd'),
          format(new Date(action.payload.rangeDate[1]), 'yyyy-MM-dd')
        ],
        execludedAirlines: action.payload.execludedAirlines,
        includedAirlines: action.payload.includedAirlines,
        nonStop: action.payload.nonStop,
        adults: action.payload.adults,
        children: action.payload.children,
        infants: action.payload.infants,
        travelClass: action.payload.travelClass,
        currencyCode: action.payload.currencyCode
      }
      state.flightSearchParms = flightSearchParms
    }
  }
})

export const {
  setExchangeRates,
  setAccountInfo,
  setBusSearchParms,
  setFlightSearchParms
} = commonSlice.actions

export default commonSlice.reducer
