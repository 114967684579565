import React from "react";

interface dashboardDividerProps {
  className?: string;
  title?: string;
}
export const DashboardDivider: React.FC<dashboardDividerProps> = ({
  title
}) => {
  return (
    <div className="px-3 py-2 rounded-sm mb-0.5 last:mb-0 ">
      <span className="ml-3 result_font text-sm font-medium duration-200 text-gray-700 lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100">
        {title}
      </span>
    </div>
  );
};
