import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { b2bConfig } from "../../config";
import { Instance } from "../../services";
import { setCredential } from "../../store/Reducers/auth";
import LogoImg from "../../assets/admin_logo.png";

import "./index.css";
import { Bbutton } from "../../components/common/Bbutton";
interface authPageProps {
  className?: string;
}
interface authFormProps {
  businessentity: string;
  username: string;
  password: string;
}
export const AuthPage: React.FC<authPageProps> = ({ className }) => {
  const [formData, setFormData] = useState<authFormProps>({
    businessentity: "",
    username: "",
    password: ""
  });
  const [show, setShow] = useState(false);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const SubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      Instance({
        url: `${b2bConfig.apiPath.bzEntry}/token-auth/`,
        method: "POST",
        data: formData
      })
        .then(res => {
          if (res.status === 200) {
            if (res.data && res.data.access && res.data.refresh) {
              addToast(`Login successful`, {
                appearance: "success",
                autoDismiss: true
              });
              sessionStorage.setItem("accessToken", res.data.access);
              sessionStorage.setItem("refreshToken", res.data.refresh);
              dispatch(
                setCredential({
                  usrNm: formData.username,
                  accessToken: res.data.access,
                  refreshToken: res.data.refresh
                })
              );
              history.push("/dashboard");
            }
          } else {
            addToast(`Oops , Something wrong!`, {
              appearance: "error",
              autoDismiss: true
            });
          }
        })
        .catch(err => {
          addToast(`No active account found with the given credentials`, {
            appearance: "error",
            autoDismiss: false
          });
        });
    } catch (error) {
      addToast(`StstemError ${JSON.stringify(error)}`, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  return (
    <div className="auth_bg tracking-widest text-gray-500">
      <div className="min-h-screen bg-primary bg-opacity-30 w-full py-16 px-4">
        <div className="flex flex-col items-center justify-center">
          <div className="flex w-full justify-center flex-col items-center content-center space-y-2" />
          <form
            onSubmit={e => {
              SubmitForm(e);
            }}
            className="bg-white shadow rounded max-w-screen-sm w-full p-10 mt-20"
          >
            <img src={LogoImg} alt="" className="w-auto h-10 mx-auto" />
            <div className="w-full flex items-center justify-between py-5">
              <hr className="w-full bg-gray-400" />
              <p className="text-base font-medium leading-4 px-2 text-gray-400">
                TRAVELS.COM.MM
              </p>
              <hr className="w-full bg-gray-400  " />
            </div>
            <div>
              <label className="text-sm font-medium leading-none text-gray-600">
                Business Entity
              </label>
              <input
                name="businessentity"
                aria-label="enter business entity"
                type="text"
                id="businessentity"
                value={formData.businessentity}
                onChange={e => {
                  setFormData({ ...formData, [e.target.name]: e.target.value });
                }}
                className="bg-gray-200 border rounded focus:outline-none text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
              />
            </div>
            <div className="mt-3  w-full">
              <label className="text-sm font-medium leading-none text-gray-600">
                User Name
              </label>
              <input
                name="username"
                aria-label="enter username"
                type="text"
                id="username"
                value={formData.username}
                onChange={e => {
                  setFormData({ ...formData, [e.target.name]: e.target.value });
                }}
                className="bg-gray-200 border rounded focus:outline-none text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
              />
            </div>
            <div className="mt-3 w-full">
              <label className="text-sm font-medium leading-none text-gray-600">
                Password
              </label>
              <div className="relative flex items-center justify-center">
                <input
                  aria-label="enter Password"
                  type={show ? "text" : "password"}
                  name="password"
                  id="password"
                  value={formData.password}
                  onChange={e => {
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value
                    });
                  }}
                  className="bg-gray-200 border rounded focus:outline-none text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
                />
                <div
                  onClick={() => {
                    setShow(!show);
                  }}
                  className="absolute right-0 mt-2 mr-3 cursor-pointer"
                >
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99978 2C11.5944 2 14.5851 4.58667 15.2124 8C14.5858 11.4133 11.5944 14 7.99978 14C4.40511 14 1.41444 11.4133 0.787109 8C1.41378 4.58667 4.40511 2 7.99978 2ZM7.99978 12.6667C9.35942 12.6664 10.6787 12.2045 11.7417 11.3568C12.8047 10.509 13.5484 9.32552 13.8511 8C13.5473 6.67554 12.8031 5.49334 11.7402 4.64668C10.6773 3.80003 9.35864 3.33902 7.99978 3.33902C6.64091 3.33902 5.32224 3.80003 4.25936 4.64668C3.19648 5.49334 2.45229 6.67554 2.14844 8C2.45117 9.32552 3.19489 10.509 4.25787 11.3568C5.32085 12.2045 6.64013 12.6664 7.99978 12.6667ZM7.99978 11C7.20413 11 6.44106 10.6839 5.87846 10.1213C5.31585 9.55871 4.99978 8.79565 4.99978 8C4.99978 7.20435 5.31585 6.44129 5.87846 5.87868C6.44106 5.31607 7.20413 5 7.99978 5C8.79543 5 9.55849 5.31607 10.1211 5.87868C10.6837 6.44129 10.9998 7.20435 10.9998 8C10.9998 8.79565 10.6837 9.55871 10.1211 10.1213C9.55849 10.6839 8.79543 11 7.99978 11ZM7.99978 9.66667C8.4418 9.66667 8.86573 9.49107 9.17829 9.17851C9.49085 8.86595 9.66644 8.44203 9.66644 8C9.66644 7.55797 9.49085 7.13405 9.17829 6.82149C8.86573 6.50893 8.4418 6.33333 7.99978 6.33333C7.55775 6.33333 7.13383 6.50893 6.82126 6.82149C6.5087 7.13405 6.33311 7.55797 6.33311 8C6.33311 8.44203 6.5087 8.86595 6.82126 9.17851C7.13383 9.49107 7.55775 9.66667 7.99978 9.66667Z"
                      fill="#71717A"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <Bbutton type="submit" title="LOGIN" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
