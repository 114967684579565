import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { OnlineFlightBook } from "./Booking";
import BookingDetail from "./bookingDetail";
import { FlightBookings } from "./FlightBookings";
import { FlightSearchPage } from "./FlightSearch";
import { OfflineBookingsPage } from "./OfflineBookings";
import { OfflineOrderBookingPage } from "./OfflineOrderBooking";
import { FlightResultPage } from "./Results";

export const FlightServicePage: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={FlightSearchPage} />
      <Route
        exact
        path={`${path}/online-bookings`}
        component={FlightBookings}
      />
      <Route
        exact
        path={`${path}/offline-order-booking`}
        component={OfflineOrderBookingPage}
      />
      <Route
        exact
        path={`${path}/offline-bokings`}
        component={OfflineBookingsPage}
      />
      <Route
        exact
        path={`${path}/onewaySearch/:tripType/:origin/:destination/:departureDate/:excludedAirlines/:includeAirlines/:nonStop/:adults/:children/:infants/:travelClass/:currencyCode`}
        component={FlightResultPage}
      />
      <Route
        exact
        path={`${path}/roundTripSearch/:tripType/:origin/:destination/:departureDate/:returnDate/:excludedAirlines/:includeAirlines/:nonStop/:adults/:children/:infants/:travelClass/:currencyCode`}
        component={FlightResultPage}
      />
      <Route
        exact
        path={`${path}/onewayFlightBook/:searchID/:fsc/:src/:tripType/:origin/:destination/:departureDate/:excludedAirlines/:includeAirlines/:nonStop/:adults/:children/:infants/:travelClass/:currencyCode`}
        component={OnlineFlightBook}
      />
      <Route
        exact
        path={`${path}/roundTripFlightBook/:searchID/:fsc/:src/:tripType/:origin/:destination/:departureDate/:returnDate/:excludedAirlines/:includeAirlines/:nonStop/:adults/:children/:infants/:travelClass/:currencyCode`}
        component={OnlineFlightBook}
      />
      <Route
        path={`${path}/booking_detail/:bookingId`}
        component={BookingDetail}
      />

      <Redirect to="/404" />
    </Switch>
  );
};
