import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import { bookingDetailProps } from "./index";

const styles = StyleSheet.create({
  page: { paddingHorizontal: 20, paddingVertical: 20 },
  section: {
    color: "white",
    textAlign: "left",
    margin: "0 15px"
  },
  flex: {
    display: "flex",
    flexDirection: "column"
  },
  title: {
    width: "100%",
    borderBottom: 1,
    borderBottomColor: "#f2f2f2"
  },
  titleText: {
    fontSize: 9,
    paddingBottom: 10,
    color: "#0e96d2"
  },
  flex_row: {
    display: "flex",
    flexDirection: "row",
    margin: "4px 0px"
  },
  one: {
    fontSize: 8,
    color: "#333",
    width: "120px",
    textTransform: "capitalize"
  },
  infoTitle: {
    fontSize: 8,
    color: "#222",
    fontWeight: "bold",
    margin: "8px 0 4px"
  },
  info: {
    fontSize: 8,
    color: "#333",
    textAlign: "justify"
  },
  tr: {
    display: "flex",
    flexDirection: "row"
  },
  tr_two: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f9fafb"
  },
  th: {
    fontSize: 6,
    color: "#333",
    margin: "5px 2px",
    textTransform: "uppercase"
  },
  td: {
    fontSize: 7,
    color: "#333",
    margin: "6px 2px",
    display: "flex",
    flexDirection: "column"
  },
  c1: {
    width: "5%"
  },
  c2: {
    width: "10%"
  },
  c3: {
    width: "15%"
  },
  c4: {
    width: "20%"
  }
});

export const TicketInfoPDF: React.FC<bookingDetailProps> = ({
  FareType,
  Itineraries,
  PassengerInfos,
  booker,
  bookingId,
  businessName
}) => (
  <Document creator="GEM">
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.title}>
          <Text style={styles.titleText}>Electronic Ticket</Text>
        </View>

        <View style={styles.flex_row}>
          <Text style={styles.one}>Booking ID</Text>
          <Text style={styles.one}>{bookingId}</Text>
        </View>

        <View style={styles.flex_row}>
          <Text style={styles.one}>Booked On</Text>
          <Text style={styles.one}>          
            {moment(booker.BookingDate).format("LLL")}{" "}
          </Text>
        </View>

        <View style={styles.flex_row}>
          <Text style={styles.one}>Issue Date</Text>
          <Text style={styles.one}>            
            {moment(booker.OrderTicketDate).format("LLL")}{" "}
          </Text>
        </View>

        <View style={styles.flex_row}>
          <Text style={styles.one}>Booked By</Text>
          <Text style={styles.one}>{businessName}</Text>
        </View>

        <View style={{ marginTop: "15px" }} />

        <View style={styles.flex_row}>
          <View style={styles.title}>
            <Text style={styles.titleText}>Flight Information</Text>
          </View>
        </View>

        <View style={styles.tr}>
          <Text style={[styles.th, styles.c2]}>Flight</Text>
          <Text style={[styles.th, styles.c2]}>Class</Text>
          <Text style={[styles.th, styles.c4]}>Depart</Text>
          <Text style={[styles.th, styles.c4]}>Arrivies</Text>
          <Text style={[styles.th, styles.c2]}>During</Text>
          <Text style={[styles.th, styles.c2]}>Status</Text>
          <Text style={[styles.th, styles.c2]}>Airline PNR</Text>
          <Text style={[styles.th, styles.c2]}>Fare Type</Text>
          <Text style={[styles.th, styles.c2]}>Baggage</Text>
        </View>

        {Itineraries[0].ItineraryInfo.ReservationItems.map((r: any, i: any) => (
          <View key={i} style={styles.tr}>
            <View style={[styles.td, styles.c2]}>
              <Text>{r.MarketingAirlineName}</Text>
              <Text> {r.MarketingAirlineCode} {r.FlightNumber}</Text>
              <Text>
                  {r.MarketingAirlineCode !== r.OperatingAirlineCode &&(
                    <span>Operate By {r.OperatingAirlineName}</span>
                  )}
              </Text>
              <Text>&nbsp;</Text>
            </View>
            <View style={[styles.td, styles.c2]}>
              <Text>{r.FareFamily}</Text>
              <Text>&nbsp;</Text>
            </View>
            <View style={[styles.td, styles.c4]}>
              
              <Text>{r.DepartureAirportLocationName} ({r.DepartureAirportLocationCode})</Text>
              <Text>                
                {moment(r.DepartureDateTime).format("LLL")}{" "}
              </Text>
            </View>
            <View style={[styles.td, styles.c4]}>
              
              <Text>{r.ArrivalAirportLocationName} ({r.ArrivalAirportLocationCode})</Text>
              <Text>                
                {moment(r.ArrivalDateTime).format("LLL")}{" "}  
              </Text>
            </View>
            <Text style={[styles.td, styles.c2]}>{r.JourneyDuration} min</Text>
            <Text style={[styles.td, styles.c2]}>{r.FlightStatus}</Text>
            <Text style={[styles.td, styles.c2]}>{r.AirlinePNR}</Text>
            <Text style={[styles.td, styles.c2]}>{FareType}</Text>
            <Text style={[styles.td, styles.c2]}>{r.Baggage}</Text>
          </View>
        ))}

        <View style={{ marginTop: "15px" }} />

        <View style={styles.flex_row}>
          <View style={styles.title}>
            <Text style={styles.titleText}>Passenger Details</Text>
          </View>
        </View>

        <View style={styles.tr}>
          <Text style={[styles.th, styles.c4]}>Name</Text>
          <Text style={[styles.th, styles.c2]}>Date of Birth</Text>
          <Text style={[styles.th, styles.c2]}>Gender</Text>
          <Text style={[styles.th, styles.c2]}>Passport No</Text>
          <Text style={[styles.th, styles.c2]}>Expires On</Text>
          <Text style={[styles.th, styles.c3]}>Nationality</Text>
          <Text style={[styles.th, styles.c3]}>E-Ticket Number</Text>
        </View>

        {PassengerInfos.map((m: any, i: any) => (
          <View key={i} style={styles.tr}>
            <View style={[styles.td, styles.c4]}>
              <Text>
                {`${m.Passenger.PaxName.PassengerTitle} ${m.Passenger.PaxName.PassengerFirstName} ${m.Passenger.PaxName.PassengerLastName}`}
              </Text>
              <Text>({m.Passenger.PassengerType})</Text>
              
            </View>
            <Text style={[styles.td, styles.c2]}>
              {moment(m.Passenger.DateOfBirth).format("DD MMM YYYY")}
            </Text>
            <Text style={[styles.td, styles.c2]}>{m.Passenger.Gender}</Text>
            <Text style={[styles.td, styles.c2]}>
              {m.Passenger.PassportNumber}
            </Text>
            <Text style={[styles.td, styles.c2]}>
              {moment(m.Passenger.PassportExpiresOn).format("DD MMM YYYY")}
            </Text>
            <Text style={[styles.td, styles.c3]}>
              {m.Passenger.PassengerNationality}
            </Text>
            <Text style={[styles.td, styles.c3]}>
              {m.ETickets.map((e: any, i: any) => e.ETicketNumber)}
            </Text>
          </View>
        ))}

        <View style={{ marginTop: "15px" }} />

        <View style={styles.flex_row}>
          <View style={styles.title}>
            <Text style={styles.titleText}>Fare Condition</Text>
          </View>
        </View>

        <View style={{ marginTop: "4px" }}>
          <Text style={styles.info}>
            - Cancellation charges shall be as per airline rules.
          </Text>
          <Text style={styles.info}>- Date change charges as applicable.</Text>
          <Text style={styles.infoTitle}>E-Ticket Notice</Text>
          <Text style={styles.info}>
            Carriage and other services provided by the carrier are subject to
            terms & conditions of carriage. These conditions may be obtained
            from the respective carrier.
          </Text>
          <Text style={styles.infoTitle}>Check-in Time</Text>
          <Text
            style={
              styles.info
            }>{`We recommend the following minimum check-in time:- Domestic - 1 hour prior to departure. All other destinations (including USA) - 3 hours prior to departure.`}</Text>
          <Text style={styles.infoTitle}>Passport/Visa/Health</Text>
          <Text style={styles.info}>
            Please ensure that you have all the required travel documents for
            your entire journey i.e., valid passport & necessary visas, and that
            you have had the recommended inoculations for your destination(s).
          </Text>
          <Text style={styles.infoTitle}>Reconfirmation of Flights</Text>
          <Text style={styles.info}>
            Please reconfirm all flights at least 72 hours in advance directly
            with the airline concerned. Failure to do so could result in the
            cancellation of your reservation and possible 'no-show' charges.
          </Text>
          <Text style={styles.infoTitle}>Insurance</Text>
          <Text style={styles.info}>
            We strongly recommend that you avail travel insurance for the entire
            journey.
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);
