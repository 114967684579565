import axios, { AxiosError, AxiosResponse } from "axios";
import { Store } from "redux";
import { b2bConfig } from "../config";
import { accountRespInfo, exchangeRateProps, userDataProps } from "../interfaces";
import {
  refreshToken,
  removeCredentials,
  setProfile
} from "../store/Reducers/auth";
import { setAccountInfo, setExchangeRates } from "../store/Reducers/common";

export const Instance = axios.create({
  headers: {
    "Content-Type": "application/json"
  }
});

export const setupInterceptor = (store: Store) => {
  Instance.interceptors.request.use(
    config => {
      document.body.classList.add("loading-indicator");
      const accessToken = sessionStorage.getItem("accessToken");
      if (accessToken) {
        config.headers["Authorization"] = `JWT ${accessToken}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
  const { dispatch } = store;
  Instance.interceptors.response.use(
    async (res: AxiosResponse) => {
      document.body.classList.remove("loading-indicator");
      const host = res.config.url;
      if (host === `${b2bConfig.apiPath.bzEntry}/user/profile/`) {
        if (res.data) {
          const data: userDataProps = res.data.Data;
          dispatch(setProfile(data));
          try {
            const rs = await Instance.get(`${b2bConfig.apiPath.bzEntry}/user/exchangerate/`)

            if (rs.data && rs.data.Data) {
              const respData: exchangeRateProps[] = rs.data.Data.rates
              dispatch(setExchangeRates(respData))
            }
          } catch (error) {
            console.log(error, ['exchange_fetch_error'])
          }

          try {
            const rsAccInfo = await Instance.get(`${b2bConfig.apiPath.bzEntry}/user/getbalance/`)
            if (rsAccInfo.data && rsAccInfo.data.Data && rsAccInfo.data.Data.businessBalance) {
              const rsAccInfoData: accountRespInfo[] = rsAccInfo.data.Data.businessBalance
              dispatch(setAccountInfo(rsAccInfoData))
            }
            
          } catch (error) {
            console.log(error, ['accountInfo_fetch_error'])
          }
        }

        return res;
      } else {
        return res;
      }
    },
    async (err: AxiosError) => {
      document.body.classList.remove("loading-indicator");
      const originConfig = err.config;
      if (
        originConfig.url !==
        "https://b2bapi.gemtechmyanmar.com/dev/api/v1/token-auth/" &&
        err.response
      ) {
        if (err.response.status === 401) {
          try {
            const rs = await Instance.post(
              "https://b2bapi.gemtechmyanmar.com/dev/api/v1/token-refresh/",
              {
                refresh: sessionStorage.getItem("refreshToken")
              }
            );
            sessionStorage.setItem("accessToken", rs.data.access);
            dispatch(refreshToken(rs.data.access));
            return Instance(originConfig);
          } catch (error) {
            dispatch(removeCredentials());
            sessionStorage.clear();
            return Promise.reject(error);
          }
        }
      }
    }
  );
};
