import {
  CodeIcon,
  PaperAirplaneIcon,
  PencilAltIcon,
  UserIcon
} from "@heroicons/react/solid";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { AirlineInfo } from "../../../../components/flight/OfflineOrderBooking/AirlineInfo";
import { b2bConfig } from "../../../../config";
import { Instance } from "../../../../services";
import { RootState } from "../../../../store";

import "./index.css";
interface offlineOrderBookingProps {
  className?: string;
}
interface offlineOrderBookingFormProps {
  airline: string;
  pnr: string;
  pax_name: string;
  pax_no: number;
  remark: string;
}
interface selectOptionsProps {
  text: string;
  value: any;
}
const airlineOptions: selectOptionsProps[] = [
  {
    text: "Please select",
    value: ""
  },
  {
    text: "MNA(MMK)",
    value: "MNA-MMK"
  },
  {
    text: "MNA(USD)",
    value: "MNA-USD"
  },

  {
    text: "GMA-USD",
    value: "GMA-USD"
  },
  {
    text: "GMA-MMK",
    value: "GMA-MMK"
  },
  {
    text: "KBZ",
    value: "KBZ(USD)"
  },
  {
    text: "MYP(MMKUSD)",
    value: "MYP-USD"
  },
  {
    text: "MYP(MMK)",
    value: "MYP-MMK"
  },
  {
    text: "ST(USD)",
    value: "ST-USD"
  },
  {
    text: "ST(MMK)",
    value: "ST-MMK"
  },
  {
    text: "PG(Bangkok Airway)",
    value: "PG"
  },
  {
    text: "Myanmar Airways International",
    value: "MAI"
  },
  {
    text: "Other",
    value: "Other"
  }
];

export interface airDataProps {
  airline: string;
  companycode: string;
  logo: string;
  password: string;
  url: string;
  username: string;
  adminRemark: string;
  userguide: string;
}
export interface airDataResponseProps {
  domestic?: airDataProps[];
  international?: airDataProps[];
}
export const OfflineOrderBookingPage: React.FC<offlineOrderBookingProps> = ({
  className
}) => {
  const { bzId } = useSelector((state: RootState) => state.auth);
  const { addToast } = useToasts();
  const [formData, setFormData] = useState<offlineOrderBookingFormProps>({
    airline: "",
    pnr: "",
    pax_name: "",
    pax_no: 1,
    remark: ""
  });
  const [airData, setAirData] = useState<airDataResponseProps>();
  const [active, setActive] = useState("airline");

  const SubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let orderFormData: any = {};
    orderFormData["bizid"] = bzId;
    orderFormData["airline"] = formData.airline;
    orderFormData["pnr"] = formData.pnr;
    orderFormData["pax_name"] = formData.pax_name;
    orderFormData["pax_no"] = formData.pax_no;
    orderFormData["remark"] = formData.remark;

    Instance({
      url: `${b2bConfig.apiPath.filghtServices}/orderofflinebooking`,
      data: orderFormData,
      method: "POST"
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data && res.data.Status && res.data.Status.Code) {
            addToast(`${res.data.Status.Message}`, {
              appearance: res.data.Status.Code === "01" ? "success" : "warning",
              autoDismiss: true
            });
          }
          if (res.data && res.data.Status && res.data.Status.Code === "01") {
            setFormData({
              airline: "",
              pnr: "",
              pax_name: "",
              pax_no: 1,
              remark: ""
            });
          }
        }
      })
      .catch((err) => {
        addToast(JSON.stringify(err), {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const getOrderOnline = useCallback(() => {
    Instance({
      url: `${b2bConfig.apiPath.filghtServices}/offline-airlineinfo`,
      method: "GET"
    })
      .then((res) => {
        if (res.data && res.data.success === true) {
          if (res.data.data) {
            const response: airDataResponseProps = res.data.data;
            setAirData(response);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getOrderOnline();
  }, [getOrderOnline]);

  return (
    <div className={`${className} w-full`}>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
        <ul className="flex flex-wrap -mb-px">
          {["airline", "Order Form"].map((d, i) => (
            <li className="" key={i}>
              <button
                className={`${
                  active === d
                    ? "border-gray-800 text-gray-800 rounded-t-lg border-b-2"
                    : "border-gray-500 text-gray-500 hover:text-gray-600 hover:border-gray-500"
                } inline-block p-4 capitalize`}
                onClick={() => setActive(d)}>
                {d}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {active === "airline" ? (
        <AirlineInfo
          domestic={airData?.domestic}
          international={airData?.international}
        />
      ) : (
        <form
          onSubmit={(e) => {
            SubmitForm(e);
          }}
          className="bg-white p-12 w-2/3 mx-auto rounded-md mt-4">
          <div className="flex justify-between items-center content-center px-2 py-1 border-gray-200 rounded-md border">
            <PaperAirplaneIcon className="w-4 h-4" />
            <select
              name="airline"
              id="airline"
              value={formData.airline}
              required
              onChange={(e) => {
                setFormData({ ...formData, [e.target.name]: e.target.value });
              }}
              className="border-0 px-2 py-2 w-full bg-white outline-none text-gray-500">
              <option value=""> select airline... </option>
              {airlineOptions.map((a, i) => (
                <option key={i} value={a.value}>
                  {a.text}
                </option>
              ))}
            </select>
          </div>

          <div className="flex my-4 justify-between  items-center content-center px-2 py-1 border-gray-200 rounded-md border">
            <CodeIcon className="w-4 h-4" />
            <input
              type="text"
              className="border-0 px-2 py-2 w-full outline-none text-gray-400"
              placeholder="PNR no. ...."
              name="pnr"
              id="pnr"
              required
              value={formData.pnr}
              onChange={(e) => {
                setFormData({ ...formData, [e.target.name]: e.target.value });
              }}
            />
          </div>

          <div className="flex my-4 justify-between  items-center content-center px-2 py-1 border-gray-200 rounded-md border">
            <UserIcon className="w-4 h-4" />
            <input
              type="text"
              className="border-0 px-2 py-2 w-full outline-none text-gray-400"
              placeholder="Pax Name. ...."
              name="pax_name"
              id="pax_name"
              required
              value={formData.pax_name}
              onChange={(e) => {
                setFormData({ ...formData, [e.target.name]: e.target.value });
              }}
            />
          </div>

          <div className="flex my-4 justify-between  items-center content-center px-2 py-1 border-gray-200 rounded-md border">
            <UserIcon className="w-4 h-4" />
            <input
              type="number"
              className="border-0 px-2 py-2 w-full outline-none text-gray-400"
              placeholder="No. of Pressengers ...."
              name="pax_no"
              id="pax_no"
              value={formData.pax_no}
              required
              onChange={(e) => {
                setFormData({ ...formData, [e.target.name]: e.target.value });
              }}
            />
          </div>

          <div className="flex my-4 justify-between items-start content-center px-2 py-1 border-gray-200 rounded-md border">
            <PencilAltIcon className="w-4 h-4 mt-3" />
            <textarea
              className="border-0 px-2 py-2 w-full outline-none text-gray-400"
              placeholder="Remark ...."
              name="remark"
              id="remark"
              rows={7}
              value={formData.remark}
              onChange={(e) => {
                setFormData({ ...formData, [e.target.name]: e.target.value });
              }}
            />
          </div>

          <div className="flex justify-start py-2 items-center content-center ">
            <button
              className="flex w-full justify-center items-center content-center border rounded-md border-yellow-400 px-2 py-2 text-yellow-400 hover:bg-yellow-400 hover:text-white"
              type="submit">
              
              <span>Request Issue</span>
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
