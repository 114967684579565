import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { BookingDetailPageProps } from "./index";
import moment from "moment";

const styles = StyleSheet.create({
  page: { paddingHorizontal: 30, paddingVertical: 15 },
  section: { color: "white", textAlign: "left", margin: 15 },
  title: {
    backgroundColor: "#fff",
    width: "100%",
    borderBottom: 1,
    marginBottom: 10,
    borderBottomColor: "#f2f2f2"
  },
  titleText: {
    fontSize: 13,
    paddingBottom: 10,
    color: "#0e96d2"
  },
  flex_row: {
    display: "flex",
    flexDirection: "row",
    margin: "4px 0px"
  },
  one: {
    fontSize: 10,
    color: "#333",
    width: "120px",
    textTransform: "capitalize"
  },
  tr: {
    display: "flex",
    flexDirection: "row"
  },
  tr_two: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f9fafb"
  },
  th: {
    fontSize: 7,
    color: "#333",
    margin: "5px 2px",
    textTransform: "uppercase"
  },
  td: {
    fontSize: 8,
    color: "#333",
    margin: "6px 2px"
  },
  total: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "10px",
    borderTop: 1,
    borderTopColor: "#f2f2f2"
  },
  totalText: {
    fontSize: 8,
    color: "#333",
    marginTop: "10px"
  },
  c1: {
    width: "3%"
  },
  c2: {
    width: "18%"
  },
  c3: {
    width: "18%"
  },
  c4: {
    width: "18%"
  },
  c5: {
    width: "18%"
  },
  c6: {
    width: "15%"
  },
  c7: {
    width: "5%"
  },
  c8: {
    width: "5%"
  }
});

export const BookingInfoPDF: React.FC<BookingDetailPageProps> = ({
  premiumRate,
  route,
  travelStartDate,
  travelEndDate,
  paxNo,
  status,
  totalAmount,
  bookingDetail
}) => {
  return (
    <Document creator="GEM">
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.title}>
            <Text style={styles.titleText}>Insurance booking detail</Text>
          </View>

          <View style={styles.flex_row}>
            <Text style={styles.one}>Trip Type</Text>
            <Text style={styles.one}>
              {`${premiumRate.tripType.toLowerCase()}`}
            </Text>
          </View>

          <View style={styles.flex_row}>
            <Text style={styles.one}>Premium Rate</Text>
            <Text style={styles.one}>
              {`${
                premiumRate.duration
              } ${premiumRate.durationUnit.toLowerCase()} ${parseInt(
                premiumRate.ratePerUnit
              )}ks per unit`}
            </Text>
          </View>

          <View style={styles.flex_row}>
            <Text style={styles.one}>Start Date</Text>
            <Text style={styles.one}>
              {moment(travelStartDate).format("YYYY-MM-DD")}
            </Text>
          </View>

          <View style={styles.flex_row}>
            <Text style={styles.one}>End Date</Text>
            <Text style={styles.one}>
              {moment(travelEndDate).format("YYYY-MM-DD")}
            </Text>
          </View>

          <View style={styles.flex_row}>
            <Text style={styles.one}>Route</Text>
            <Text style={styles.one}>{route}</Text>
          </View>

          <View style={styles.flex_row}>
            <Text style={styles.one}>Traveler No.</Text>
            <Text style={styles.one}>{paxNo}</Text>
          </View>

          <View style={styles.flex_row}>
            <Text style={styles.one}>Status</Text>
            <Text style={styles.one}>{status.toLowerCase()}</Text>
          </View>

          <View style={{ marginTop: "15px" }} />

          <View style={styles.title}>
            <Text style={styles.titleText}>Traveler Information</Text>
          </View>

          <View style={styles.tr}>
            <Text style={[styles.th, styles.c1]}>No</Text>
            <Text style={[styles.th, styles.c2]}>
              Insured <br />
              Name
            </Text>
            <Text style={[styles.th, styles.c3]}>Insured ID</Text>
            <Text style={[styles.th, styles.c4]}>
              Beneficiary <br />
              Name
            </Text>
            <Text style={[styles.th, styles.c5]}>Beneficiary ID</Text>
            <Text style={[styles.th, styles.c6]}>Contact</Text>
            <Text style={[styles.th, styles.c7]}>Unit</Text>
            <Text style={[styles.th, styles.c8]}>Total</Text>
          </View>

          {Object.values(bookingDetail)
            .map((m) => m)
            .map((b: any, i) => (
              <View key={i} style={i % 2 === 0 ? styles.tr : styles.tr_two}>
                <Text style={[styles.td, styles.c1]}>{i + 1}</Text>
                <Text style={[styles.td, styles.c2]}>
                  {b.insuredInfo.insuredName}
                </Text>
                <Text style={[styles.td, styles.c3]}>
                  {b.insuredInfo.insuredID}
                </Text>
                <Text style={[styles.td, styles.c4]}>
                  {b.insuredInfo.beneficiaryName}
                </Text>
                <Text style={[styles.td, styles.c5]}>
                  {b.insuredInfo.beneficiaryID}
                </Text>
                <Text style={[styles.td, styles.c6]}>
                  {b.insuredInfo.contactNo}
                </Text>
                <Text style={[styles.td, styles.c7]}>{b.unitno}</Text>
                <Text style={[styles.td, styles.c8]}>
                  {parseInt(b.totalAmount)}
                </Text>
              </View>
            ))}

          <View style={styles.total}>
            <Text style={styles.totalText}>
              Grand Total: {parseInt(totalAmount)}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
