import { find } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ConfirmationForm } from '../../../../components/bus/Booking/confirm'

import { SeatPlan } from '../../../../components/bus/Booking/seatplan'
import { TranvellerInput } from '../../../../components/bus/Booking/travelerInput'
import { b2bConfig } from '../../../../config'
import { Instance } from '../../../../services'
import { destinationsProps, otherBusResponseProps } from '../BusSearch'
export interface busBookingProps {
  pax_name: string
  pax_contactno: string
  pax_email: string
  pax_gender: string
  pax_ID: string
  pax_remark: string
  pax_no: number
  operatorcode: string
  operatorname: string
  seatPlanUrl: string
  tripID: string
  depfrom: string
  arrvto: string
  depdate: string
  deptime: string
  nationality: string
  seats: string[]
  perpaxprice: perpaxpriceProps
  step: '1' | '2' | '3'
}

interface perpaxpriceProps {
  amount: number
  currency: string
}
export interface seatPlanProps {
  isAvailable: boolean
  isSeat: boolean
  number: string
  seatId: string
}
export const BusBooking: React.FC = () => {
  const RouteMatch = useRouteMatch()
  const params: any = RouteMatch.params
  const sessionData: any = sessionStorage.getItem('busData')
  const busData: otherBusResponseProps = JSON.parse(sessionData)
  const seatPlanUrl: string = busData.correlationId
  const depfrom = params.depfrom
  const arrvto = params.arrvto
  const depdate = params.depdate
  const [seatPlanError, setSeatPlanError] = useState('')
  const pax = params.pax
  const nationality = params.nationality
  const operatorcode = params.operatorcode
  const [formData, setFormData] = useState<busBookingProps>({
    pax_name: '',
    pax_contactno: '',
    pax_email: '',
    pax_gender: 'Male',
    pax_ID: '',
    pax_remark: '',
    pax_no: parseInt(params.pax),
    operatorcode: busData.operatorcode,
    operatorname: busData.operatorNameEN,
    seatPlanUrl: busData.seatPlanUrl,
    tripID: busData.tripID,
    depfrom: depfrom,
    arrvto: arrvto,
    depdate: busData.departureDate,
    deptime: busData.departureDateTime,
    nationality: nationality,
    seats: [],
    perpaxprice: {
      currency: busData.localTicketPrice
        ? busData.localTicketPrice.currency
        : 'MMK',
      amount: busData.localTicketPrice
        ? parseInt(busData.localTicketPrice.amount)
        : 0
    },
    step: '1'
  })
  const { goBack } = useHistory()
  const tripID = params.tripID
  const [seats, setSeats] = useState<seatPlanProps[][]>([])
  const [destinations, setDestinations] = useState<destinationsProps[]>([])
  const GetDestinations = useCallback(() => {
    Instance({
      url: `${b2bConfig.apiPath.busServices}/destinations/`,
      method: 'GET'
    })
      .then(res => {
        if (res.data && res.data.Status && res.data.Status.Code === '01') {
          const response: destinationsProps[] = res.data.Data
          setDestinations(response)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const getSeatPlan = useCallback(() => {
    const formData = new FormData()
    formData.append('depfrom', depfrom)
    formData.append('arrvto', arrvto)
    formData.append('depdate', depdate)
    formData.append('pax', pax)
    formData.append('nationality', nationality)
    formData.append('operatorcode', operatorcode)
    formData.append('seatPlanUrl', seatPlanUrl)
    formData.append('tripID', tripID)

    Instance({
      url: `${b2bConfig.apiPath.busServices}/getseatplan`,
      method: 'POST',
      data: formData
    })
      .then(res => {
        if (res.data && res.data.success && res.data.success === true) {
          const response: seatPlanProps[][] = res.data.data
          setSeats(response)
          setSeatPlanError('')
        }
      })
      .catch(err => {
        setSeatPlanError('you need to send all required data to get seat plan')
        console.log(err)
      })
  }, [
    depfrom,
    arrvto,
    depdate,
    pax,
    nationality,
    operatorcode,
    seatPlanUrl,
    tripID
  ])

  useEffect(() => {
    getSeatPlan()
    GetDestinations()
  }, [getSeatPlan, GetDestinations])

  const getDestinationNm = (destId: string) => {
    const destination: any = find(
      destinations,
      dest => dest.pk === parseInt(destId)
    )
    if (destination) {
      return `${destination.destinationCode}(${destination.destinationNameMM})`
    } else {
      return ''
    }
  }

  return (
    <div>
      <div className='grid grid-flow-row grid-cols-4 text-xs text-gray-500  tracking-widest'>
        <div className='flex justify-start items-center content-center'>
          <button
            onClick={() => goBack()}
            className='px-2 py-2 rounded-md border border-gray-600 hover:text-gray-900'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-arrow-90deg-left'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z'
              />
            </svg>
          </button>
        </div>
        <div className='w-full flex justify-center  items-center content-center space-x-2'>
          {formData.step === '1' && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              fill='currentColor'
              className='bi bi-check-circle text-success'
              viewBox='0 0 16 16'
            >
              <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
              <path d='M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z' />
            </svg>
          )}
          <span className='w-full'>Seat Plan</span>
          <hr className='w-full bg-gray-200' />
        </div>
        <div className='w-full flex justify-center items-center content-center space-x-2'>
          {formData.step === '2' && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              fill='currentColor'
              className='bi bi-check-circle text-success'
              viewBox='0 0 16 16'
            >
              <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
              <path d='M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z' />
            </svg>
          )}

          <span className='w-full'>Traveler Infromation</span>
          <hr className='w-full bg-gray-200' />
        </div>

        <div className='w-full flex justify-center items-center content-center space-x-2'>
          {formData.step === '3' && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              fill='currentColor'
              className='bi bi-check-circle text-success'
              viewBox='0 0 16 16'
            >
              <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
              <path d='M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z' />
            </svg>
          )}
          <span className='w-full'>Confirmation</span>
        </div>
      </div>
      <div className='grid grid-cols-12 gap-4'>
        <div className='col-span-12 md:col-span-8'>
          {seatPlanError && (
            <div className='px-2 py-2 text-warning '>
              <p>
                {' '}
                Seat plan is not avaliable ... You can go farward with TBA.
              </p>
            </div>
          )}
          {formData.step === '1' && (
            <SeatPlan
              seats={seats}
              formData={formData}
              setFormData={setFormData}
            />
          )}

          {formData.step === '2' && (
            <TranvellerInput
              formData={formData}
              setFormData={setFormData}
              numOfPax={pax}
            />
          )}

          {formData.step === '3' && (
            <ConfirmationForm setFormData={setFormData} formData={formData} />
          )}
        </div>
        <div className='col-span-12 md:col-span-4'>
          <div className='w-full shadow-lg px-2 py-4 rounded-md my-7'>
            <div className='flex justify-between items-center content-center space-x-2'>
              <span className='text-xs tracking-widest text-gray-500'>
                {getDestinationNm(depfrom)}
                {busData.sourceName.en} <br></br>
                {busData.departureDateTime}
              </span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-reception-0'
                viewBox='0 0 16 16'
              >
                <path d='M0 13.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z' />
              </svg>
              <span className='text-xs tracking-widest text-gray-500'>
                {getDestinationNm(arrvto)}
                {busData.destinationName.en} <br></br>
                {busData.estArrivalDateTime}
              </span>
            </div>
            <div className='flex justify-start my-4 items-center content-center space-x-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-clock-history'
                viewBox='0 0 16 16'
              >
                <path d='M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z' />
                <path d='M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z' />
                <path d='M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z' />
              </svg>
              <span className='text-gray-600 text-xs'>
                &nbsp; 
              </span>
            </div>
            <div className='flex justify-between text-xs text-warning items-center content-center space-x-2'>
              <span>
                {' '}
                {busData.unitTicketPrice?.amount} x {pax}{' '}
              </span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-reception-0'
                viewBox='0 0 16 16'
              >
                <path d='M0 13.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z' />
              </svg>
              <span>
                {' '}
                {busData.unitTicketPrice &&
                  parseInt(busData.unitTicketPrice.amount) * parseInt(pax)}
                {busData.unitTicketPrice?.currency}
              </span>
            </div>
            <div className='flex justify-start text-xs text-secondary my-4 items-center content-center space-x-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-building'
                viewBox='0 0 16 16'
              >
                <path
                  fillRule='evenodd'
                  d='M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z'
                />
                <path d='M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z' />
              </svg>
              <span>
                {' '}
                {busData.gateName}
              </span>
            </div>
            <div className='flex justify-start text-xs text-secondary my-4 items-center content-center space-x-2'>
              <img
                src={busData.operator.logoUrl}
                alt=''
                className='w-auto h-6'
              />
              <span>
                {' '}
                {busData.operator.name.en}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
