import React from 'react'
import './index.css'

interface selectOptions {
  text: string
  value: any
}

interface bSelectPorps {
  className?: string
  name: string
  title?: string
  id: string
  formData: any
  setFormData: React.Dispatch<React.SetStateAction<any>>
  selectOptions?: selectOptions[]
  disabled?: boolean
  required?: boolean
}

export const BSelect: React.FC<bSelectPorps> = ({
  required = true,
  disabled = false,
  className,
  name,
  id,
  title,
  formData,
  setFormData,
  selectOptions
}) => {
  return (
    <div className={`${className} w-full `}>
      {title && (
        <label htmlFor={name} className='text-sm tracking-widest text-secondary ml-2'>
          {title}
        </label>
      )}
      <select
        name={name}
        id={id}
        required={required}
        disabled={disabled}
        onChange={e => {
          setFormData({ ...formData, [e.target.name]: e.target.value })
        }}
        value={formData[name]}
        className='form-select rounded-sm border border-gray-300  px-2 text-xs text-gray-500 tracking-widest w-full'
      >
        {selectOptions?.map((s: selectOptions, i: number) => (
          <option key={i} value={s.value}>
            {s.text}{' '}
          </option>
        ))}
      </select>
    </div>
  )
}
